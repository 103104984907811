import React from 'react';
import SaveAsPDF from '../../../../features/button/save-as-pdf';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';
import { FlashingText } from '../../../../features';

export const IVCannulation = () => {


    return (

        <CalculatorWorking>



            {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
            <h1 class="place-content-center text-2xl text-center md:text-4xl mb-15 font-extrabold dark:text-white">Intravenous Cannulation/Line <div>(Access)</div></h1>

            <div className="container mb-20 mx-auto px-4 py-0">
                <div className="flex place-items-center place-content-center gap-x-1 container grid-cols-none mx-auto px-4 py-0">


                    <div className='px-0 md:px-8 mt-2'>
                        <FlashingText>
                            <label htmlFor="my-modal" className="btn lg:mx-0 hover:underline h-[80px] md:h-fit bg-white text-center w-[120px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition gap-x-1 hover:scale-105 duration-300 ease-in-out">Equipment for Cannulation</label>
                        </FlashingText>
                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modal" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white ">
                                <label htmlFor="my-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        List of Necessary Equipment for IV Cannulation:</h3>


                                    <div> <SaveAsPDF /></div>
                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                <ul class="space-y-4 text-gray-800 list-decimal list-outside px-4 md:px-8 text-vsm  md:text-base">

                                    <li className='font-bold'> Gloves:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Disposable gloves to ensure hand hygiene and prevent contamination.</li>
                                    </ol>

                                    <li className='font-bold'>IV Cannula:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>IV Cannula: Appropriate-sized IV cannula with a needle for venous access.</li>
                                    </ol>

                                    <li className='font-bold'>Antiseptic Solution:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>An antiseptic solution, such as chlorhexidine or povidone-iodine, for skin preparation and disinfection.</li>
                                    </ol>

                                    <li className='font-bold'>Tourniquet:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A tourniquet to apply pressure above the intended insertion site to enhance vein visibility and control blood flow during cannulation.</li>
                                    </ol>

                                    <li className='font-bold'>Securement Device:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A securement device, such as an adhesive tape or device specifically designed for securing IV lines, to prevent accidental dislodgement.</li>
                                    </ol>

                                    <li className='font-bold'>Sterile Saline: </li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile saline solution for flushing the cannula to ensure patency and prevent clot formation.</li>
                                    </ol>

                                    <li className='font-bold'>Intravenous Extension Tubing:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Extension tubing with a female luer lock connector for connecting to the IV cannula and administering medications or fluids.</li>
                                    </ol>

                                    <li className='font-bold'>Sterile Saline: </li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile saline solution for flushing the cannula to ensure patency and prevent clot formation.</li>
                                    </ol>

                                    <li className='font-bold'>Adhesive Dressings:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Adhesive dressings or transparent film dressings to secure the IV cannula in place after insertion.</li>
                                    </ol>

                                    <li className='font-bold'>Transparent Dressing: </li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Transparent dressing to provide a clear view of the insertion site while securing the cannula.</li>
                                    </ol>

                                    <li className='font-bold'>Syringes: </li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile syringes of appropriate sizes for flushing the cannula and drawing up medications, if required.</li>
                                    </ol>

                                    <li className='font-bold'>Intravenous Extension Tubing: </li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Extension tubing with a female luer lock connector for connecting to the IV cannula and administering medications or fluids.</li>
                                    </ol>

                                    <li className='font-bold'>Transparent Dressing:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Transparent dressing to provide a clear view of the insertion site while securing the cannula.</li>
                                    </ol>

                                    <li className='font-bold'>Labeling Materials:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Pre-printed labels or marker pens for accurate labeling of the IV cannula, including the date and time of insertion.</li>
                                    </ol>

                                    <li className='font-bold'>Alcohol Swabs: </li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Alcohol swabs for cleaning the tops of blood collection tubes before transferring blood samples.</li>
                                    </ol>

                                    <li className='font-bold'>Gauze or Cotton Balls:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile gauze or cotton balls for applying pressure to the puncture site after blood collection to stop any bleeding.</li>
                                    </ol>

                                    <li className='font-bold'>Waste Disposal Bags:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Plastic bags for the proper disposal of used gloves, swabs, and other non-sharp waste.</li>
                                    </ol>

                                    <li className='font-bold'>Hand Hygiene Supplies:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Soap and water or alcohol-based hand rub for performing hand hygiene before putting on gloves and after the procedure.</li>
                                    </ol>


                                </ul>

                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-vsm md:text-base">It is essential to ensure that all equipment is sterile, within its expiration date, and disposed of properly after use. Adhering to infection control practices and having the necessary equipment readily available helps maintain patient safety and minimize the risk of contamination during IV cannulation procedures.</p>


                                <div className="modal-action">
                                    <label htmlFor="my-modal" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='px-0 md:px-8  mt-2'>
                        <FlashingText>
                            <label htmlFor="my-modalvials" className="btn lg:mx-0 h-[80px] md:h-fit shover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Cannula Color Guide</label>
                        </FlashingText>
                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvials" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvials" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        IV Cannula Color Guide:</h3>


                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                <div className="overflow-x-auto">
                                    <table className="rounded-lg bg-gray-800 table-pin-cols">
                                        {/* head */}
                                        <thead>
                                            <tr>
                                                <th className='w-24 md:w-28'>Color</th>
                                                <th>Max Flow Rate</th>
                                                <th className='w-52'>Uses</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* row 1 */}
                                            <tr>
                                                <th className='bg-[#ff8617] text-vsm md:text-xs font-bold text-white text-center'>14G</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>240 ml/min</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>Trauma, surigcal procedures</td>

                                            </tr>
                                            {/* row 2 */}
                                            <tr>
                                                <th className='bg-[#808080] text-vsm md:text-xs font-bold text-white text-center'>16G</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center mb-2'>180 ml/min</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>Trauma, surigcal procedures</td>
                                            </tr>


                                            {/* row 3 */}
                                            <tr>
                                                <th className='bg-[#3bb371] text-vsm md:text-xs font-bold text-white text-center'>18G</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>90 ml/min</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>Trauma, quick blood transfusion</td>
                                            </tr>

                                            {/* row 4 */}
                                            <tr>
                                                <th className='bg-[#ff11ad] text-vsm md:text-xs font-bold text-white text-center'>20G</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>60 ml/min</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>Normal IV or blood transfusion</td>

                                            </tr>


                                            {/* row 5 */}
                                            <tr>
                                                <th className='bg-[#4169e1] text-vsm md:text-xs font-bold text-white text-center'>22G</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>36 ml/min</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>Children, older adults</td>
                                            </tr>


                                            {/* row 6 */}
                                            <tr>
                                                <th className='bg-[#ffff00] text-vsm md:text-xs font-bold text-gray-800 text-center'>24G</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>20 ml/min</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>Neonates, children, old elderly</td>
                                            </tr>


                                            {/* row 7 */}
                                            <tr>
                                                <th className='bg-[#b955d3] text-vsm md:text-xs font-bold text-white text-center'>26G</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>13 ml/min</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>Neonates</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                {/* <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-base md:text-xl">For more details, <a href='https://en.wikipedia.org/wiki/Vacutainer' target="_blank" rel="noreferrer" className='hover:text-white dark:text-blue-600 text-start text-base md:text-xl hover:bg-blue-400'>click here</a></p> */}







                                <div className="modal-action">
                                    <label htmlFor="my-modalvials" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='px-0 md:px-8  mt-2'>
                        <FlashingText>
                            <label htmlFor="my-modalvideo" className="btn lg:mx-0 h-[80px] md:h-fit hover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none gap-x-1 focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"><span class="hidden md:block">Watch </span>Video of Cannulation</label>
                        </FlashingText>
                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvideo" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvideo" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        IV Cannulation: Video</h3>



                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div>
                                <div class='max-w-fit flex justify-center items-center'>
                                    <iframe
                                        className='w-fit h-fit md:w-[560px] md:h-[360px] flex place-content-center place-items-center'
                                        src="https://www.youtube.com/embed/9OCVSsKJsdE"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen>

                                    </iframe>
                                </div>



                                <div className="modal-action">
                                    <label htmlFor="my-modalvideo" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>



                        <p class=" text-white dark:text-white px-4 indent-6 my-4 text-start text-sm md:text-lg">Intravenous (IV) cannulation is a common medical procedure used to establish access to a patient's veins for the administration of medications, fluids, or blood products. The National Institute for Health and Care Excellence (NICE) provides guidelines on the recommended steps for performing IV cannulation.</p>
                        <p class="mb-10 text-white dark:text-white px-4 my-1 text-start text-sm md:text-lg">Here's a brief note on IV cannulation and its steps:</p>

                        <ul class="space-y-4 text-white list-decimal list-outside px-4 md:px-16 text-sm md:text-lg">

                            <li className='font-bold'> Preparation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Gather all the necessary equipment, including gloves, appropriate-sized IV cannula, antiseptic solution, tourniquet, adhesive dressings, securement device, and non-touch technique (NTT) equipment</li>
                                <li>Ensure a clean and well-lit area for the procedure.</li>
                                <li>Verify the patient's identification and explain the procedure to them, addressing any concerns or questions.</li>
                            </ol>

                            <li className='font-bold'> Hand Hygiene:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Perform hand hygiene by using soap and water or an alcohol-based hand rub before putting on gloves.</li>
                            </ol>

                            <li className='font-bold'> Site Selection:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Select an appropriate site for IV cannulation based on patient factors, such as clinical condition, anticipated duration of IV access, and patient preference.</li>
                                <li>Consider using the NICE guidelines for site selection, which recommend using the veins of the non-dominant arm, starting distally, and avoiding veins in joints or areas of flexion.</li>
                            </ol>

                            <li className='font-bold'> Skin Preparation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Cleanse the selected site with an appropriate antiseptic solution using a back-and-forth motion for at least 30 seconds, allowing it to dry.</li>
                            </ol>

                            <li className='font-bold'>Cannulation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Apply a tourniquet above the intended insertion site to enhance vein visibility and control blood flow.</li>
                                <li>Use a sterile technique to insert the IV cannula into the vein, ensuring proper positioning within the lumen.</li>
                                <li>Secure the cannula in place using adhesive dressings and a securement device to prevent accidental dislodgement.</li>
                            </ol>

                            <li className='font-bold'>Flush and Assess:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Flush the cannula with sterile saline to ensure patency and prevent clot formation.</li>
                                <li>Observe the patient for any signs of complications, such as pain, swelling, or infiltration, and assess the functioning of the IV line.</li>
                            </ol>

                            <li className='font-bold'>Post-Cannulation Care:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Apply gentle pressure over the insertion site to stop any bleeding.</li>
                                <li>Dispose of sharps in designated sharps containers.</li>
                            </ol>

                            <li className='font-bold'>Documentation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Document the details of the procedure, including the site of cannulation, size of cannula used, any complications encountered, and patient response.</li>
                            </ol>



                        </ul>
                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Note:</div>

                        <p class="mb-3 text-white dark:text-white px-4 my-4 text-start text-sm md:text-lg">Following the NICE guidelines for IV cannulation helps healthcare professionals perform the procedure safely and effectively. By adhering to proper hand hygiene, site selection, and aseptic techniques, the risk of complications, such as infection or infiltration, can be minimized. Accurate documentation ensures continuity of care and facilitates communication among healthcare providers.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div>


        </CalculatorWorking >

    );
};
