import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { MedicineProgress } from "../pages/interns/internalmedicine/medicineprogress";
import { EssentialSkillsMedicine, EssentialSkillsSurgery, ETTAnesthesia, FemoralLine, Home, InternalJugularLine, LumbarPuncture, MaintainanceFluidCalculator, SubclavianLine, } from "../pages";
import { CalculatorWorking } from "../pages/interns/calculatorworking";
import { IVDripRates } from "../pages/interns/anesthesia/ivdriprates";
import { Layout } from "../pages/interns/layout";
import { CalculatorsPage } from "../pages/interns/calculators";
import { PediatricsPage } from "../pages/interns/pediatrics/page";
import { AnesthesiaPage } from "../pages/interns/anesthesia/page";
import { InternalMedicinePage } from "../pages/interns/internalmedicine/page";
import { SurgeryPage } from "../pages/interns/surgery/page";
import { SurgeryProgress } from "../pages/interns/surgery/surgeryprogress";
import { BedsideProceduresPage } from "../pages/interns/bedsideprocedures/page";
import { IVSampling } from "../pages/interns/bedsideprocedures/ivsampling";
import { IVCannulation } from "../pages/interns/bedsideprocedures/ivcannulation";
import { UrinaryCatheterizationPage } from "../pages/interns/bedsideprocedures/urinarycatheterization/page";
import { ExternalUrinaryCatheter } from "../pages";
import { IntermittentCatheter } from "../pages";
import { FoleysCatheter } from "../pages";
import { SuprapubicCatheter } from "../pages/interns/bedsideprocedures/urinarycatheterization/suprapubiccatheter";
import { NasogastricTubeInsertion } from "../pages/interns/bedsideprocedures/nasogastrictube";
import { ArterialSampling } from "../pages/interns/bedsideprocedures/arterialsampling";
import { LifeSavingProceduresPage } from "../pages/interns/lifesavingprocedures/page";
import { ACLS } from "../pages/interns/lifesavingprocedures/acls";
import { BLS } from "../pages/interns/lifesavingprocedures/bls";
import { CardiacArrest } from "../pages/interns/lifesavingprocedures/cardiacarrest";
import { EmergencyETT } from "../pages/interns/lifesavingprocedures/emergencyett";
import { CommonAnesthetics } from "../pages/interns/anesthesia/commonanesthetics";
import { PreOperativeAssessment } from "../pages/interns/anesthesia/preoperativeassessment";
import { BasicAirwayManagement } from "../pages/interns/anesthesia/basicairwaymanagement";
import { CentralVenousLinePage } from "../pages/interns/bedsideprocedures/centralvenousline/page";
import { ETTTroubleshooting } from "../pages/interns/anesthesia/ett-troubleshooting";
import { InternsPage } from "../pages/interns/page";
import { UrinarySampling } from "../pages";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/interns",
        element: <Layout />,
        children: [
            {
                path: '',
                element: <InternsPage />
            },
            {
                path: 'surgery',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <SurgeryPage />
                    },
                    {
                        path: 'surgeryprogress',
                        element: <SurgeryProgress />
                    },
                    {
                        path: 'essentialskills-surgery',
                        element: <EssentialSkillsSurgery />
                    },



                ]
            },
            {
                path: 'pediatrics',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <PediatricsPage />
                    },
                ]
            },
            {
                path: 'anesthesia',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <AnesthesiaPage />
                    },
                    {
                        path: 'ivdriprates',
                        element: <IVDripRates />
                    },
                    {
                        path: 'maintainancefluid',
                        element: <MaintainanceFluidCalculator />
                    },
                    {
                        path: 'commonanesthetics',
                        element: <CommonAnesthetics />
                    },
                    {
                        path: 'preoperativeassessment',
                        element: <PreOperativeAssessment />
                    },
                    {
                        path: 'basicairwaymanagement',
                        element: <BasicAirwayManagement />
                    },
                    {
                        path: 'ett-anesthesia',
                        element: <ETTAnesthesia />
                    },
                    {
                        path: 'ett-troubleshooting',
                        element: <ETTTroubleshooting />
                    },


                ]
            },
            {
                path: 'internalmedicine',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <InternalMedicinePage />
                    },
                    {
                        path: 'medicineprogress',
                        element: <MedicineProgress />
                    },
                    {
                        path: 'essentialskills-medicine',
                        element: <EssentialSkillsMedicine />
                    },
                ]
            },
            {
                path: 'lifesavingprocedures',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <LifeSavingProceduresPage />
                    },
                    {
                        path: 'acls',
                        element: <ACLS />
                    },
                    {
                        path: 'bls',
                        element: <BLS />
                    },
                    {
                        path: 'cardiacarrest',
                        element: <CardiacArrest />
                    },
                    {
                        path: 'emergencyett',
                        element: <EmergencyETT />
                    },
                ]
            },
            {
                path: 'bedsideprocedures',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <BedsideProceduresPage />
                    },
                    {
                        path: 'ivsampling',
                        element: <IVSampling />
                    },
                    {
                        path: 'ivcannulation',
                        element: <IVCannulation />
                    },
                    {
                        path: 'urinarycatheterization',
                        element: <Outlet />,
                        children: [
                            {
                                path: '',
                                element: <UrinaryCatheterizationPage />
                            },
                            {
                                path: 'externalurinarycatheter',
                                element: <ExternalUrinaryCatheter />
                            },
                            {
                                path: 'foleyscatheter',
                                element: <FoleysCatheter />
                            },
                            {
                                path: 'intermittentcatheter',
                                element: <IntermittentCatheter />
                            },
                            {
                                path: 'suprapubiccatheter',
                                element: <SuprapubicCatheter />
                            },

                        ]
                    },
                    {
                        path: 'centralvenousline',
                        element: <Outlet />,
                        children: [
                            {
                                path: '',
                                element: <CentralVenousLinePage />
                            },
                            {
                                path: 'internaljugularline',
                                element: <InternalJugularLine />
                            },
                            {
                                path: 'subclavianline',
                                element: <SubclavianLine />
                            },
                            {
                                path: 'femoralline',
                                element: <FemoralLine />
                            },


                        ]
                    },
                    {
                        path: 'nasogastrictube',
                        element: <NasogastricTubeInsertion />
                    },
                    {
                        path: 'arterialsampling',
                        element: <ArterialSampling />
                    },
                    {
                        path: 'urinarysampling',
                        element: <UrinarySampling />
                    },
                    {
                        path: 'lumbarpuncture',
                        element: <LumbarPuncture />
                    },
                ]
            },
            {
                path: 'calculators',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <CalculatorsPage />
                    },
                    {
                        path: 'ivdriprates',
                        element: <IVDripRates />
                    },
                    {
                        path: 'maintainancefluid',
                        element: <MaintainanceFluidCalculator />
                    },
                    {
                        path: 'commonanesthetics',
                        element: <CommonAnesthetics />
                    },



                ]
            },
        ]
    },

    {
        path: "/interns/calculatorworking",
        element: <CalculatorWorking />,
    },



]);

export const Router = () => {
    return (
        <RouterProvider router={router} />
    )
}