import React from 'react';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';
import { Link } from 'react-router-dom';
import { LuExternalLink } from 'react-icons/lu'
import SaveAsPDF from '../../../../features/button/save-as-pdf';



export const ETTAnesthesia = () => {




    return (

        <CalculatorWorking>


            <div className="container mb-20  mx-auto px-2 md:px-4 py-0">
                {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
                <h1 class="place-content-center block md:inline text-2xl text-start md:text-center md:text-4xl font-extrabold dark:text-white">Endotracheal Intubation: <div className='text-start text-sm md:text-2xl'>Safeguarding Airway in the Operation Theater</div></h1>
                <div className='grid-cols-1 md:flex grid place-items-center place-content-center md:place-items-start px-0 md:px-8  my-2 md:my-6'>


                    <div className='flex gap-x-2 '>
                        <div className='px-0 md:px-8  mt-1'>
                            <label htmlFor="my-modalarrest" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">ETT Size Guide</label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my-modalarrest" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                    <label htmlFor="my-modalarrest" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                    <div className='flex gap-x-20'>
                                        <h3 className="font-bold text-gray-800 text-base mb-6">
                                            ETT Size Guide: </h3>



                                    </div>

                                    {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                    <ul class="space-y-4 text-gray-800  list-outside px-4 md:px-8 text-vsm  md:text-base">
                                        <div className='rounded-lg border-4 px-4'>
                                            <li className='font-bold'>Adults:</li>
                                            <ol class="pl-5 space-y-1 list-disc list-outside">
                                                <li>Most commonly used sizes in adults are between 7 & 8mm</li>
                                                <li>Sizes may vary according to the anatomical variations or abnormailities.</li>
                                            </ol>
                                        </div>

                                        <div className='rounded-lg border-4 p-4'>
                                            <li className='font-bold'>Paediatrics:</li>
                                            <ol class="pl-5 space-y-1 list-disc list-outside">
                                                <li>There is a formula to calculate the size of ETT in paediatric patients.</li>
                                                <li className='font-bold text-base md:text-lg'>ETT Size = [Age / 4 ] + 4</li>
                                            </ol>
                                        </div>


                                    </ul>



                                    <div className="modal-action">
                                        <label htmlFor="my-modalarrest" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className='px-0 md:px-8  mt-1'>

                            <label htmlFor="my-modalchecklist" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Intubation Checklist</label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my-modalchecklist" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                    <label htmlFor="my-modalchecklist" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                    <div className='flex gap-x-20 mb-2'>
                                        <h3 className="font-bold text-gray-800 text-base mb-6">
                                            Intubation Checklist</h3>
                                        <div className='mb-2'> <SaveAsPDF /></div>


                                    </div>

                                    {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                    <div className="overflow-x-auto">
                                        <table className="rounded-lg bg-gray-800 table-pin-cols">
                                            {/* head */}

                                            <thead>
                                                <tr>

                                                    <th>Step</th>
                                                    <th>1st attempt</th>
                                                    <th>2nd attempt</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <th className='bg-[#3bb371]'>Pre-procedure</th>
                                                    <th className='bg-[#3bb371]'></th>
                                                    <th className='bg-[#3bb371]'></th>


                                                </tr>
                                                <tr>

                                                    <td>Assessment of patient and airway</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Ensures trained assistant & senior supervision</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Confirms airway plan with anaesthetic assistant</td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td>Performs WHO sign in</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>

                                                    <td>Full monitoring including capnography</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Ensures patent IV cannula</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>

                                                    <td>Optimises patient position</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Pre-oxygenation until ETO2 &gt; 0.8</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr>
                                                    <th className='bg-[#3bb371]'>Procedure</th>
                                                    <th className='bg-[#3bb371]'></th>
                                                    <th className='bg-[#3bb371]'></th>
                                                </tr>

                                                <tr>
                                                    <td>Supports airway using simple manoeuvres</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr>
                                                    <td>Supports ventilation using bag mask ventilation</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr>

                                                    <td>Assesses adequacy of anaesthesia/relaxation</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Inserts laryngoscope correctly into mouth and obtains view – vocalises view</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>

                                                    <td>Inserts endotracheal tube to correct distance</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Uses additional manoeuvres or adjuncts, if required (e.g. external neck pressure; bougie)</td>
                                                    <td></td>
                                                    <td></td>


                                                </tr>
                                                <tr>
                                                    <td>Anchors ETT to ensure it does not move</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Removes laryngoscope carefully from mouth</td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>

                                                    <td>Attaches ventilator tubing to ETT</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Confirms ventilation – chest rising, ETCO2, auscultates chest</td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>

                                                    <td>Secures ETT</td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <th className='bg-[#3bb371]'>Post-procedure</th>
                                                    <th className='bg-[#3bb371]'></th>
                                                    <th className='bg-[#3bb371]'></th>
                                                </tr>
                                                <tr>

                                                    <td>Documentation of airway management</td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>

                                                    <th className='bg-[#3bb371]'>Throughout</th>
                                                    <th className='bg-[#3bb371]'></th>
                                                    <th className='bg-[#3bb371]'></th>
                                                </tr>
                                                <tr>
                                                    <td>Appropriate communication with assistant & patient</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>

                                                    <td>Aware of patient condition/vital signs</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>

                                                    <td>Shows understanding of adequate anaesthetic/paralysis for intubation/maintenance</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className='text-xs md:text-base text-gray-800 font-bold my-4'>Literature From: <a class='text-xs md:text-base text-start font-bold text-blue-600 flex  hover:text-white hover:bg-blue-400' href='https://das.uk.com/files/2020/page/Tayside%20Mastery%20Learning%20Programme%20combined%20final-Aug26.pdf' target="_blank" rel="noreferrer">Tayside Mastery Learning Programme of DAS and Royal College of Anesthetists<span className='scale-50'> <LuExternalLink /></span>    </a></div>
                                    </div>


                                    <div className="modal-action">
                                        <label htmlFor="my-modalvideo" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                    </div>
                                </div>
                            </div>



                        </div>

                        <div className='px-0 md:px-8  mt-1'>

                            <label htmlFor="my-modalvideo" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Watch Video</label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my-modalvideo" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                    <label htmlFor="my-modalvideo" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                    <div className='flex gap-x-20'>
                                        <h3 className="font-bold text-gray-800 text-base mb-6">
                                            Endotracheal Intubation: Video</h3>



                                    </div>

                                    {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                    <h3 className="font-bold text-gray-800 text-sm mb-1">
                                        ETT Video</h3>
                                    <div class='max-w-fit flex justify-center items-center mb-4'>
                                        <iframe
                                            className='w-fit h-fit md:w-[560px] md:h-[250px] flex place-content-center place-items-center'
                                            src="https://www.youtube.com/embed/8AOB2PtHfVM"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowfullscreen>

                                        </iframe>
                                    </div>
                                    <h3 className="font-bold text-gray-800 text-sm mb-1">
                                        ETT Tips & Tricks</h3>
                                    <div class='max-w-fit flex justify-center items-center'>
                                        <iframe
                                            className='w-fit h-fit md:w-[560px] md:h-[250px] flex place-content-center place-items-center'
                                            src="https://www.youtube.com/embed/x2FOdgw93sA"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowfullscreen>

                                        </iframe>
                                    </div>


                                    <div className="modal-action">
                                        <label htmlFor="my-modalvideo" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>




                </div>


                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>


                        <div>
                            <div>
                                <p class="mb-10 text-white dark:text-white px-2 indent-6 my-4 text-start text-sm md:text-lg">Endotracheal intubation is a critical procedure performed in the operation theater for anesthesia to secure a patient's airway during surgical procedures. It involves the insertion of an endotracheal tube through the patient's mouth or nose into the trachea, allowing for controlled ventilation and anesthesia delivery. This procedure is essential for maintaining adequate oxygenation, ventilation, and airway protection during surgery. Here is a step-by-step guide outlining the key aspects of the endotracheal intubation procedure.</p>
                                <div className='flex place-content-center'>
                                    <div className='text-sm flex px-2 py-2 md:px-10 border rounded-2xl w-fit my-4 text-center italic font-bold'>For an article on ETT Troubleshooting, <Link to='../ett-troubleshooting'> <span class='text-sm text-center font-bold text-blue-400 my-0 flex justify-center hover:bg-blue-300 hover:text-white'>click here</span></Link></div>
                                </div>

                                <ul class="space-y-5 text-white list-disc list-outside px-6 md:px-16 text-sm md:text-lg">

                                    <li className='font-bold mb-2'>Procedure for Endotracheal Intubation in the Operation Theater for Anesthesia:</li>

                                    <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-decimal list-outside">
                                        <li class='font-bold'>Preparation:</li>
                                        <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-disc list-outside">
                                            <li>Gather necessary equipment: endotracheal tube, laryngoscope, stylet, syringe, bag-valve mask, oral airway, end-tidal carbon dioxide (EtCO2) monitor, and suction.</li>
                                            <li>Position the patient on the operating table with proper padding and alignment.</li>
                                            <div class='max-w-fit md:px-4 md:max-w-[400px] justify-center items-center mb-4'>
                                                <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://www.aclsmedicaltraining.com/wp-content/uploads/2019/02/Flexion-of-the-neck-2.jpg" alt="circular" />
                                                <div className='text-sm text-center font-bold'>Position the patient along the pharyngeal axis (PA)</div>
                                                <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                            </div>
                                            <li>Ensure adequate lighting and visualization of the patient's airway.</li>
                                            <li>Perform a preoperative assessment of the patient's airway.</li>
                                        </ol>

                                        <li class='font-bold'>Sterile Field Setup:                                  </li>
                                        <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-disc list-outside">
                                            <li>Put on sterile gloves and prepare a sterile field using sterile drapes.</li>
                                            <li>Ensure the sterile field covers the patient's mouth, nose, and surrounding area.</li>
                                        </ol>

                                        <li class='font-bold'>Induction of Anesthesia:    </li>
                                        <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-disc list-outside">
                                            <li>Administer appropriate <Link to='../commonanesthetics'><span className='text-blue-300 underline text-sm md:text-lg hover:text-white'>intravenous induction agents and muscle relaxants</span></Link> as per the anesthetic plan.</li>
                                            <li>Maintain oxygenation and ventilation using a <Link to='../basicairwaymanagement'><span className='text-blue-300 underline text-sm md:text-lg hover:text-white'> bag-valve mask</span></Link> or other suitable methods.</li>
                                        </ol>

                                        <li class='font-bold'>Laryngoscope Insertion and Visualization:   </li>
                                        <label htmlFor="my-modalvideo" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-sm md:text-xs text-gray-800 font-bold rounded-full lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Watch Video</label>

                                        <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-disc list-outside">

                                            <ul class="space-y-5 text-white list-[lower-latin] list-outside text-sm md:text-lg">

                                                <ol class="pl-2 md:pl-2 mt-2 space-y-4 list-[lower-latin] list-outside">
                                                    <li class='font-bold'>Insert the blade:</li>
                                                    <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-[lower-roman] list-outside">
                                                        <li>Hold the laryngoscope in the left hand (or non-dominant hand)</li>
                                                        <li>Insert the blade of the laryngoscope into the right side of the fully open mouth</li>
                                                        <li>Pass the blade to the right of the tongue</li>
                                                        <li>Avoid contact with the soft tissues and teeth.</li>
                                                    </ol>

                                                    <li class='font-bold'>Advance the blade:</li>
                                                    <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-[lower-roman] list-outside">
                                                        <li>Advance the blade down the right side of the tongue</li>
                                                        <li>“Sweep” the tongue to the left and out of the way</li>
                                                        <li>Continue to advance the blade towards the base of the tongue</li>
                                                        <li>Return the tip of the blade to the midline, <span class='font-bold'>identifying the epiglottis,</span> prior to placing the tip into the vallecula.</li>
                                                    </ol>

                                                    <li class='font-bold'>Lift the epiglottis:</li>
                                                    <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-[lower-roman] list-outside">
                                                        <li>Use a moderate lifting force applied along the line of the laryngoscope handle (at a 45-degree angle to the floor) to lift the epiglottis and reveal the glottis</li>
                                                        <li>Do not lever backwards on to the top teeth</li>
                                                        <li>while doing this it is essential to maintain mouth opening and head and neck position.</li>
                                                    </ol>
                                                    <div class='max-w-fit md:max-w-[400px] justify-center items-center mb-8'>
                                                        <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://www.paediatricemergencies.com/wp-content/uploads/2017/02/Pull-dont-lever.png" alt="circular" />
                                                        <div className='text-sm text-center italic font-bold'>Lift the Laryngoscope, do not rotate as a lever on fulcrum</div>
                                                        <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                        <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                                    </div>
                                                    <li class='font-bold'>Reveal the vocal cords:</li>
                                                    <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-[lower-roman] list-outside">
                                                        <li>The larynx should now be visible</li>
                                                    </ol>

                                                    <li class='font-bold'>Achieve the best view:</li>
                                                    <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-[lower-roman] list-outside">
                                                        <li>Once the best view has been achieved, the anaesthetist should not take their eye off the vocal cords.</li>
                                                    </ol>
                                                </ol>
                                            </ul>




                                            <div class='max-w-fit md:px-4 md:max-w-[400px] justify-center items-center mb-4'>
                                                <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://rc.rcjournal.com/content/respcare/59/6/850/F1.large.jpg?width=800&height=600&carousel=1" alt="circular" />
                                                <div className='text-sm text-center italic font-bold'>Cormack-Lehane grading on laryngoscopy <a class='text-sm text-center font-bold text-blue-400 my-0 flex justify-center hover:bg-blue-300 hover:text-white' href='https://www.uptodate.com/contents/image?imageKey=EM%2F55034' target="_blank" rel="noreferrer">(see here)<span className='scale-90'> <LuExternalLink /></span></a></div>
                                                <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                            </div>


                                        </ol>

                                        <li class='font-bold'>Endotracheal Tube Insertion:  </li>
                                        <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-disc list-outside">


                                            <ul class="space-y-5 text-white list-[lower-latin] list-outside md:px-3 text-sm md:text-lg">

                                                <ol class="pl-2 md:pl-2 mt-2 space-y-4 list-[lower-latin] list-outside">
                                                    <li>Identify the appropriate time to perform the intubation based on the patient's clinical condition and assessment. Insert tube only if cords are visulized or ask for senior help.</li>
                                                    <li>Maintain a view of the vocal cords while your assistant hands you the ETT</li>
                                                    <li>Hold the ETT towards the proximal end</li>
                                                    <li>Pass the ETT from the right side of the mouth, so that it can be seen approaching and entering the glottis in a direct line of sight (see image below)</li>
                                                    <li>Advance the ETT until the cuff is about 2 cm distal to the vocal cords (many ETTs have a black mark, indicating the depth at the cords)</li>
                                                    <li>Once positioned, take great care that the tube does not move. This is best done by holding the tube with your hand anchored on the patient’s cheek until the tube is secured.</li>

                                                </ol>
                                            </ul>
                                            <div className='md:flex h-fit md:gap-x-20'>
                                                <div class='max-w-fit md:px-4 md:max-w-[400px] justify-center items-center mb-4'>
                                                    <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://i.ibb.co/mXz22qt/Screenshot-2023-07-04-at-23-52-28.png" alt="circular" />
                                                    <div className='text-sm text-center font-bold'>Direct line of sight</div>
                                                    <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                    <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                                </div>
                                                <div class='max-w-fit md:min-w-[200px] md:px-4 md:max-w-[400px] justify-center items-center mb-4'>
                                                    <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://www.paediatricemergencies.com/wp-content/uploads/2017/02/Passing-the-endotracheal-tube.jpeg" alt="circular" />
                                                    <div className='text-sm text-center font-bold'>Hold the ETT obliquely while inserting for a better view of larynx</div>
                                                    <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                    <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                                </div>
                                            </div>
                                        </ol>


                                        <li class='font-bold'>Inflating the Cuff and Confirmation:    </li>
                                        <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-disc list-outside">
                                            <li>Your assistant will inflate the cuff to obtain a seal and prevent air leak from the lungs.</li>
                                            <li>your assistant will check the cuff pressure is within the normal range using a manometer.</li>
                                        </ol>

                                        <li class='font-bold'>Confirmation:    </li>
                                        <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-disc list-outside">
                                            <li>Visualise tube position through cords.</li>
                                            <li>Bilateral chest movement when squeezing the bag (visual +/– auscultation)</li>
                                            <li>You should see misting of the ETT</li>
                                            <li className='font-bold'>ETCO2 capnography trace must be present – THIS IS CRUCIAL</li>
                                            <li>Once confirmed, secure the ETT with cotton bandage or paper-tape, whatever is avaialble.</li>
                                        </ol>

                                        <li class='font-bold text-base break-normal'><Link to='../ett-troubleshooting'><span class=' text-blue-400 hover:bg-blue-300 text-base md:text-lg underline flex hover:text-white'>Complications and Troubleshooting: </span> </Link>   </li><span></span>
                                        <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-disc list-outside">
                                            <li>Be prepared to manage potential complications such as hypoxia, hypotension, laryngospasm, or tube misplacement.</li>
                                        </ol>

                                    </ol>

                                </ul>

                            </div>


                        </div>


                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Conclusion:</div>

                        <p class="mb-3 text-white dark:text-white px-4 indent-3 my-4 text-start text-sm md:text-lg">Effective troubleshooting during endotracheal tube intubation is crucial for ensuring successful airway management. By following key strategies such as optimizing patient position, utilizing external laryngeal manipulation, and addressing oesophageal or endobronchial intubation promptly, anaesthetists can overcome common challenges and enhance patient safety. Clear communication of encountered difficulties on the anaesthetic chart enables seamless continuity of care and empowers future anaesthetists to provide optimal airway management. Remember, thorough troubleshooting contributes to improved outcomes and enhances the overall success of the intubation procedure.</p>

                        <div className='text-xs md:text-base text-white font-bold my-8'>Literature From: <a class='text-xs md:text-base text-start font-bold text-blue-300 flex  hover:text-white hover:bg-blue-400' href='https://das.uk.com/files/2020/page/Tayside%20Mastery%20Learning%20Programme%20combined%20final-Aug26.pdf' target="_blank" rel="noreferrer">Tayside Mastery Learning Programme of DAS and Royal College of Anesthetists<span className='scale-50'> <LuExternalLink /></span>    </a></div>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div >


        </CalculatorWorking >

    );
};
