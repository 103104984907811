import React from 'react';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';
import { LuExternalLink } from 'react-icons/lu';
import { Link } from 'react-router-dom';


export const EmergencyETT = () => {




    return (

        <CalculatorWorking>


            <div className="container mb-20  mx-auto px-2 md:px-4 py-0">
                {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
                <h1 class="place-content-center block md:inline text-2xl text-start md:text-center md:text-4xl font-extrabold dark:text-white">Emergency Endotracheal Intubation: <div className='text-start text-sm md:text-2xl'>Ensuring Airway Patency in Critical Situations</div></h1>
                <div className='grid-cols-1 md:flex grid place-items-center place-content-center md:place-items-start px-0 md:px-8  my-2 md:my-6'>


                    <div className='flex gap-x-2 '>
                        <div className='px-0 md:px-8  mt-1'>
                            <label htmlFor="my-modalarrest" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">ETT Size Guide</label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my-modalarrest" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                    <label htmlFor="my-modalarrest" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                    <div className='flex gap-x-20'>
                                        <h3 className="font-bold text-gray-800 text-base mb-6">
                                            ETT Size Guide: </h3>



                                    </div>

                                    {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                    <ul class="space-y-4 text-gray-800  list-outside px-4 md:px-8 text-vsm  md:text-base">
                                        <div className='rounded-lg border-4 px-4'>
                                            <li className='font-bold'>Adults:</li>
                                            <ol class="pl-5 space-y-1 list-disc list-outside">
                                                <li>Most commonly used sizes in adults are between 7 & 8mm</li>
                                                <li>Sizes may vary according to the anatomical variations or abnormailities.</li>
                                            </ol>
                                        </div>

                                        <div className='rounded-lg border-4 p-4'>
                                            <li className='font-bold'>Paediatrics:</li>
                                            <ol class="pl-5 space-y-1 list-disc list-outside">
                                                <li>There is a formula to calculate the size of ETT in paediatric patients.</li>
                                                <li className='font-bold text-base md:text-lg'>ETT Size = [Age / 4 ] + 4</li>
                                            </ol>
                                        </div>


                                    </ul>



                                    <div className="modal-action">
                                        <label htmlFor="my-modalarrest" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className='px-0 md:px-8  mt-1'>

                            <label htmlFor="my-modalvideo" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Watch Video</label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my-modalvideo" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                    <label htmlFor="my-modalvideo" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                    <div className='flex gap-x-20'>
                                        <h3 className="font-bold text-gray-800 text-base mb-6">
                                            Endotracheal Intubation: Video</h3>



                                    </div>

                                    {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                    <h3 className="font-bold text-gray-800 text-sm mb-1">
                                        ETT Video</h3>
                                    <div class='max-w-fit flex justify-center items-center mb-4'>
                                        <iframe
                                            className='w-fit h-fit md:w-[560px] md:h-[250px] flex place-content-center place-items-center'
                                            src="https://www.youtube.com/embed/8AOB2PtHfVM"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowfullscreen>

                                        </iframe>
                                    </div>
                                    <h3 className="font-bold text-gray-800 text-sm mb-1">
                                        ETT Tips & Tricks</h3>
                                    <div class='max-w-fit flex justify-center items-center'>
                                        <iframe
                                            className='w-fit h-fit md:w-[560px] md:h-[250px] flex place-content-center place-items-center'
                                            src="https://www.youtube.com/embed/x2FOdgw93sA"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowfullscreen>

                                        </iframe>
                                    </div>


                                    <div className="modal-action">
                                        <label htmlFor="my-modalvideo" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>




                </div>


                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>


                        <div>
                            <div>
                                <p class="mb-10 text-white dark:text-white px-2 indent-6 my-4 text-start text-sm md:text-lg">Emergency endotracheal intubation is a life-saving procedure used in critical situations to secure the airway and enable effective ventilation in patients who are unable to breathe adequately on their own. This article provides a concise overview of the key aspects of emergency endotracheal intubation, including indications, equipment required, procedure steps, and potential complications.</p>
                                <div className='text-sm flex px-2 py-2 md:px-10 border rounded-2xl w-fit my-8 content-start text-start italic font-bold'>For detailed article on Endotracheal Intubation, <Link to='/interns/anesthesia/ett-anesthesia'> <span class='text-sm text-center font-bold text-blue-400 my-0 flex justify-center hover:bg-blue-300 hover:text-white'>click here</span></Link></div>

                                <ul class="space-y-4 text-white list-disc list-outside px-6 md:px-16 text-sm md:text-lg">

                                    <li className='font-bold text-sm md:text-lg'> Indications:</li>
                                    <div className='pl-0 mt-2 space-y-1'>Emergency endotracheal intubation is typically performed in the following scenarios:</div>
                                    <ol class="pl-2 md:pl-5 mt-2 space-y-1 list-decimal list-outside">
                                        <li>Respiratory failure or impending respiratory failure.</li>
                                        <li>Inability to maintain a clear and patent airway.</li>
                                        <li>Trauma or injury affecting the airway.</li>
                                        <li>Cardiac arrest requiring advanced life support measures.</li>
                                        <li>Severe acute respiratory distress syndrome (ARDS).</li>
                                        <li>Management of upper airway obstruction or foreign body aspiration.</li>
                                    </ol>

                                    <li className='font-bold'>Equipment Required:</li>
                                    <div className='pl-0 mt-2 space-y-1'>To perform emergency endotracheal intubation, the following equipment is necessary:</div>
                                    <ol class="pl-2 md:pl-5 mt-2 space-y-1 list-disc list-outside">
                                        <li><span class='font-bold'>Endotracheal tube (ETT): </span>A flexible tube inserted through the vocal cords into the trachea to secure the airway and allow for mechanical ventilation.</li>
                                        <li><span class='font-bold'>Laryngoscope: </span>A device with a handle and blade used to visualize the vocal cords and guide the placement of the endotracheal tube.</li>
                                        <li><span class='font-bold'>Syringe: </span>Used to inflate the cuff of the endotracheal tube once it is in the trachea.</li>
                                        <li><span class='font-bold'>Stylet: </span>A rigid wire that can be inserted into the endotracheal tube to provide shape and stiffness for easier insertion.</li>
                                        <li><span class='font-bold'>Bag-mask ventilation device: </span>Used for manual ventilation if needed before or after intubation.</li>
                                        <li><span class='font-bold'>Monitoring equipment: </span>Such as a pulse oximeter and capnography monitor, to assess the patient's oxygenation and ventilation status during the procedure.</li>
                                    </ol>

                                    <li className='font-bold'>Procedure Steps:</li>
                                    <label htmlFor="my-modalvideo" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-sm md:text-xs text-gray-800 font-bold rounded-full lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Watch Video Here</label>



                                    <div className='pl-0 mt-2 space-y-1'>The steps involved in emergency endotracheal intubation generally include:</div>
                                    <ol class="pl-2 md:pl-5 mt-2 space-y-1 list-decimal list-outside">
                                        <li><div class='font-bold'>Preoxygenation: </div>Administering high-flow oxygen to the patient to optimize oxygen reserves before the procedure.</li>
                                        <li><span class='font-bold'>Position the patient</span> on the operating table with proper alignment along the Pharyngeal Axis.</li>
                                        <div class='max-w-fit md:px-4 md:max-w-[400px] justify-center items-center mb-4'>
                                            <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://www.aclsmedicaltraining.com/wp-content/uploads/2019/02/Flexion-of-the-neck-2.jpg" alt="circular" />
                                            <div className='text-sm text-center font-bold'>Position the patient along the pharyngeal axis (PA)</div>
                                            <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                            <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                        </div>
                                        <li><div class='font-bold'>Rapid sequence induction (RSI): </div>Administering an induction agent and a neuromuscular blocking agent to achieve unconsciousness and muscle relaxation.</li>

                                        <li class='font-bold'>Laryngoscope Insertion and Visualization:   </li>
                                        <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-disc list-outside">

                                            <ul class="space-y-5 text-white list-[lower-latin] list-outside text-sm md:text-lg">

                                                <ol class="pl-2 md:pl-2 mt-2 space-y-4 list-[lower-latin] list-outside">
                                                    <li class='font-bold'>Insert the blade:</li>
                                                    <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-[lower-roman] list-outside">
                                                        <li>Hold the laryngoscope in the left hand (or non-dominant hand)</li>
                                                        <li>Insert the blade of the laryngoscope into the right side of the fully open mouth</li>
                                                        <li>Pass the blade to the right of the tongue</li>
                                                        <li>Avoid contact with the soft tissues and teeth.</li>
                                                    </ol>

                                                    <li class='font-bold'>Advance the blade:</li>
                                                    <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-[lower-roman] list-outside">
                                                        <li>Advance the blade down the right side of the tongue</li>
                                                        <li>“Sweep” the tongue to the left and out of the way</li>
                                                        <li>Continue to advance the blade towards the base of the tongue</li>
                                                        <li>Return the tip of the blade to the midline, <span class='font-bold'>identifying the epiglottis,</span> prior to placing the tip into the vallecula.</li>
                                                    </ol>

                                                    <li class='font-bold'>Lift the epiglottis:</li>
                                                    <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-[lower-roman] list-outside">
                                                        <li>Use a moderate lifting force applied along the line of the laryngoscope handle (at a 45-degree angle to the floor) to lift the epiglottis and reveal the glottis</li>
                                                        <li>Do not lever backwards on to the top teeth</li>
                                                        <li>while doing this it is essential to maintain mouth opening and head and neck position.</li>
                                                    </ol>
                                                    <div class='max-w-fit md:max-w-[400px] justify-center items-center mb-8'>
                                                        <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://www.paediatricemergencies.com/wp-content/uploads/2017/02/Pull-dont-lever.png" alt="circular" />
                                                        <div className='text-sm text-center italic font-bold'>Lift the Laryngoscope, do not rotate as a lever on fulcrum</div>
                                                        <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                        <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                                    </div>
                                                    <li class='font-bold'>Reveal the vocal cords:</li>
                                                    <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-[lower-roman] list-outside">
                                                        <li>The larynx should now be visible</li>
                                                    </ol>

                                                    <li class='font-bold'>Achieve the best view:</li>
                                                    <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-[lower-roman] list-outside">
                                                        <li>Once the best view has been achieved, the anaesthetist should not take their eye off the vocal cords.</li>
                                                    </ol>
                                                </ol>
                                            </ul>




                                            <div class='max-w-fit md:px-4 md:max-w-[400px] justify-center items-center mb-4'>
                                                <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://rc.rcjournal.com/content/respcare/59/6/850/F1.large.jpg?width=800&height=600&carousel=1" alt="circular" />
                                                <div className='text-sm text-center italic font-bold'>Cormack-Lehane grading on laryngoscopy <a class='text-sm text-center font-bold text-blue-400 my-0 flex justify-center hover:bg-blue-300 hover:text-white' href='https://www.uptodate.com/contents/image?imageKey=EM%2F55034' target="_blank" rel="noreferrer">(see here)<span className='scale-90'> <LuExternalLink /></span></a></div>
                                                <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                            </div>


                                        </ol>

                                        <li class='font-bold'>Endotracheal Tube Insertion:  </li>
                                        <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-disc list-outside">


                                            <ul class="space-y-5 text-white list-[lower-latin] list-outside md:px-3 text-sm md:text-lg">

                                                <ol class="pl-2 md:pl-2 mt-2 space-y-4 list-[lower-latin] list-outside">
                                                    <li>Identify the appropriate time to perform the intubation based on the patient's clinical condition and assessment. Insert tube only if cords are visulized or ask for senior help.</li>
                                                    <li>Maintain a view of the vocal cords while your assistant hands you the ETT</li>
                                                    <li>Hold the ETT towards the proximal end</li>
                                                    <li>Pass the ETT from the right side of the mouth, so that it can be seen approaching and entering the glottis in a direct line of sight (see image below)</li>
                                                    <li>Advance the ETT until the cuff is about 2 cm distal to the vocal cords (many ETTs have a black mark, indicating the depth at the cords)</li>
                                                    <li>Once positioned, take great care that the tube does not move. This is best done by holding the tube with your hand anchored on the patient’s cheek until the tube is secured.</li>

                                                </ol>
                                            </ul>
                                            <div className='md:flex h-fit md:gap-x-20'>
                                                <div class='max-w-fit md:px-4 md:max-w-[400px] justify-center items-center mb-4'>
                                                    <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://i.ibb.co/mXz22qt/Screenshot-2023-07-04-at-23-52-28.png" alt="circular" />
                                                    <div className='text-sm text-center font-bold'>Direct line of sight</div>
                                                    <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                    <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                                </div>
                                                <div class='max-w-fit md:min-w-[200px] md:px-4 md:max-w-[400px] justify-center items-center mb-4'>
                                                    <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://www.paediatricemergencies.com/wp-content/uploads/2017/02/Passing-the-endotracheal-tube.jpeg" alt="circular" />
                                                    <div className='text-sm text-center font-bold'>Hold the ETT obliquely while inserting for a better view of larynx</div>
                                                    <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                    <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                                </div>
                                            </div>
                                        </ol>


                                        <li class='font-bold'>Inflating the Cuff and Confirmation:    </li>
                                        <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-disc list-outside">
                                            <li>Your assistant will inflate the cuff to obtain a seal and prevent air leak from the lungs.</li>
                                            <li>your assistant will check the cuff pressure is within the normal range using a manometer.</li>
                                        </ol>

                                        <li class='font-bold'>Confirmation:    </li>
                                        <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-disc list-outside">
                                            <li>Visualise tube position through cords.</li>
                                            <li>Bilateral chest movement when squeezing the bag (visual +/– auscultation)</li>
                                            <li>You should see misting of the ETT</li>
                                            <li className='font-bold'>ETCO2 capnography trace must be present – THIS IS CRUCIAL</li>
                                            <li>Once confirmed, secure the ETT with cotton bandage or paper-tape, whatever is avaialble.</li>
                                        </ol>
                                        <li><div class='font-bold'>Securing the tube: </div>Inflating the cuff of the endotracheal tube and securing it in place using tape or tube-holding devices.</li>
                                    </ol>

                                    <li className='font-bold'>Complications:</li>
                                    <div className='pl-0 mt-2 space-y-1'>While emergency endotracheal intubation is a critical procedure, it is not without potential complications, including:</div>
                                    <ol class="pl-2 md:pl-5 mt-2 space-y-1 list-[lower-latin] list-outside">
                                        <li>Difficult intubation or inability to visualize the vocal cords.</li>
                                        <li>Esophageal intubation or misplacement of the endotracheal tube.</li>
                                        <li>Hypoxia or desaturation during the procedure.</li>
                                        <li>Hemodynamic instability or changes in blood pressure and heart rate.</li>
                                        <li>Trauma to the airway structures or teeth.</li>
                                        <li>Aspiration of gastric contents.</li>
                                        <li>Infection or pneumonia.</li>
                                    </ol>


                                </ul>
                            </div>


                        </div>


                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Conclusion:</div>

                        <p class="mb-3 text-white dark:text-white px-4 indent-3 my-4 text-start text-sm md:text-lg">Emergency endotracheal intubation is a vital intervention in critical situations to secure the airway and enable effective ventilation in patients who are unable to breathe adequately on their own. Proper training, expertise, and adherence to guidelines are crucial to ensure successful outcomes and minimize complications. The prompt and accurate execution of emergency endotracheal intubation can significantly improve patient outcomes and increase the chances of survival in life-threatening emergencies.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div >


        </CalculatorWorking >

    );
};
