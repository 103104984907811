import React from 'react';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';




export const PreOperativeAssessment = () => {




    return (

        <CalculatorWorking>


            <div className="container mb-20  mx-auto px-2 md:px-4 py-0">
                {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
                <h1 class="place-content-center block md:inline text-xl text-start md:text-center md:text-4xl font-extrabold dark:text-white">Pre-operative Assessment:<div className='text-start text-sm font-bold md:text-2xl'>Ensuring Safety and Success</div></h1>

                <div className='grid-cols-1 md:flex grid place-items-center place-content-center md:place-items-start px-0 md:px-8  my-2 md:my-6'>


                    <div className='flex gap-x-2 '>
                        <div className='px-0 md:px-8  mt-1'>
                            <label htmlFor="my-modalarrest" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Pre-op Assessment Form</label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my-modalarrest" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                    <label htmlFor="my-modalarrest" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                    <div className='flex gap-x-20'>
                                        <h3 className="font-bold text-gray-800 text-base mb-6">
                                            Pre-op Assessment Form Example: </h3>



                                    </div>

                                    {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                    <ul class="space-y-4 text-gray-800  list-outside px-4 md:px-8 text-vsm  md:text-base">
                                        <div className='rounded-lg border-4 px-4'>
                                            <li className='font-bold'><a className='text-blue-600 text-vsm  md:text-base' href='https://www.westhertshospitals.nhs.uk/preoperative_assessment/documents/Patient_Preparation_for_Elective_Day_Surgery_v1_June11.pdf' rel="noreferrer" target="_blank" >Click here</a> to download Pre-operative Assessment Form from NHS Trust: West Hertfordshire Hospitals' Website</li>

                                        </div>

                                    </ul>



                                    <div className="modal-action">
                                        <label htmlFor="my-modalarrest" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className='px-0 md:px-8  mt-1'>

                            <label htmlFor="my-modalvideo" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Watch Video</label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my-modalvideo" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                    <label htmlFor="my-modalvideo" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                    <div className='flex gap-x-20'>
                                        <h3 className="font-bold text-gray-800 text-base mb-6">
                                            Endotracheal Intubation: Video</h3>



                                    </div>

                                    {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                    <h3 className="font-bold text-gray-800 text-sm mb-1">
                                        ETT Video</h3>
                                    <div class='max-w-fit flex justify-center items-center mb-4'>
                                        <iframe
                                            className='w-fit h-fit md:w-[560px] md:h-[250px] flex place-content-center place-items-center'
                                            src="https://www.youtube.com/embed/8AOB2PtHfVM"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowfullscreen>

                                        </iframe>
                                    </div>
                                    <h3 className="font-bold text-gray-800 text-sm mb-1">
                                        ETT Tips & Tricks</h3>
                                    <div class='max-w-fit flex justify-center items-center'>
                                        <iframe
                                            className='w-fit h-fit md:w-[560px] md:h-[250px] flex place-content-center place-items-center'
                                            src="https://www.youtube.com/embed/x2FOdgw93sA"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowfullscreen>

                                        </iframe>
                                    </div>


                                    <div className="modal-action">
                                        <label htmlFor="my-modalvideo" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>




                </div>


                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>


                        <div>
                            <div>
                                <p class="mb-10 text-white dark:text-white px-2 indent-6 my-4 text-start text-sm md:text-lg">
                                    Pre - operative anesthesia assessment is a critical and thorough evaluation conducted before a surgical procedure to ensure the safe and effective administration of anesthesia.It involves a comprehensive assessment of the patient's medical history, physical condition, and any potential risk factors that may impact anesthesia delivery and surgical outcomes. This assessment is typically performed by an anesthesiologist or a specialized anesthesia team, and it plays a crucial role in optimizing patient safety and perioperative care.
                                </p>
                                <p class="mb-10 text-white dark:text-white px-2 my-4 text-start text-sm md:text-lg">
                                    The components and steps involved in a pre - operative anesthesia assessment are as follows:
                                </p>

                                <ul class="space-y-4 text-white list-disc list-outside px-6 md:px-16 text-sm md:text-lg">
                                    <ol class="pl-2 md:pl-5 mt-2 space-y-1 list-decimal list-outside">
                                        <li className='font-bold text-sm md:text-lg'> Medical History Review:</li>
                                        <div className='pl-0 mt-2 space-y-1'>The anesthesiologist reviews the patient's medical records, including previous surgeries, allergies, chronic medical conditions (such as diabetes, heart disease, or respiratory disorders), and current medications. This information helps in understanding the patient's overall health status and guides the selection of the most appropriate anesthesia technique.</div>


                                        <li className='font-bold'>Physical Examination:</li>
                                        <div className='pl-0 mt-2 space-y-1'>A comprehensive physical examination is conducted to assess the patient's general health, vital signs, cardiovascular function, respiratory status, and any potential anatomical concerns that may affect anesthesia administration. The examination helps identify any underlying conditions or anatomical variations that could impact anesthesia management.</div>


                                        <li className='font-bold'>Evaluation of Airway and Breathing:</li>
                                        <div className='pl-0 mt-2 space-y-1'>In the pre-operative assessment, the evaluation of the airway is of paramount importance as it directly impacts the management of anesthesia and the safety of the patient during surgery. Several methods are employed to assess the airway, and they provide valuable information regarding its anatomy, potential difficulties, and the most appropriate approach for airway management. </div>
                                        <div className='pl-0 mt-2 space-y-1'>Following are the ways of assessment of airway assessment:</div>

                                        <ul class="space-y-4 text-white list-[lower-roman] list-outside px-6 md:px-16 text-sm md:text-lg mb-4">
                                            <li><div class='font-bold'>Mallampati Classification: </div>The Mallampati classification is a widely used method to assess the visibility and size of the oral cavity and pharynx. It involves asking the patient to open their mouth and protrude their tongue while the examiner is at level the same levels as that of patient and evaluates the structures. The classification ranges from I to IV.</li>


                                            <ol class="mt-2 pl-2 md:pl-5 list-disc list-outside">
                                                <div className='text-sm md:text-lg font-bold'>Mnemonic: PUSH - Keep removing one alphabet with every class </div>
                                                <li>Class I (PUSH): The entire tonsillar pillars, uvula, hard and soft palates are visualized</li>
                                                <li>Class II (USH): Partial uvula and soft palate are visualized</li>
                                                <li>Class III (SH): Only the soft palate is visualized</li>
                                                <li>Class IV (H): Only hard palate is visualized or no visualization of any structures beyond the tongue</li>
                                            </ol>

                                            <div class='max-w-fit items-center mb-4'>
                                                <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://www.uptodate.com/contents/images/EM/75229/Mallampaticlassification.jpg" alt="circular" />
                                                <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>  </div>

                                            <li><div class='font-bold'>Thyromental Distance: </div>The thyromental distance is measured as the distance between the thyroid notch and the tip of the chin with the patient's head fully extended. <span className='font-bold'>Short TMD has been defined as less than 6 cm.</span> A shorter thyromental distance may suggest limited space for airway management and potential difficulty in achieving proper alignment during intubation. <span className='font-bold'>It is the single most important indicator of a difficult intubation.</span> </li>
                                            <div class='max-w-fit md:max-w-[400px] justify-center items-center mb-4'>
                                                <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://www.e-safe-anaesthesia.org/sessions/02_03/jpg/ana_1_030_12_t1_01_med.jpg" alt="circular" />
                                                <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                            </div>

                                            <li><div class='font-bold'>Sternomental distance </div>Sternomental distance is measured between the sternal notch and the mandible, measured in full neck extension. <span className='font-bold'>Short sternomental distance is defined as less than 12 cm.</span> This parameter and TMD may be objective surrogates for adequacy of neck extension. </li>
                                            <li><div class='font-bold'>Mandibular protrusion (Upper lip bite test): </div>Patients are asked to protrude the lower jaw such that the mandibular teeth are in front of the maxillary teeth, as a predictor of the ability to sublux the mandible during laryngoscopy. A more objective, similar measurement is the upper lip bite test (ULBT), which assesses the patient's ability to reach and cover the upper lip with their lower incisors. ULBT grading includes</li>
                                            <ol class="mt-2 pl-2 md:pl-5 list-disc list-outside mb-4">
                                                <li>Grade 1: The patient can fully cover the upper lip with lower incisors</li>
                                                <li>Grade 2: The patient can partially cover the upper lip with lower incisors</li>
                                                <li>Grade 3: The patient cannot reach the upper lip with lower teeth</li>
                                            </ol>

                                            <li><div class='font-bold'>Interincisor Gap: </div>The interincisor gap is assessed by measuring the distance between the upper and lower incisors when the patient opens their mouth. A reduced interincisor gap may indicate limited mouth opening and potential challenges during intubation or mask ventilation. A crude method is to check whether patient can fit 3 fingers in his mouth in vertical orientation.</li>
                                            <div class='min-w-fit md:max-w-[300px] justify-center items-center mb-4'>
                                                <img class="scale-100 bg-white hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://images.ctfassets.net/vrrt8fsfwf0e/52ks0MsAxNIEtzluSskuJ5/42617bbe89f43be457efa247de4a59ec/Trismus_Art_1.svg" alt="circular" />
                                                <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                            </div>
                                            <li><div class='font-bold'>Neck Mobility: </div>Evaluation of neck mobility is crucial as restricted movement can impact airway positioning and intubation. Assessing the ability to flex, extend, and rotate the neck helps identify potential difficulties in achieving optimal airway alignment.</li>
                                            <li><div class='font-bold'>Radiological Imaging: </div>In some cases, e.g. retrosternal extension of thyroid, radiological imaging such as X-rays, computed tomography (CT), or magnetic resonance imaging (MRI) may be utilized to assess the airway anatomy in greater detail. These imaging techniques can help identify structural abnormalities, tumors, or other factors that may impede airway management.</li>
                                            <li><div class='font-bold'>Ultrasound: </div>Ultrasound can be employed to assess the airway in real-time. It allows visualization of the soft tissues, structures, and dynamic movements during respiration. Ultrasound can assist in identifying anatomical variations, determining the optimal site for airway interventions, and guiding the placement of devices such as supraglottic airway devices or percutaneous tracheostomy.</li>
                                            <li><div class='font-bold'>Endoscopic Evaluation: </div>In certain cases, a flexible or rigid endoscope may be used to directly visualize the airway and assess its anatomy. This technique provides a detailed view of the larynx, vocal cords, and any potential abnormalities that may affect airway management.</li>

                                        </ul>


                                        <li className='font-bold'>Assessment of Cardiovascular Function:</li>
                                        <div className='pl-0 mt-2 space-y-1'>The cardiovascular system is assessed through a combination of physical examination, review of medical history, and additional tests such as electrocardiogram (ECG) or echocardiography if necessary.This evaluation helps identify any cardiac conditions or risk factors that may require specific anesthesia considerations or monitoring during the surgical procedure.</div>

                                        <li className='font-bold'>Laboratory and Diagnostic Tests:</li>
                                        <div className='pl-0 mt-2 space-y-1'>Depending on the patient's medical history and the complexity of the surgery, additional laboratory tests may be ordered, such as blood tests, coagulation studies, imaging (e.g., chest X-ray), or other diagnostic tests. These tests help evaluate organ function, detect any underlying abnormalities, and guide anesthesia management.</div>

                                        <li className='font-bold'>Medication and Allergy Assessment:</li>
                                        <div className='pl-0 mt-2 space-y-1'>The anesthesiologist reviews the patient's medication history, including prescription drugs, over-the-counter medications, and herbal supplements. Special attention is given to medications that may interact with anesthesia medications or affect coagulation. Any known allergies or adverse drug reactions are documented to avoid potential complications.</div>

                                        <li className='font-bold'>Psychological and Social Evaluation:</li>
                                        <div className='pl-0 mt-2 space-y-1'>The patient's psychological well-being and social support system are assessed to identify any factors that may impact the patient's response to anesthesia and recovery.Pre - existing anxiety or mental health conditions are considered, and appropriate measures are taken to address patient comfort and support.</div>

                                        <li className='font-bold'>Anesthesia Plan and Risk Assessment:</li>
                                        <div className='pl-0 mt-2 space-y-1'>Based on the gathered information, the anesthesiologist develops an individualized anesthesia plan.This includes selecting the most suitable anesthesia technique(general anesthesia, regional anesthesia, or sedation), determining the dosage and administration of anesthesia medications, and considering any additional measures to ensure patient comfort, pain management, and safety during surgery.The assessment also involves evaluating the potential risks associated with anesthesia administration and developing strategies to mitigate those risks.</div>

                                        <li className='font-bold'>Communication and Collaboration:</li>
                                        <div className='pl-0 mt-2 space-y-1'>The anesthesiologist communicates with the surgical team, including the surgeon and nursing staff, to discuss the patient's specific needs, intraoperative challenges, and surgical requirements. Collaboration among the healthcare team ensures a coordinated approach to anesthesia administration and perioperative care.</div>

                                        <li className='font-bold'>Patient Education and Informed Consent:</li>
                                        <div className='pl-0 mt-2 space-y-1'>The anesthesiologist plays a vital role in educating the patient about the anesthesia process, potential risks, and expected outcomes.They address any concerns or questions the patient may have and obtain informed consent for anesthesia administration.This helps in establishing trust, ensuring patient autonomy, and promoting shared decision-making.</div>

                                    </ol>

                                </ul>
                            </div>


                        </div>




                        <p class="my-8 text-white dark:text-white px-4 indent-3 text-start text-sm md:text-lg">In conclusion, pre-operative anesthesia assessment is a comprehensive and detailed evaluation process that considers the patient's medical history, physical condition, and surgical requirements. It involves a systematic approach to gather relevant information, stratify risks, develop a tailored anesthesia plan, collaborate with the surgical team, educate the patient, and ensure optimal preparation for a safe and successful surgical experience. The expertise and thoroughness of the anesthesiologist during this assessment are crucial in providing personalized care and mitigating potential risks associated with anesthesia administration.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div >


        </CalculatorWorking >

    );
};
