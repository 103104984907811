import React from 'react';
import SaveAsPDF from '../../../../features/button/save-as-pdf';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';

export const ArterialSampling = () => {


    return (

        <CalculatorWorking>



            {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
            <h1 class="place-content-center text-2xl text-center md:text-4xl mb-15 font-extrabold dark:text-white">Arterial Sampling</h1>

            <div className="container mb-20 mx-auto px-4 py-0">



                <div className="flex place-items-center place-content-center gap-x-1 container grid-cols-none mx-auto py-0">


                    <div className='px-0 md:px-8 mt-2'>
                        <label htmlFor="my-modal" className="btn lg:mx-0 hover:underline md:min-w-[150px] h-[80px] md:h-fit bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Necessary Equipment Required</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modal" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white ">
                                <label htmlFor="my-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        List of Necessary Equipment for Arterial Sampling:</h3>


                                    <div> <SaveAsPDF /></div>
                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>


                                <ul class="space-y-4 text-gray-800 list-decimal list-outside px-4 md:px-8 text-vsm  md:text-base">

                                    <li className='font-bold'>Arterial Sampling Kit:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile arterial collection syringe with needle or catheter</li>
                                        <li>Heparinized flush syringe</li>
                                        <li>Sterile drapes</li>
                                        <li>Antiseptic solution for site preparation</li>
                                        <li>Sterile gauze</li>
                                        <li>Adhesive bandage</li>
                                        <li>Sharps container for safe disposal</li>
                                    </ol>

                                    <li className='font-bold'>Blood Gas Analyzer:</li>
                                    <ol class="pl-5 space-y-1 list-outside">
                                        <li>Ensure availability and proper functioning of a blood gas analyzer or a laboratory capable of analyzing arterial blood samples.</li>
                                    </ol>
                                    <li className='font-bold'>Personal Protective Equipment (PPE):</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li><span class='font-bold'>Gloves:</span> Sterile gloves for the healthcare professional performing the procedure.</li>
                                        <li><span class='font-bold'>Mask:</span> Depending on the clinical setting and patient condition, a mask may be necessary to protect against potential exposure to respiratory droplets.</li>
                                        <li><span class='font-bold'>Eye Protection:</span> Safety glasses or a face shield may be required to protect against splashes or potential blood-borne pathogens.</li>
                                        <li><span class='font-bold'>Gown:</span> A disposable gown may be needed to protect clothing from potential blood splatter.</li>
                                    </ol>
                                    <li className='font-bold'>Local Anesthesia (if applicable):</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Lidocaine or other local anesthetic, if needed and appropriate for local anesthesia at the arterial puncture site.</li>
                                    </ol>

                                    <li className='font-bold'>Antiseptic Solution:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Use an antiseptic solution, such as chlorhexidine or povidone-iodine, for site preparation to maintain aseptic conditions.</li>
                                    </ol>

                                    <li className='font-bold'>Sterile Drape:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Use sterile drapes to create a sterile field around the arterial puncture site.</li>
                                    </ol>

                                    <li className='font-bold'>Sterile Gauze:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile gauze pads or sponges to apply pressure at the puncture site after the procedure.</li>
                                    </ol>

                                    <li className='font-bold'>Adhesive Bandage:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile adhesive bandage or dressing to cover the puncture site and provide secure protection.</li>
                                    </ol>


                                    <li className='font-bold'>Labels:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Labels for proper identification of the arterial blood sample, including patient details, site of collection, and time of sampling.</li>
                                    </ol>


                                    <li className='font-bold'>Sharps Container:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A designated sharps container for safe disposal of needles, syringes, and other sharps used during the procedure.</li>
                                    </ol>

                                    <li className='font-bold'>Documentation Materials:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Documentation forms or electronic medical records to record details of the procedure, including date, time, site of arterial puncture, volume of blood collected, complications, and analysis results.</li>
                                    </ol>
                                </ul>

                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-vsm md:text-base">It is essential to follow institutional protocols and guidelines, as well as adhere to aseptic techniques and infection control practices throughout the arterial sampling procedure.</p>


                                <div className="modal-action">
                                    <label htmlFor="my-modal" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalvideo" className="btn lg:mx-0 md:min-w-[150px] h-[80px] md:h-fit hover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Video of Allen Test</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvideo" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvideo" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Allen Test: Video</h3>



                                </div>

                                {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}
                                <h3 className="font-bold text-gray-800 text-base">
                                </h3>
                                <div class='max-w-fit flex justify-center items-center mb-4'>
                                    <iframe
                                        className='w-fit h-fit md:w-[560px] md:h-[360px] flex place-content-center place-items-center'
                                        src="https://www.youtube.com/embed/wXH6YbDAwbI"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen>

                                    </iframe>
                                </div>

                                <div className="modal-action">
                                    <label htmlFor="my-modalvideo" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalvideo" className="btn lg:mx-0 md:min-w-[150px] h-[80px] md:h-fit hover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Video of Arterial Sampling</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvideo" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvideo" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Arterial Sampling: Video</h3>



                                </div>

                                {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}
                                <h3 className="font-bold text-gray-800 text-base">
                                </h3>
                                <div class='max-w-fit flex justify-center items-center mb-4'>
                                    <iframe
                                        className='w-fit h-fit md:w-[560px] md:h-[360px] flex place-content-center place-items-center'
                                        src="https://www.youtube.com/embed/0BSv4iN8T2E"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen>

                                    </iframe>
                                </div>

                                <div className="modal-action">
                                    <label htmlFor="my-modalvideo" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>




                <div className='w-100'>
                    <div className='grid grid-cols-1 place-items-start'>



                        <p class=" text-white dark:text-white px-4 indent-6 my-4 text-start text-sm md:text-lg">Arterial sampling is a procedure that involves obtaining a blood sample from an artery for diagnostic or monitoring purposes. It is commonly performed in clinical settings, especially in critical care units, to assess arterial blood gases, acid-base balance, oxygenation, and other parameters.</p>
                        <p class="mb-10 text-white dark:text-white px-4 my-1 text-start text-sm md:text-lg">The following is a note outlining the key considerations and steps involved in arterial sampling, based on NICE (National Institute for Health and Care Excellence) guidelines:</p>





                        <ul class="space-y-4 text-white list-disc list-outside pl-8 pr-4 md:px-16 text-sm md:text-lg">
                            <li className='font-bold'> Indication:</li>

                            <ol class="pl-5 mt-2">
                                <li>Arterial sampling is indicated when precise and immediate measurement of arterial blood gases, pH, and other arterial parameters is required. It is commonly performed in patients with respiratory or metabolic disorders, critically ill patients, and those undergoing certain surgical procedures.</li>
                            </ol>

                            <label htmlFor="my-modalvideo" className="btn lg:mx-0 md:min-w-[150px] h-[80px] md:h-fit hover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Video of Arterial Sampling</label>


                            <li className='font-bold'> Informed Consent: </li>
                            <ol class="pl-5 mt-2">
                                <li>Obtain informed consent from the patient or their legal representative, explaining the purpose, risks, benefits, and alternatives of arterial sampling. Discuss any specific contraindications or precautions, such as bleeding disorders or infection at the site.</li>
                            </ol>

                            <li className='font-bold'>Hand Hygiene and Personal Protective Equipment (PPE):</li>
                            <ol class="pl-5 mt-2 space-y-1 list-outside">
                                <li>Perform hand hygiene and wear appropriate PPE, including gloves and possibly a gown, mask, and eye protection, to maintain aseptic conditions and prevent transmission of infections.</li>
                            </ol>

                            <li className='font-bold'> Equipment Preparation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li><div className='font-bold'>Arterial Sampling Kit:</div> Assemble an arterial sampling kit containing the necessary equipment, including an arterial collection syringe, heparinized flush syringe, sterile drapes, antiseptic solution, gauze, adhesive bandage, and a sharps container.</li>
                                <li><div className='font-bold'>Blood Gas Analyzer:</div> Ensure the availability and proper functioning of a blood gas analyzer or a laboratory capable of analyzing arterial blood samples.</li>
                            </ol>

                            <li className='font-bold'> Patient Positioning: </li>
                            <ol class="pl-5 mt-2 space-y-1 list-outside">
                                <li>Position the patient in a comfortable and appropriate position for arterial sampling, such as supine with the affected limb extended and well-supported.</li>
                            </ol>

                            <li className='font-bold'>Site Selection:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-outside">
                                <li>Identify the appropriate arterial site for sampling, typically the radial artery in adults. Alternative sites include the brachial or femoral artery. Consider factors such as patient age, arterial accessibility, collateral circulation, and any contraindications.</li>

                            </ol>

                            <li className='font-bold'>Perform Allen Test:</li>
                            <div class="mt-2">The Allen test is performed prior to arterial sampling to assess the collateral circulation of the hand. It helps determine if the radial or ulnar artery can be safely used for sampling, minimizing the risk of ischemic complications. Here's a brief description of the Allen test procedure:</div>
                            <label htmlFor="my-modalvideo" className="btn lg:mx-0 md:min-w-[150px] h-[80px] md:h-fit hover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Video of Allen Test</label>

                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Have the patient sit or lie down comfortably, with their arm extended and palm facing up.</li>
                                <li>Locate the radial and ulnar arteries on the patient's wrist, typically at the base of the thumb and the side of the little finger, respectively.</li>
                                <li>Apply gentle pressure to both the radial and ulnar arteries simultaneously to occlude blood flow. Instruct the patient to clench their fist several times to empty the hand of blood.</li>
                                <li>While maintaining pressure on both arteries, release the pressure on the ulnar artery while keeping the radial artery occluded. Observe the patient's hand for any pallor (indicating poor collateral circulation).</li>
                                <li>Release the pressure on the radial artery and occlude the ulnar artery. Again, observe the hand for any pallor. Both the radial and ulnar arteries should be released and blood flow restored.</li>
                                <li>If the hand quickly regains its normal color (within 5-10 seconds) after releasing the pressure on both arteries, collateral circulation is considered sufficient, and either artery can be used for arterial sampling.</li>
                                <li>Document the results of the Allen test, including the time taken for the hand to regain color, and note which artery (radial or ulnar) will be used for arterial sampling.</li>

                            </ol>

                            <li className='font-bold'> Site Preparation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Perform hand hygiene and wear sterile gloves.</li>
                                <li>Cleanse the selected arterial puncture site with an antiseptic solution, following aseptic technique and allowing sufficient drying time.</li>
                            </ol>



                            <li className='font-bold'> Local Anesthesia (if applicable): </li>
                            <ol class="pl-5 mt-2 space-y-1 list-outside">
                                <li>Administer local anesthesia, such as lidocaine, at the arterial puncture site, if necessary and appropriate. Ensure the patient's comfort and monitor for any adverse reactions.</li>
                            </ol>

                            <li className='font-bold'> Arterial Puncture:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Use a sterile technique to perform the arterial puncture, inserting a sterile arterial needle or catheter into the chosen artery.</li>
                                <li>Connect the arterial collection syringe to the needle or catheter and withdraw the desired blood sample slowly and steadily.</li>
                                <li>Collect an appropriate volume of blood, typically specified by the laboratory or clinical guidelines, to ensure accurate analysis.</li>
                            </ol>

                            <li className='font-bold'> Post-procedure:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Apply firm pressure over the arterial puncture site using sterile gauze to achieve hemostasis.</li>
                                <li>Withdraw the needle or catheter and apply a sterile adhesive bandage or dressing to the site.</li>
                                <li>Dispose of sharps in a designated sharps container.</li>
                                <li>Label the arterial blood sample with the patient's details, site of collection, and the time of sampling.</li>
                                <li>Transport the blood sample promptly and appropriately to the laboratory or blood gas analyzer for analysis.</li>
                            </ol>

                            <li className='font-bold'> Documentation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-outside">
                                <li>Document the arterial sampling procedure in the patient's medical records, including details such as the date and time of the procedure, result of Allen test, the site of arterial puncture, the volume of blood collected, any complications or adverse events, and the analysis results.</li>
                            </ol>



                        </ul>
                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Remember:</div>

                        <p class="mb-3 text-white dark:text-white px-4 my-4 text-start text-sm md:text-lg">Arterial sampling is a specialized procedure that requires adequate training, experience, and adherence to local protocols and guidelines. Healthcare professionals performing arterial sampling should ensure patient safety, maintain aseptic technique, and monitor for any complications during and after the procedure.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div>




        </CalculatorWorking >

    );
};
