import React, { useEffect } from 'react';
import './App.css';
import { Router } from './router';
import { hotjar } from 'react-hotjar';

hotjar.initialize('3538474', '6');

const App = () => {
  useEffect(() => {
    // Identify the user
    hotjar.identify('USER_ID', { userProperty: 'value' });
  }, [])
  return (
    <Router />
    

  );
};

export default App;

