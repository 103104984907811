import React, { Component } from 'react'

class DefaultBottomDialogue extends Component {
    render() {
        return (
            <p class="tracking-loose font-bold text-sm md:text-xl w-full">a project of <a class="tracking-loose font-bold text-sm md:text-xl w-full hover:bg-sky-700" href='https://alostmedic.com'>alostmedic.com</a></p>
        )
    }
}

export default DefaultBottomDialogue