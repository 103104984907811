import React from 'react';
import SaveAsPDF from '../../../../features/button/save-as-pdf';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';

export const NasogastricTubeInsertion = () => {


    return (

        <CalculatorWorking>



            {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
            <h1 class="place-content-center text-2xl text-center md:text-4xl mb-15 font-extrabold dark:text-white">Nasogastric Tube Insertion</h1>

            <div className="container mb-20 mx-auto px-4 py-0">



                <div className="flex place-items-center place-content-center gap-x-1 container grid-cols-none mx-auto py-0">


                    <div className='px-0 md:px-8 mt-2'>
                        <label htmlFor="my-modal" className="btn lg:mx-0 hover:underline md:min-w-[150px] h-[80px] md:h-fit bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Necessary Equipment Required</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modal" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white ">
                                <label htmlFor="my-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        List of Necessary Equipment for NG Tube Insertion:</h3>


                                    <div> <SaveAsPDF /></div>
                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>


                                <ul class="space-y-4 text-gray-800 list-decimal list-outside px-4 md:px-8 text-vsm  md:text-base">

                                    <li className='font-bold'>Nasogastric Tube:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A flexible tube made of various materials, such as polyvinyl chloride (PVC) or silicone, available in different sizes and lengths. Choose the appropriate size based on the patient's age and the purpose of insertion.</li>
                                    </ol>

                                    <li className='font-bold'>Water-soluble Lubricant:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A water-based lubricant used to ease the insertion of the nasogastric tube. Ensure it is suitable for medical use and compatible with the chosen tube material.</li>
                                    </ol>
                                    <li className='font-bold'>Adhesive Tape or Tube Holder:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Used to secure the nasogastric tube to the patient's nose or cheek after insertion. Adhesive tape should be hypoallergenic and gentle on the skin, while tube holders provide a more secure and comfortable attachment.</li>
                                    </ol>
                                    <li className='font-bold'>Syringe:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A syringe, typically 20 or 50 mL in size, is used for various purposes during nasogastric tube insertion, such as aspirating stomach contents or flushing the tube to ensure patency.</li>
                                    </ol>

                                    <li className='font-bold'>Litmus pH Paper Kit:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>For testing pH of gastric aspirate on the bedside to confirm the position of nasogastric tube</li>
                                    </ol>

                                    <li className='font-bold'>Stethoscope (if Litmus kit is not available):</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A stethoscope is necessary to auscultate for the presence of air in the stomach during the verification of tube placement.</li>
                                    </ol>

                                    <li className='font-bold'>Water for Flushing:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile or clean water is used to flush the nasogastric tube before and after insertion to ensure patency and proper functioning.</li>
                                    </ol>

                                    <li className='font-bold'>Marking Pen:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A marking pen is used to mark the external length of the nasogastric tube at the nostril or lip level. This helps in monitoring the tube's position and detecting any displacement.</li>
                                    </ol>


                                    <li className='font-bold'>Personal Protective Equipment (PPE):</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Healthcare professionals should wear appropriate PPE, including gloves and an apron, to maintain infection control during the procedure.</li>
                                    </ol>


                                    <li className='font-bold'>Cup of Water:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A cup of water or a small drink is often provided to the patient to aid in swallowing and facilitate the passage of the nasogastric tube into the stomach.</li>
                                    </ol>
                                </ul>

                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-vsm md:text-base">It is important to note that the specific equipment required for nasogastric tube insertion may vary depending on institutional protocols, patient needs, and healthcare provider preferences. Additionally, additional equipment may be needed for specific purposes, such as pH paper to test gastric aspirate pH or a suction device for gastric decompression. Healthcare professionals should consult institutional guidelines and refer to manufacturer instructions for specific equipment to ensure safe and effective nasogastric tube insertion.</p>


                                <div className="modal-action">
                                    <label htmlFor="my-modal" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalvials" className="btn lg:mx-0 h-[80px] md:h-fit shover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">NG Tube Color Guide</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvials" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvials" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        NG Tube Size Color Guide:</h3>


                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                <div className="overflow-x-auto">
                                    <table className="rounded-lg bg-gray-800 table-pin-cols">
                                        {/* head */}
                                        <thead>
                                            <tr>
                                                <th className='w-[25px]'>Color</th>
                                                <th className='w-[25px]'>Width</th>
                                                <th className='w-[25px] max-w-[25px]'></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* row 1 */}
                                            <tr>
                                                <th className='bg-[#9bce31] text-vsm md:text-xs font-bold text-white text-center'>Light Green</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>6 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>2.0 mm</td>

                                            </tr>

                                            {/* row 2 */}
                                            <tr>
                                                <th className='bg-[#6495ec] text-vsm md:text-xs font-bold text-white text-center'>Light Blue</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center mb-2'>8 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'> 2.7 mm</td>
                                            </tr>


                                            {/* row 3 */}
                                            <tr>
                                                <th className='bg-[#000000] text-vsm md:text-xs font-bold text-white text-center'>Black</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center mb-2'>10 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'> 3.3 mm</td>
                                            </tr>


                                            {/* row 4 */}
                                            <tr>
                                                <th className='bg-[#ffffff] text-vsm md:text-xs font-bold text-black text-center'>White</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>12 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>4.0 mm</td>

                                            </tr>


                                            {/* row 5 */}
                                            <tr>
                                                <th className='bg-[#008001] text-vsm md:text-xs font-bold text-white text-center'>Dark Green</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>14 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>4.7 mm</td>
                                            </tr>


                                            {/* row 6 */}

                                            <tr>
                                                <th className='bg-[#ff8617] text-vsm md:text-xs font-bold text-white text-center'>Orange</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>16 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>5.3 mm<div className='text-vsm md:text-xs text-white text-start'> Most commonly used in adults</div></td>

                                            </tr>

                                            {/* row 7 */}

                                            <tr>
                                                <th className='bg-[#ff0000] text-vsm md:text-xs font-bold text-white text-center'>Red</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>18 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>6.0 mm</td>
                                            </tr>

                                            {/* row 8 */}

                                            <tr>
                                                <th className='bg-[#ffff00] text-vsm md:text-xs font-bold text-gray-800 text-center'>Yellow</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>20 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>6.7 mm</td>
                                            </tr>


                                            {/* row 8 */}


                                            <tr>
                                                <th className='bg-[#b955d3] text-vsm md:text-xs font-bold text-white text-center'>Purple</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>22 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>7.3 mm</td>
                                            </tr>

                                            {/* row 9 */}

                                            <tr>
                                                <th className='bg-[#0000ff] text-vsm md:text-xs font-bold text-white text-center'>Dark Blue</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>24 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>8.0 mm</td>
                                            </tr>
                                            {/* row 10 */}
                                            <tr>
                                                <th className='bg-[#ff11ad] text-vsm md:text-xs font-bold text-white text-center'>Pink</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>26 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>8.7 mm</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>


                                {/* <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-base md:text-xl">For more details, <a href='https://en.wikipedia.org/wiki/Vacutainer' target="_blank" rel="noreferrer" className='hover:text-white dark:text-blue-600 text-start text-base md:text-xl hover:bg-blue-400'>click here</a></p> */}







                                <div className="modal-action">
                                    <label htmlFor="my-modalvials" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className='px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalvideo" className="btn lg:mx-0 md:min-w-[150px] h-[80px] md:h-fit hover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Watch Video</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvideo" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvideo" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Nasogastric Tube Insertion: Video</h3>



                                </div>

                                {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}
                                <h3 className="font-bold text-gray-800 text-base">
                                </h3>
                                <div class='max-w-fit flex justify-center items-center mb-4'>
                                    <iframe
                                        className='w-fit h-fit md:w-[560px] md:h-[360px] flex place-content-center place-items-center'
                                        src="https://www.youtube.com/embed/QZRnpwxNJfw"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen>

                                    </iframe>
                                </div>

                                <div className="modal-action">
                                    <label htmlFor="my-modalvideo" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>




                <div className='w-100'>
                    <div className='grid grid-cols-1 place-items-start'>



                        <p class=" text-white dark:text-white px-4 indent-6 my-4 text-start text-sm md:text-lg">Nasogastric tube insertion is a medical procedure that involves the insertion of a tube through the nose and into the stomach. It is commonly used for various purposes such as administering medications, feeding, decompressing the stomach, or obtaining gastric samples.</p>
                        <p class="mb-10 text-white dark:text-white px-4 my-1 text-start text-sm md:text-lg">The following is a general note outlining the procedure for nasogastric tube insertion based on guidelines from the National Institute for Health and Care Excellence (NICE):</p>





                        <ul class="space-y-4 text-white list-disc list-outside pl-8 pr-4 md:px-16 text-sm md:text-lg">
                            <li className='font-bold'> Preparation:</li>
                            <ol class=" text-white dark:text-white my-4 text-start text-sm md:text-lg">Suprapubic catheterization may be indicated in various situations, including but not limited to:</ol>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Gather the necessary equipment, including a nasogastric tube, water-soluble lubricant, tape, a syringe, and a stethoscope.</li>
                                <li>Explain the procedure to the patient and obtain informed consent.</li>
                                <li>Position the patient in an upright or semi-upright position, if possible.</li>
                                <li>Wear appropriate personal protective equipment (e.g., gloves, apron) to maintain infection control.</li>

                            </ol>

                            <li className='font-bold'> Nasogastric Tube Selection:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Choose the appropriate size and type of nasogastric tube based on the patient's age, condition, and purpose of insertion.</li>
                                <li>Ensure that the tube is of sufficient length to reach the stomach comfortably.</li>
                            </ol>

                            <li className='font-bold'>Lubrication and Insertion:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Lubricate the distal end of the nasogastric tube with a water-soluble lubricant to facilitate insertion.</li>
                                <li>Gently insert the tube through the patient's nostril, aiming downward and toward the back of the throat.</li>
                                <li>Ask the patient to swallow or sip water to aid the passage of the tube into the esophagus and stomach.</li>
                                <li>Advance the tube slowly and carefully, encouraging the patient to breathe through their mouth if necessary.</li>
                                <li>Assess the tube's placement by checking for bilateral breath sounds and aspiration of stomach contents (aspirate stomach contents using a syringe).</li>
                            </ol>

                            <li className='font-bold'> Securing the Tube:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Once the tube is in the correct position, secure it to the patient's nose using adhesive tape or a tube holder.</li>
                                <li>Ensure that the tube is not pulled excessively tight, allowing for movement but preventing accidental dislodgement.</li>
                            </ol>

                            <li className='font-bold'> Confirmation of Placement:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li><div className='font-bold'>Crude/Unreilable method:</div>Use a stethoscope to auscultate for the presence of air in the stomach while injecting a small amount of air through the tube.</li>
                                <li><div className='font-bold'>Reliable Method:</div>Check pH of the gastric aspirate on the bedside, a pH of between 1 and 5.5 is usually confirmatory for correct NG placement.</li>
                                <li><div className='font-bold'>Do not hesitate doing this:</div>Consider obtaining an abdominal X-ray to confirm the tube's placement if there are concerns or uncertainties in the above methods.</li>
                            </ol>

                            <li className='font-bold'>Tube Position Verification:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Mark the external length of the inserted tube at the nostril or lip level for reference and monitoring.</li>

                            </ol>

                            <li className='font-bold'> Documentation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Type and size of tube</li>
                                <li>External length of tube or cm marking at nostril</li>
                                <li>pH of aspirate</li>
                            </ol>



                            <li className='font-bold'> Post-insertion Care:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Flush the nasogastric tube with the appropriate amount of water to ensure patency and verify placement.</li>
                                <li>Secure the tube to prevent accidental displacement during patient movement.</li>
                                <li>Provide the patient with appropriate education and instructions regarding tube maintenance, feeding, and any necessary precautions.</li>
                            </ol>




                        </ul>
                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Note:</div>

                        <p class="mb-3 text-white dark:text-white px-4 my-4 text-start text-sm md:text-lg">It is essential to note that the specific steps and techniques for nasogastric tube insertion may vary based on institutional protocols, patient condition, and healthcare provider preferences. Adherence to aseptic techniques, proper positioning, and patient comfort are vital aspects of the procedure. Healthcare professionals performing nasogastric tube insertion should have adequate training, knowledge, and experience to ensure patient safety and minimize the risk of complications.</p>
                        <p class="mb-3 text-white dark:text-white px-4 my-4 text-start text-sm md:text-lg">Regular monitoring, repositioning, and care of the nasogastric tube are essential to prevent complications such as tube dislodgement, blockage, or irritation of the nasal passages. Healthcare professionals should follow institutional guidelines and consult specific manufacturer instructions for the equipment used in the procedure.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div>




        </CalculatorWorking >

    );
};
