import React from 'react';
import SaveAsPDF from '../../../../../features/button/save-as-pdf';
import DefaultBottomDialogue from '../../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../../calculatorworking';
import { FlashingText } from '../../../../../features';



export const SuprapubicCatheter = () => {


    return (

        <CalculatorWorking>



            {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
            <h1 class="place-content-center text-2xl text-center md:text-4xl mb-15 font-extrabold dark:text-white">Supra-pubic Catheterization</h1>

            <div className="container mb-20 mx-auto px-4 py-0">



                <div className="flex place-items-center place-content-center gap-x-3 md:gap-x-1 container grid-cols-none mx-auto py-0">


                    <div className='px-0 md:px-8 mt-2'>
                        <label htmlFor="my-modal" className="btn lg:mx-0 hover:underline md:min-w-[150px] h-[80px] md:h-fit bg-white text-center w-[140px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Necessary Equipment Required</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modal" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white ">
                                <label htmlFor="my-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        List of Necessary Equipment for Suprapubic Catheterization:</h3>


                                    <div> <SaveAsPDF /></div>
                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>


                                <ul class="space-y-4 text-gray-800 list-decimal list-outside px-4 md:px-8 text-vsm  md:text-base">

                                    <li className='font-bold'>Sterile Catheterization Kit:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li><span className='font-bold'>Sterile gloves:</span> To maintain a sterile environment and prevent contamination.</li>
                                        <li><span className='font-bold'>Sterile drape:</span> To create a sterile field around the insertion site.</li>
                                        <li><span className='font-bold'>Antiseptic solution:</span> To clean and disinfect the skin around the incision site.</li>
                                        <li><span className='font-bold'>Sterile dressing:</span> To cover and secure the catheter insertion site after the procedure.</li>
                                    </ol>

                                    <li className='font-bold'>Suprapubic Catheter:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li><span className='font-bold'>Suprapubic catheter:</span> A specialized catheter designed for suprapubic insertion, usually made of silicone or latex.</li>
                                        <li><span className='font-bold'>Catheter stylet (optional):</span> A removable stiffening wire that helps guide the catheter during insertion.</li>
                                        <li><span className='font-bold'>Catheter securement device:</span> A device used to secure the catheter in place and prevent accidental displacement.</li>
                                    </ol>
                                    <li className='font-bold'>Distilled Water:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>For inflation of baloon of the bladder</li>
                                    </ol>
                                    <li className='font-bold'>Local Anesthetic:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li><span className='font-bold'>Local anesthetic solution:</span> Typically lidocaine or a similar agent, used to numb the insertion site and minimize discomfort during the procedure.</li>
                                    </ol>

                                    <li className='font-bold'>Skin Marking:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li><span className='font-bold'>Surgical skin marker or sterile pen:</span> Used to mark the location for incision and insertion of the suprapubic catheter.</li>
                                    </ol>

                                    <li className='font-bold'>Incision and Suturing Equipment (if applicable):</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li><span className='font-bold'>Sterile scalpel or blade:</span> To make a small incision in the lower abdomen for catheter insertion.</li>
                                        <li><span className='font-bold'>Sterile sutures or adhesive strips:</span> Used to close and secure the incision site after catheter insertion.</li>
                                    </ol>

                                    <li className='font-bold'>Catheter Irrigation and Drainage Equipment (if necessary):</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li><span className='font-bold'>Sterile saline solution:</span> Used for irrigation or flushing of the catheter to maintain patency.</li>
                                        <li><span className='font-bold'>Sterile syringes:</span> To facilitate irrigation or drainage through the catheter.</li>
                                        <li><span className='font-bold'>Drainage bag or container:</span> Collects and measures urine output from the catheter.</li>
                                    </ol>
                                </ul>

                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-vsm md:text-base">It is crucial to note that the specific equipment required may vary depending on the healthcare setting, patient condition, and healthcare provider's preferences. The listed items serve as a general guideline for the equipment needed for suprapubic catheterization. Healthcare professionals should follow institutional protocols, manufacturer instructions, and adhere to aseptic techniques to ensure patient safety and minimize the risk of complications.</p>
                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-vsm md:text-base">Proper training and proficiency in suprapubic catheterization techniques are essential for healthcare professionals performing the procedure. Regular assessment and monitoring of the catheter and the patient's condition are necessary to ensure effective bladder drainage and minimize the potential for complications.</p>


                                <div className="modal-action">
                                    <label htmlFor="my-modal" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='px-0 hidden md:inline-flex md:px-8 mt-2'>
                        <FlashingText>
                            <label htmlFor="my-modalpuncture1" className="btn lg:mx-0 hover:underline w-fit md:min-w-[150px] h-fit md:h-fit bg-white text-center md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Emergency Bladder Puncture</label>
                        </FlashingText>
                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalpuncture1" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white ">
                                <label htmlFor="my-modalpuncture1" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold px-4 text-gray-800 text-base mb-6">
                                        Emergency Bladder Puncture with Needle and Aspiration of Urine</h3>



                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                <iframe
                                    className='w-fit h-fit md:w-[450px] md:h-[250px] flex place-content-center place-items-center mb-4'
                                    src="https://www.youtube.com/embed/Q0B5UAWIdYU"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen>

                                </iframe>
                                <ul class="space-y-4 text-gray-800 list-decimal list-outside px-4 md:px-8 text-vsm  md:text-base">
                                    <div classname='space-y-4 text-gray-800 px-4 md:px-8 text-vsm  md:text-base'>Bladder puncture is a procedure performed in specific situations where other methods of urinary catheterization, such as urethral or suprapubic catheterization, are not feasible or contraindicated. The National Institute for Health and Care Excellence (NICE) provides guidelines for healthcare professionals on the use of bladder puncture as an alternative method for bladder drainage. Here is a note on bladder puncture based on NICE guidelines:</div>

                                    <li className='font-bold'>Indications:</li>
                                    <ol class=" space-y-1  list-outside">
                                        <li>Bladder puncture may be indicated in emergency situations where urgent bladder drainage is necessary and conventional catheterization methods cannot be performed. It is typically considered when both urethral and suprapubic catheterization are not feasible or contraindicated. Some common indications for bladder puncture include acute urinary retention, bladder dysfunction, or the need for urgent urine sample collection.</li>
                                    </ol>

                                    <li className='font-bold'>Equipment:</li>
                                    <ol class=" space-y-1  list-outside">
                                        <li>The equipment required for bladder puncture includes sterile gloves, a sterile drape, an appropriate-sized needle (usually 16-18G), a syringe for aspirating urine, antiseptic solution for skin preparation, local anesthetic if necessary, a urine collection container, and adhesive dressings for wound closure after the procedure.</li>
                                    </ol>

                                    <li className='font-bold'>Procedure Steps:</li>
                                    <ol class="pl-5 space-y-1 list-decimal list-outside">
                                        <li>Prepare the patient: Explain the procedure to the patient, obtain informed consent, and position the patient in a supine position.</li>
                                        <li>Preparation and draping: Wear sterile gloves and prepare the puncture site using an antiseptic solution. Place a sterile drape to create a sterile field around the puncture site.</li>
                                        <li>Anesthesia (if necessary): Administer a local anesthetic</li>
                                        <li>Needle insertion: Insert a sterile needle into the bladder through the previously prepared puncture site. Advance the needle slowly while monitoring the patient's response and urine flow.</li>
                                        <li>Aspiration and urine collection: Attach a syringe to the needle and gently aspirate urine from the bladder. Collect the urine sample in a sterile container if needed for diagnostic purposes.</li>
                                        <li>Needle removal and wound closure: Once the bladder is adequately drained, carefully remove the needle. Apply pressure and use adhesive dressings to close the puncture site and prevent leakage.</li>
                                        <li>Post-procedure care: Provide appropriate post-procedure care, including monitoring for any complications, ensuring patient comfort, and arranging for follow-up care as necessary.</li>
                                    </ol>


                                </ul>

                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-vsm md:text-base">It is important to note that bladder puncture is an emergency procedure and should only be used when other catheterization methods are not feasible or contraindicated. Efforts should be made to transition to more definitive methods of bladder drainage as soon as possible, such as suprapubic or urethral catheterization, depending on the patient's clinical condition and available resources.</p>
                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-vsm md:text-base">NICE guidelines provide recommendations to guide healthcare professionals in emergency scenarios where bladder puncture is necessary. These guidelines aim to ensure patient safety, appropriate bladder drainage, and minimize the potential complications associated with the procedure.</p>


                                <div className="modal-action">
                                    <label htmlFor="my-modalpuncture1" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>


                    </div>








                    <div className='px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalvideo" className="btn lg:mx-0 md:min-w-[150px] h-[80px] md:h-fit hover:underline bg-white text-center w-[140px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Watch Video of Catheterization</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvideo" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvideo" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Supra-pubic Catheterization: Video</h3>



                                </div>

                                {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}
                                <h3 className="font-bold text-gray-800 text-base">
                                </h3>
                                <div class='max-w-fit flex justify-center items-center mb-4'>
                                    <iframe
                                        className='w-fit h-fit md:w-[560px] md:h-[360px] flex place-content-center place-items-center'
                                        src="https://www.youtube.com/embed/GvYoOyobpZA"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen>

                                    </iframe>
                                </div>

                                <div className="modal-action">
                                    <label htmlFor="my-modalvideo" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="flex place-items-center place-content-center gap-x-1 container grid-cols-none mx-auto py-0">
                    <div className='px-0 block md:hidden md:px-8 mt-2'>
                        <FlashingText>
                            <label htmlFor="my-modalpuncture2" className="btn lg:mx-0 hover:underline w-fit md:min-w-[150px] h-fit md:h-fit bg-white text-center md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Emergency Bladder Puncture</label>
                        </FlashingText>
                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalpuncture2" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white ">
                                <label htmlFor="my-modalpuncture2" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Emergency Bladder Puncture with Needle and Aspiration of Urine</h3>



                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>  <div> <SaveAsPDF /></div>FirstScrub.com</div>

                                <iframe
                                    className='w-fit h-fit md:w-[560px] md:h-[250px] flex place-content-center place-items-center mb-4'
                                    src="https://www.youtube.com/embed/Q0B5UAWIdYU"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen>

                                </iframe>
                                <ul class="space-y-4 text-gray-800 list-decimal list-outside px-4 md:px-8 text-vsm  md:text-base">
                                    <div classname='space-y-4 text-gray-800 px-4 md:px-8 text-vsm  md:text-base'>Bladder puncture is a procedure performed in specific situations where other methods of urinary catheterization, such as urethral or suprapubic catheterization, are not feasible or contraindicated. The National Institute for Health and Care Excellence (NICE) provides guidelines for healthcare professionals on the use of bladder puncture as an alternative method for bladder drainage. Here is a note on bladder puncture based on NICE guidelines:</div>

                                    <li className='font-bold'>Indications:</li>
                                    <ol class=" space-y-1  list-outside">
                                        <li>Bladder puncture may be indicated in emergency situations where urgent bladder drainage is necessary and conventional catheterization methods cannot be performed. It is typically considered when both urethral and suprapubic catheterization are not feasible or contraindicated. Some common indications for bladder puncture include acute urinary retention, bladder dysfunction, or the need for urgent urine sample collection.</li>
                                    </ol>

                                    <li className='font-bold'>Equipment:</li>
                                    <ol class=" space-y-1  list-outside">
                                        <li>The equipment required for bladder puncture includes sterile gloves, a sterile drape, an appropriate-sized needle (usually 16-18G), a syringe for aspirating urine, antiseptic solution for skin preparation, local anesthetic if necessary, a urine collection container, and adhesive dressings for wound closure after the procedure.</li>
                                    </ol>

                                    <li className='font-bold'>Procedure Steps:</li>
                                    <ol class="pl-5 space-y-1 list-decimal list-outside">
                                        <li>Prepare the patient: Explain the procedure to the patient, obtain informed consent, and position the patient in a supine position.</li>
                                        <li>Preparation and draping: Wear sterile gloves and prepare the puncture site using an antiseptic solution. Place a sterile drape to create a sterile field around the puncture site.</li>
                                        <li>Anesthesia (if necessary): Administer a local anesthetic</li>
                                        <li>Needle insertion: Insert a sterile needle into the bladder through the previously prepared puncture site. Advance the needle slowly while monitoring the patient's response and urine flow.</li>
                                        <li>Aspiration and urine collection: Attach a syringe to the needle and gently aspirate urine from the bladder. Collect the urine sample in a sterile container if needed for diagnostic purposes.</li>
                                        <li>Needle removal and wound closure: Once the bladder is adequately drained, carefully remove the needle. Apply pressure and use adhesive dressings to close the puncture site and prevent leakage.</li>
                                        <li>Post-procedure care: Provide appropriate post-procedure care, including monitoring for any complications, ensuring patient comfort, and arranging for follow-up care as necessary.</li>
                                    </ol>


                                </ul>

                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-vsm md:text-base">It is important to note that bladder puncture is an emergency procedure and should only be used when other catheterization methods are not feasible or contraindicated. Efforts should be made to transition to more definitive methods of bladder drainage as soon as possible, such as suprapubic or urethral catheterization, depending on the patient's clinical condition and available resources.</p>
                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-vsm md:text-base">NICE guidelines provide recommendations to guide healthcare professionals in emergency scenarios where bladder puncture is necessary. These guidelines aim to ensure patient safety, appropriate bladder drainage, and minimize the potential complications associated with the procedure.</p>


                                <div className="modal-action">
                                    <label htmlFor="my-modalpuncture2" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>



                <div className='w-100'>
                    <div className='grid grid-cols-1 place-items-start'>



                        <p class=" text-white dark:text-white px-4 indent-6 my-4 text-start text-sm md:text-lg">Suprapubic catheterization is a procedure used to drain urine from the bladder when a person is unable to empty their bladder naturally or when other methods of catheterization are not feasible. The National Institute for Health and Care Excellence (NICE) provides guidelines to ensure safe and effective suprapubic catheterization.</p>
                        <p class="mb-10 text-white dark:text-white px-4 my-1 text-start text-sm md:text-lg">Here is a note on suprapubic catheterization based on NICE guidelines:</p>


                        <p class=" text-white dark:text-white px-2 my-4 text-start text-sm md:text-lg">Suprapubic Catheterization - NICE Guidelines</p>
                        <p class=" text-white dark:text-white px-2 my-4 text-start text-sm md:text-lg">Suprapubic catheterization is a procedure used to drain urine from the bladder when a person is unable to empty their bladder naturally or when other methods of catheterization are not feasible. The National Institute for Health and Care Excellence (NICE) provides guidelines to ensure safe and effective suprapubic catheterization.</p>



                        <ul class="space-y-4 text-white list-disc list-outside pl-8 pr-4 md:px-16 text-sm md:text-lg">
                            <li className='font-bold'> Indications:</li>
                            <ol class=" text-white dark:text-white my-4 text-start text-sm md:text-lg">Suprapubic catheterization may be indicated in various situations, including but not limited to:</ol>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Urinary retention or inability to empty the bladder adequately.</li>
                                <li>Bladder dysfunction caused by neurological conditions, such as spinal cord injury or multiple sclerosis.</li>
                                <li>Urinary incontinence not responsive to other treatment options.</li>
                                <li>Urethral trauma or obstruction.</li>
                                <li>Severe urinary urgency or frequency.</li>
                            </ol>

                            <li className='font-bold'> Procedure:</li>
                            <ol class=" text-white dark:text-white my-4 text-start text-sm md:text-lg">The suprapubic catheterization procedure involves several key steps, which should be performed in accordance with NICE guidelines:</ol>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li><div className='font-bold'>Patient Assessment:</div> Before the procedure, a thorough assessment of the patient's medical history, bladder function, and any contraindications should be conducted.</li>
                                <li><div className='font-bold'>Consent:</div> Informed consent must be obtained from the patient or their legal representative, explaining the benefits, risks, and potential complications of the procedure.</li>
                                <li><div className='font-bold'>Sterile Technique:</div> The procedure should be performed using aseptic technique to minimize the risk of infection. Sterile gloves, drapes, and equipment should be used.</li>
                                <li><div className='font-bold'>Anesthesia:</div> Local anesthesia is administered to numb the area where the incision will be made.</li>
                                <li><div className='font-bold'>Incision and Catheter Insertion:</div> A small incision is made just above the pubic bone, and the bladder is accessed using either a trocar or a needle. The catheter is then inserted into the bladder through the incision and secured in place.</li>
                                <li><div className='font-bold'>Catheter Fixation:</div> The catheter is secured to the abdomen using an appropriate method, such as sutures, a catheter stabilization device, or adhesive dressings.</li>
                                <li><div className='font-bold'>Catheter Care:</div> Regular catheter care, including cleaning and monitoring for infection, should be implemented following the procedure.</li>
                                <li><div className='font-bold'>Patient Education:</div> The patient and/or caregiver should receive comprehensive education on self-care, hygiene, and potential complications associated with suprapubic catheterization.</li>
                            </ol>

                            <li className='font-bold'>Complications and Follow-up:</li>
                            <ol class=" text-white dark:text-white my-4 text-start text-sm md:text-lg">Suprapubic catheterization, like any medical procedure, carries some risks and potential complications. These may include infection, bleeding, bladder or bowel injury, catheter blockage, and catheter dislodgment. It is important to provide appropriate post-procedure instructions and arrange follow-up care to monitor the patient's well-being and address any concerns or complications that may arise.</ol>


                        </ul>
                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Note:</div>

                        <p class="mb-3 text-white dark:text-white px-4 my-4 text-start text-sm md:text-lg">NICE guidelines aim to ensure that healthcare professionals follow standardized protocols and best practices for suprapubic catheterization, promoting patient safety, effective management, and positive outcomes. Adherence to these guidelines helps to minimize the risks associated with the procedure and optimize the patient's quality of life.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div>




        </CalculatorWorking >

    );
};
