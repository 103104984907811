import React, { useState } from 'react';
import { CalculatorWorking } from '../../calculatorworking';
import SaveAsPDF from '../../../../features/button/save-as-pdf';

export const MaintainanceFluidCalculator = () => {
  const [weight, setWeight] = useState('');
  const [fluidRequirement, setFluidRequirement] = useState('');
  const [perHour, setPerHour] = useState('');

  const calculateFluidRequirement = () => {
    const parsedWeight = parseFloat(weight);
    if (isNaN(parsedWeight)) {
      setFluidRequirement(0);
      return;
    }

    let fluidRate = 0;
    if (parsedWeight <= 10) {
      fluidRate = parsedWeight * 4;
    } else if (parsedWeight <= 20) {
      fluidRate = 10 * 4 + (parsedWeight - 10) * 2;
    } else {
      fluidRate = 10 * 4 + 10 * 2 + (parsedWeight - 20) * 1;
    }

    const dailyRequirement = fluidRate * 24;
    setFluidRequirement(dailyRequirement);

    const hourlyRequirement = fluidRate;
    setPerHour(hourlyRequirement);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    calculateFluidRequirement();
  };

  return (
    <CalculatorWorking>

      <div className="container mx-auto px-4 py-6">

        <h1 className="text-3xl font-bold mb-4 md:mb-10">Maintainance Fluid Calculator</h1>
        <div className='w-90'>
          <p className="text-white text-start text-xs md:text-xl md-10 flex justify-items-center">
            The 4-2-1 Maintenance Fluid Calculator is a tool designed to estimate the daily fluid requirements for patients using the simplified 4-2-1 formula. This formula takes into account the patient's weight to determine the appropriate fluid rates.
          </p>
          <div className='px-0 flex place-content-center md:px-8 mt-2 mb-4 md:mb-6'>

            <label htmlFor="my-modal" className="btn lg:mx-0 hover:underline h-[40px] md:h-fit bg-white text-center w-[110px] md:w-fit  hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Formula</label>

            {/* Put this part before </body> tag */}
            <input type="checkbox" id="my-modal" className="modal-toggle" />
            <div className="modal">
              <div className="modal-box relative bg-white ">
                <label htmlFor="my-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                <div className='flex gap-x-20'>
                  <h3 className="font-bold text-gray-800 text-base mb-6">
                    Maintainance Fluid: Formula</h3>


                  <div> <SaveAsPDF /></div>
                </div>

                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                <ul class="space-y-4 text-gray-800 list-outside px-4 md:px-8 text-vsm  md:text-base">

                  <li className='font-bold'>Based on the 4-2-1 rule, the calculator calculates the fluid requirements as follows:</li>
                  <ol class="pl-5 space-y-1 list-disc list-outside">
                    <li>For the first 10 kilograms of body weight, it recommends providing 4 milliliters of fluid per kilogram per hour.</li>
                    <li>For the second 10 kilograms of body weight, an additional 2 milliliters of fluid per kilogram per hour are suggested.</li>
                    <li>For any weight above 20 kilograms, an extra 1 milliliter of fluid per kilogram per hour is recommended for each additional kilogram.</li>
                  </ol>

                  <li>By inputting the patient's weight, the calculator will estimate the daily maintenance fluid requirement according to this formula. It provides the total amount of fluid required per day and can also display the hourly fluid rate.</li>

                  <li>This calculator simplifies the process of determining the appropriate fluid intake for patients, allowing healthcare professionals to quickly assess and provide the necessary fluid support.</li>


                </ul>



                <div className="modal-action">
                  <label htmlFor="my-modal" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                </div>
              </div>
            </div>

          </div>
        </div>


        <div className='flex flex-col md:flex-row justify-center'>
          <form className='mb-10 items-center' onSubmit={submitHandler}>


            <div className='w-fit border rounded px-4 py-2 bg-gray-800'>
              <div className='grid grid-cols-1 mb-4 place-items-center'>
                <p class="px-4 text-sm font-bold  text-white text-start mb-2 dark:text-white">Enter weight below:</p>

                <input

                  value={weight}
                  placeholder="in Kilograms"
                  onChange={(e) => setWeight(e.target.value)}
                  class="bg-gray-50 mb-4 text-center border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:text-base dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="number"

                  required />

                <button id="navAction"
                  onClick={submitHandler}
                  className="w-fit grid grid-cols-1  mb-2 lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                  Calculate
                </button>
              </div>
            </div>





          </form>




          <div className='flex place-items-center place-content-center mb-24'>

            {fluidRequirement && (
              <div class="max-w-sm rounded overflow-hidden shadow-inner md:mx-16 bg-opacity-20 bg-white">

                <div>
                  <div class="px-6 py-4">

                    <div class="font-bold text-xl mb-2">Daily Maintainance:</div>
                    <div className='flex justify-center items-center'>
                      <div class="text-white text-2xl">
                        {fluidRequirement}</div><span className='text-gray-100 text-xl'>ml/day</span>
                    </div>
                  </div>

                  <div>
                    <div class="px-6 pt-4 pb-2 grid grid-cols-1 place-items-center">
                      <div class="inline-block bg-gray-200 w-fit rounded-full px-3 py-1 text-sm text-center font-semibold text-gray-700 mr-2 mb-2">Hourly Maintainance:</div>
                      <div class="inline-block  px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">{perHour} ml/hour</div>


                    </div>

                  </div>
                </div>
              </div>
            )}


          </div>
        </div>




        <div className='my-10 text-center text-xl'>developed by alostmedic.com</div>
      </div>

    </CalculatorWorking >

  );

}
