import React from 'react';
import SaveAsPDF from '../../../../features/button/save-as-pdf';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';

export const MedicineProgress = () => {


    return (

        <CalculatorWorking>


            <div className="container mb-20  mx-auto px-4 py-0">
                {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
                <h1 class="flex place-content-center text-2xl text-center md:text-4xl font-extrabold dark:text-white">Comprehensive Guide to Daily Patient Progress Monitoring</h1>
                <div className='flex place-items-center place-content-center md:place-items-start px-0 md:px-8  mt-2'>
                    <label htmlFor="my-modal" className="btn lg:mx-0 hover:underline bg-white text-center w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Progress Template</label>

                    {/* Put this part before </body> tag */}
                    <input type="checkbox" id="my-modal" className="modal-toggle" />
                    <div className="modal">
                        <div className="modal-box relative bg-white ">
                            <label htmlFor="my-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                            <div className='flex gap-x-20'>
                                <h3 className="font-bold text-gray-800 text-2xl mb-6">Daily Progress Template:</h3>


                                <div> <SaveAsPDF /></div>
                            </div>

                            <div class='font-bold text-center text-gray-400 text-xl mb-6' style={{ userSelect: "none" }}>FirstScrub.com</div>

                            <div className='mb-2 grid grid-cols-3'>
                                <div style={{ userSelect: "none" }} className="text-xs text-gray-800 md:text-base text-start pl-2">Date:</div>
                                <div style={{ userSelect: "none" }} className="text-xs text-gray-800 md:text-base text-start pl-2">Shift:<span class='font-bold'>M/E/N</span></div>
                                <div style={{ userSelect: "none" }} className="text-xs text-gray-800 md:text-base text-start pl-2">Time:</div>
                            </div>
                            <div style={{ userSelect: "none" }} className="text-xs mb-2 md:text-base text-gray-800 font-bold text-start pl-2">Patient Name:</div>
                            <div className='mb-2 grid grid-cols-2'>
                                <div style={{ userSelect: "none" }} className="text-xs md:text-base text-gray-800 font-bold text-start pl-2">Diagnosis: &#9651;</div>
                                <div style={{ userSelect: "none" }} className="text-xs md:text-base text-gray-800 font-bold text-start pl-2"> Comorbids/Allergies:</div>
                            </div>
                            <div>
                                <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Subjective:</div>

                                <div className='px-2 mb-4'>
                                    <ul style={{ userSelect: "none" }} class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                        <li>
                                            Chief Complaint:

                                        </li>
                                        <li>
                                            Symptoms or concerns reported by the patient:

                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div>
                                <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Objective:</div>

                                <div className='px-2 mb-4'>
                                    <ul style={{ userSelect: "none" }} class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                        <li>
                                            Vital Signs:
                                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                                <li>Blood Pressure: _____ mmHg</li>
                                                <li>Heart Rate: _____ bpm</li>
                                                <li>Oxygen Saturation: ____ %</li>
                                                <li>Respiratory Rate: ____ /min</li>
                                                <li>Temperature: ____ &deg;C/F</li>
                                            </ol>
                                        </li>
                                        <li>Physical Examination Findings:
                                            <ul class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                                <li>General Appearance:</li>
                                                <li>Cardiovascular System:</li>
                                                <li>Respiratory System:</li>
                                                <li>Gastrointestinal System:</li>
                                                <li>Neurological System:</li>
                                                <li>Other Relevant Systems:</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Laboratory and Diagnostic Results:
                                            <ul class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                                <li>Relevant test results:</li>
                                                <li>Imaging findings (if applicable):</li>
                                                <li>Other diagnostic findings (if applicable):</li>
                                            </ul>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div>
                                <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Course of Illness:</div>

                                <div style={{ userSelect: "none" }} className='px-2 mb-4'>
                                    <ul class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                        <li>
                                            Timeline of treatment and interventions:

                                        </li>
                                        <li>
                                            Response to treatment and changes observed:

                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div>
                                <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Assessment:</div>

                                <div className='px-2 mb-4'>
                                    <ul style={{ userSelect: "none" }} class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                        <li>
                                            Analysis of subjective and objective data:

                                        </li>
                                        <li>
                                            Diagnosis evaluation:

                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div>
                                <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Plan:</div>

                                <div className='px-2 mb-4'>
                                    <ul style={{ userSelect: "none" }} class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                        <li>
                                            Treatment adjustments or modifications:

                                        </li>
                                        <li>
                                            Patient education:

                                        </li>

                                        <li>
                                            Follow-up monitoring and next steps:

                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Additional Notes:</div>

                                <div className='px-2 mb-4'>
                                    <ul style={{ userSelect: "none" }} class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                        <li>
                                            ________________________________
                                        </li>
                                    </ul>
                                </div>
                            </div>


                            <div className="modal-action">
                                <label htmlFor="my-modal" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                            </div>
                        </div>
                    </div>

                </div>


                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>



                        <p class="mb-10 text-white dark:text-white px-4 indent-6 my-4 text-start text-sm md:text-lg">Monitoring the daily progress of patients is a vital aspect of healthcare that helps healthcare professionals make informed decisions, track treatment effectiveness, and ensure positive patient outcomes. In this blog, we will explore the key components of daily patient progress monitoring, including subjective information, objective data, course of illness, assessment, and plan. By understanding these elements, healthcare professionals can effectively assess and manage patient care on a daily basis.</p>

                        <ul class="space-y-4 text-white list-disc list-outside px-4 md:px-16 text-sm md:text-lg">

                            <li className='font-bold'> Subjective:</li>
                            <div> The subjective component of daily patient progress monitoring involves gathering information from the patient about their symptoms, concerns, and overall well-being. This includes:</div>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Symptom Assessment: Ask the patient about any changes in symptoms since the last assessment. Inquire about pain levels, discomfort, sleep quality, appetite, and any new or worsening symptoms they may be experiencing.</li>
                                <li>Patient Concerns: Allow the patient to express any concerns or questions they may have regarding their condition, treatment, or recovery process. Actively listen and address their concerns to ensure a patient-centered approach to care.</li>
                            </ol>

                            <li className='font-bold'> Objective:</li>
                            <div>The objective component of daily patient progress monitoring involves obtaining measurable and observable data. This includes:</div>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Vital Signs: Regularly measure and record vital signs such as temperature, blood pressure, heart rate, respiratory rate, and oxygen saturation. Compare these measurements to previous values to identify any significant changes.</li>
                                <li>Physical Examinations: Perform focused physical examinations based on the patient's condition and needs. Assess relevant body systems, observe wound healing progress, and document any notable findings.</li>
                                <li>Laboratory and Diagnostic Results: Review and interpret the patient's laboratory tests, imaging studies, and other diagnostic results. Evaluate trends and compare values to baseline or previous results to identify any significant changes.</li>
                            </ol>

                            <li className='font-bold'> Course of Illness:</li>
                            <div>The course of illness refers to the progression and development of the patient's condition. This includes:</div>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Treatment Timeline: Document the timeline of the patient's treatment, including medication administration, therapies, procedures, and interventions. Track adherence to the treatment plan and note any deviations or modifications.</li>
                                <li>Response to Treatment: Evaluate how the patient is responding to the prescribed treatment. Assess improvements or worsening of symptoms, changes in functional status, and overall progress toward recovery goals.</li>
                            </ol>

                            <li className='font-bold'> Assessment:</li>
                            <div>The assessment component involves synthesizing subjective and objective data to form an overall evaluation of the patient's current status. This includes:</div>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Data Analysis: Analyze the collected data, including subjective information, objective measurements, and the course of illness. Identify trends, patterns, and any deviations from the expected progress.</li>
                                <li>Diagnosis Evaluation: Assess the patient's diagnosis and consider if any adjustments or modifications are necessary based on new information or changes in the patient's condition.</li>
                            </ol>

                            <li className='font-bold'> Plan:</li>
                            <div>The plan component focuses on outlining the actions and interventions to be implemented based on the assessment. This includes:</div>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Treatment Adjustments: Determine if any modifications are required in the patient's treatment plan. This may involve changes in medication dosages, therapies, or interventions based on the patient's response and current needs.</li>
                                <li>Patient Education: Provide the patient with clear and concise information about their condition, treatment plan, and self-care instructions. Ensure they understand their role in their recovery and can actively participate in managing their health.</li>
                                <li>Follow-Up Monitoring: Schedule follow-up assessments and tests to track the patient's progress and evaluate the effectiveness of the implemented plan. Set realistic goals and benchmarks to guide the ongoing management of the patient's condition.</li>


                            </ol>



                        </ul>
                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Conclusion:</div>

                        <p class="mb-3 text-white dark:text-white px-4 indent-3 my-4 text-start text-sm md:text-lg">Daily patient progress monitoring is a critical aspect of healthcare that enables healthcare professionals to assess the patient's condition, track treatment effectiveness, and make informed decisions. By incorporating subjective and objective data, evaluating the course of illness, conducting thorough assessments, and developing appropriate plans, healthcare professionals can ensure comprehensive and individualized patient care on a daily basis.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div>


        </CalculatorWorking >

    );
};
