import React from 'react';
import SaveAsPDF from '../../../../../features/button/save-as-pdf';
import DefaultBottomDialogue from '../../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../../calculatorworking';

export const FoleysCatheter = () => {


    return (

        <CalculatorWorking>



            {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
            <h1 class="place-content-center text-2xl text-center md:text-4xl mb-15 font-extrabold dark:text-white">Foley's Catheterization</h1>

            <div className="container mb-20 mx-auto px-4 py-0">
                <div className="flex place-items-center place-content-center gap-x-1 container grid-cols-none mx-auto px-4 py-0">


                    <div className='px-0 md:px-8 mt-2'>
                        <label htmlFor="my-modal" className="btn lg:mx-0 hover:underline h-[80px] md:h-fit bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Necessary Equipment for Foleys</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modal" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white ">
                                <label htmlFor="my-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        List of Necessary Equipment for Foley's Catheterization:</h3>


                                    <div> <SaveAsPDF /></div>
                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>


                                <p class=" text-white dark:text-white px-4 indent-6 my-4 text-start text-sm md:text-lg">Foley catheterization is a common procedure used to insert a Foley catheter into the urinary bladder to facilitate drainage and monitoring of urine output. The National Institute for Health and Care Excellence (NICE) provides guidelines on the recommended steps for performing Foley catheterization.</p>

                                <ul class="space-y-4 text-gray-800 list-decimal list-outside px-4 md:px-8 text-vsm  md:text-base">

                                    <li className='font-bold'>Sterile Gloves:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Disposable sterile gloves to ensure hand hygiene and prevent contamination.</li>
                                    </ol>

                                    <li className='font-bold'>Foley Catheter:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A sterile Foley catheter of appropriate size and type. Foley catheters have an inflatable balloon near the tip that is used to secure the catheter inside the bladder.</li>
                                    </ol>

                                    <li className='font-bold'>Sterile Lubricating Gel:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A sterile lubricating gel to ease the insertion of the catheter and minimize patient discomfort. Water-soluble lubricants are recommended.</li>
                                    </ol>

                                    <li className='font-bold'>Antiseptic Solution:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>An antiseptic solution, such as chlorhexidine or povidone-iodine, for cleansing the genital area and maintaining aseptic conditions during the procedure.</li>
                                    </ol>

                                    <li className='font-bold'>Sterile Drapes:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile drapes to create a clean field and maintain aseptic conditions during the catheterization process.</li>
                                    </ol>

                                    <li className='font-bold'>Sterile Syringe:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A sterile syringe of appropriate size for inflating the balloon of the Foley catheter. The syringe should be of a volume recommended by the manufacturer.</li>
                                    </ol>

                                    <li className='font-bold'>Sterile Distilled Water:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile water for inflating the balloon of the Foley catheter. The amount of water required for balloon inflation should be as per the manufacturer's guidelines.</li>
                                    </ol>

                                    <li className='font-bold'>Adhesive or Tape:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Adhesive strips or tape to secure the catheter in place after insertion. It should be firm enough to prevent accidental dislodgement but not excessively tight.</li>
                                    </ol>

                                    <li className='font-bold'>Urine Drainage Bag:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A sterile urine drainage bag with appropriate capacity to collect and measure urine output. The bag may be connected to a leg bag or a larger collection bag, depending on the patient's needs.</li>
                                    </ol>

                                    <li className='font-bold'>Sterile Gauze or Cotton Balls:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile gauze or cotton balls for cleaning and drying the genital area, as well as for applying pressure to the puncture site after catheter insertion to stop any bleeding.</li>
                                    </ol>

                                    <li className='font-bold'>Waste Disposal Bags:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Plastic bags for the proper disposal of used gloves, swabs, and other non-sharp waste generated during the catheterization procedure.</li>
                                    </ol>

                                </ul>

                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-vsm md:text-base">It is essential to follow local guidelines, protocols, and infection control practices when performing Foley catheterization. Proper selection and preparation of equipment contribute to maintaining patient safety, preventing infections, and ensuring effective urinary drainage.</p>


                                <div className="modal-action">
                                    <label htmlFor="my-modal" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalvials" className="btn lg:mx-0 h-[80px] md:h-fit shover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Foleys Size Color Guide</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvials" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvials" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Foley's Catheter Size Color Guide:</h3>


                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                <div className="overflow-x-auto">
                                    <table className="rounded-lg bg-gray-800 table-pin-cols">
                                        {/* head */}
                                        <thead>
                                            <tr>
                                                <th className='w-[25px]'>Color</th>
                                                <th className='w-[25px]'>Width</th>
                                                <th className='w-[25px] max-w-[25px]'></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* row 1 */}
                                            <tr>
                                                <th className='bg-[#9bce31] text-vsm md:text-xs font-bold text-white text-center'>Light Green</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>6 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>2.0 mm</td>

                                            </tr>

                                            {/* row 2 */}
                                            <tr>
                                                <th className='bg-[#6495ec] text-vsm md:text-xs font-bold text-white text-center'>Light Blue</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center mb-2'>8 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'> 2.7 mm</td>
                                            </tr>


                                            {/* row 3 */}
                                            <tr>
                                                <th className='bg-[#000000] text-vsm md:text-xs font-bold text-white text-center'>Black</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center mb-2'>10 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'> 3.3 mm</td>
                                            </tr>


                                            {/* row 4 */}
                                            <tr>
                                                <th className='bg-[#ffffff] text-vsm md:text-xs font-bold text-black text-center'>White</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>12 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>4.0 mm</td>

                                            </tr>


                                            {/* row 5 */}
                                            <tr>
                                                <th className='bg-[#008001] text-vsm md:text-xs font-bold text-white text-center'>Dark Green</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>14 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>4.7 mm</td>
                                            </tr>


                                            {/* row 6 */}

                                            <tr>
                                                <th className='bg-[#ff8617] text-vsm md:text-xs font-bold text-white text-center'>Orange</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>16 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>5.3 mm<div className='text-vsm md:text-xs text-white text-start'> Most commonly used in adults</div></td>

                                            </tr>

                                            {/* row 7 */}

                                            <tr>
                                                <th className='bg-[#ff0000] text-vsm md:text-xs font-bold text-white text-center'>Red</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>18 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>6.0 mm</td>
                                            </tr>

                                            {/* row 8 */}

                                            <tr>
                                                <th className='bg-[#ffff00] text-vsm md:text-xs font-bold text-gray-800 text-center'>Yellow</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>20 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>6.7 mm</td>
                                            </tr>


                                            {/* row 8 */}


                                            <tr>
                                                <th className='bg-[#b955d3] text-vsm md:text-xs font-bold text-white text-center'>Purple</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>22 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>7.3 mm</td>
                                            </tr>

                                            {/* row 9 */}

                                            <tr>
                                                <th className='bg-[#0000ff] text-vsm md:text-xs font-bold text-white text-center'>Dark Blue</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>24 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>8.0 mm</td>
                                            </tr>
                                            {/* row 10 */}
                                            <tr>
                                                <th className='bg-[#ff11ad] text-vsm md:text-xs font-bold text-white text-center'>Pink</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>26 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>8.7 mm</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>


                                {/* <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-base md:text-xl">For more details, <a href='https://en.wikipedia.org/wiki/Vacutainer' target="_blank" rel="noreferrer" className='hover:text-white dark:text-blue-600 text-start text-base md:text-xl hover:bg-blue-400'>click here</a></p> */}







                                <div className="modal-action">
                                    <label htmlFor="my-modalvials" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalvideo" className="btn lg:mx-0 h-[80px] md:h-fit shover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Watch Video of Catheter Insertion</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvideo" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvideo" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Foley's Catheter Insertion: Video</h3>



                                </div>

                                {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}
                                <h3 className="font-bold text-gray-800 text-base">
                                    Male Patient:</h3>
                                <div class='max-w-fit flex justify-center items-center mb-4'>
                                    <iframe
                                        className='w-fit h-fit md:w-[560px] md:h-[250px] flex place-content-center place-items-center'
                                        src="https://www.youtube.com/embed/7-qnhRAa9c4"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen>

                                    </iframe>
                                </div>
                                <h3 className="font-bold text-gray-800 text-base">
                                    Female Patient:</h3>
                                <div class='max-w-fit flex justify-center items-center'>
                                    <iframe
                                        className='w-fit h-fit md:w-[560px] md:h-[250px] flex place-content-center place-items-center'
                                        src="https://www.youtube.com/embed/V6kRalGPZB0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen>

                                    </iframe>
                                </div>


                                <div className="modal-action">
                                    <label htmlFor="my-modalvideo" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>



                        <p class=" text-white dark:text-white px-4 indent-6 my-4 text-start text-sm md:text-lg">Foley catheterization is a common procedure used to insert a Foley catheter into the urinary bladder to facilitate drainage and monitoring of urine output. The National Institute for Health and Care Excellence (NICE) provides guidelines on the recommended steps for performing Foley catheterization.</p>
                        <p class="mb-10 text-white dark:text-white px-4 my-1 text-start text-sm md:text-lg">Here's a brief note on Foley catheterization as per NICE guidelines:</p>

                        <ul class="space-y-4 text-white list-decimal list-outside pl-8 pr-4 md:px-16 text-sm md:text-lg">

                            <li className='font-bold'> Preparation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Gather all the necessary equipment, including sterile gloves, a sterile Foley catheter (of appropriate size), sterile lubricating gel, antiseptic solution, sterile drapes, and a urine drainage bag.</li>
                                <li>Verify the patient's identification and explain the procedure to them, addressing any concerns or questions.</li>
                            </ol>

                            <li className='font-bold'> Hand Hygiene:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Perform hand hygiene by using soap and water or an alcohol-based hand rub before putting on gloves.</li>
                            </ol>

                            <li className='font-bold'> Patient Positioning:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Ensure the patient is lying comfortably in a supine position with their legs slightly apart and knees flexed.</li>
                                <li>If needed, provide appropriate privacy and use drapes to maintain a sterile field.</li>
                            </ol>

                            <li className='font-bold'>Sterile Technique:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Use aseptic technique throughout the procedure to minimize the risk of infection.</li>
                                <li>Prepare a sterile field using sterile drapes and maintain sterility during the catheterization process.</li>

                            </ol>

                            <li className='font-bold'>Catheter Lubrication:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Lubricate the catheter tip with a sterile lubricating gel to facilitate smooth insertion and minimize patient discomfort.</li>
                            </ol>

                            <li className='font-bold'>Catheter Insertion:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Cleanse the genital area with an appropriate antiseptic solution, following the manufacturer's instructions and NICE guidelines.</li>
                                <li>Gently insert the lubricated Foley catheter into the urethra, advancing it until urine flows.</li>
                                <li>Ensure the catheter is not excessively tensioned or pulling on the urethra.</li>

                            </ol>

                            <li className='font-bold'>Securement and Drainage:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Secure the catheter in place using adhesive or tape, ensuring it is not excessively tensioned.</li>
                                <li>Connect the catheter to a sterile urine drainage bag to collect the urine.</li>
                            </ol>

                            <li className='font-bold'>Post-Catheterization Care:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Ensure proper positioning and comfort of the patient.</li>
                                <li>Monitor urine output and assess for any signs of complications or adverse reactions.</li>
                                <li>Provide appropriate education to the patient and caregivers regarding catheter care, hygiene, and potential complications to watch for.</li>
                            </ol>

                            <li className='font-bold'>Documentation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Document the details of the procedure, including the catheter size, balloon inflation volume, patient's response, urine output, and any complications encountered.</li>
                            </ol>



                        </ul>
                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Note:</div>

                        <p class="mb-3 text-white dark:text-white px-4 my-4 text-start text-sm md:text-lg">Following the NICE guidelines for Foley catheterization helps healthcare professionals perform the procedure safely and effectively. It promotes the use of sterile techniques, minimizes the risk of infection, and ensures patient comfort and care. Adhering to proper hand hygiene, catheter insertion techniques, and regular monitoring can help prevent complications and ensure the appropriate management of urinary drainage.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div>


        </CalculatorWorking >

    );
};
