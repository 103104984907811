import React from 'react';
import SaveAsPDF from '../../../../../features/button/save-as-pdf';
import DefaultBottomDialogue from '../../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../../calculatorworking';
import { FlashingText } from '../../../../../features';

export const FemoralLine = () => {


    return (

        <CalculatorWorking>



            {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
            <h1 class="place-content-center text-2xl text-center md:text-4xl mb-15 font-extrabold dark:text-white">Femoral Vein Cannulation</h1>

            <div className="container mb-20 mx-auto px-4 py-0">



                <div className="flex place-items-center place-content-center gap-x-4 md:gap-x-1 container grid-cols-none mx-auto px-4 py-0">


                    <div className='px-0 md:px-8 mt-2'>
                        <FlashingText>
                            <label htmlFor="my-modal" className="btn lg:mx-0 hover:underline h-[80px] md:h-fit bg-white text-center w-[140px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-1 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Equipment for Central  Cannulation</label>
                        </FlashingText>
                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modal" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white ">
                                <label htmlFor="my-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        List of Necessary Equipment for Central Venous Cannulation:</h3>


                                    <div> <SaveAsPDF /></div>
                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                <ul class="space-y-4 text-gray-800 list-decimal list-outside px-4 md:px-8 text-vsm md:text-base">
                                    <ol class="pl-5 space-y-1 list-decimal list-outside">
                                        <li><div class="font-bold">Sterile gloves:</div> To maintain a sterile environment and prevent contamination.</li>
                                        <li><div class="font-bold">Drapes:</div> Sterile sheets used to create a sterile field around the cannulation site.</li>
                                        <li><div class="font-bold">Antiseptic solution:</div> Typically chlorhexidine or povidone-iodine used to cleanse and disinfect the skin.</li>
                                        <li><div class="font-bold">Central venous catheter kit:</div> Contains the necessary components for cannulation listed below:</li>
                                        <ol class="pl-5 space-y-1 list-disc list-outside">
                                            <li><span class="font-bold">Central venous catheter:</span> A catheter specifically designed for central venous access, available in various sizes and types.</li>
                                            <li><span class="font-bold">Guidewire:</span> A flexible wire used to facilitate the insertion and placement of the central venous catheter.</li>
                                            <li><span class="font-bold">Dilator:</span> A device used to dilate the tissues and create a pathway for the central venous catheter.</li>
                                            <li><span class="font-bold">Suture materials:</span> Sterile threads or sutures used to secure the central venous catheter to the skin.</li>
                                            <li><span class="font-bold">Adhesive dressings:</span> Self-adhesive sterile dressings used to cover and protect the catheter insertion site.</li>
                                            <li><span class="font-bold">Sterile drapes:</span> Large sterile sheets used to create a sterile field around the cannulation site.</li>
                                            <li><span class="font-bold">Scalpel or blade (if applicable):</span> A sterile cutting instrument used for making a small incision if necessary.</li>
                                            <li><span class="font-bold">Syringes and needles:</span> Used for aspiration, flushing, and administration of medications or fluids.</li>
                                            <li><span class="font-bold">Suture needle and thread (if applicable):</span> Used for suturing the catheter in place.</li>
                                            <li><span class="font-bold">Tegaderm or transparent dressing:</span> A transparent, adhesive dressing used to secure the catheter and provide visibility of the insertion site.</li>
                                            <li><span class="font-bold">Gauze pads:</span> Sterile pads used for cleaning and covering the cannulation site.</li>
                                            <li><span class="font-bold">Sterile saline solution:</span> Used for flushing and maintaining patency of the central venous catheter.</li>
                                        </ol>

                                        <li><div class="font-bold">Syringe:</div> Used for aspiration and injection of medications or fluids.</li>
                                        <li><div class="font-bold">Local anesthesia:</div> Typically lidocaine or a similar agent used to numb the insertion site.</li>
                                        <li><div class="font-bold">Ultrasound machine (if available):</div> Used for real-time visualization and guidance during cannulation.</li>
                                    </ol>
                                </ul>


                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-vsm md:text-base">It is essential to follow local guidelines, protocols, and infection control practices when performing Central Venous Cannulation. Proper selection and preparation of equipment contribute to maintaining patient safety & preventing infections and complications.</p>


                                <div className="modal-action">
                                    <label htmlFor="my-modal" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>



                    <div className='px-0 md:px-8  mt-2'>
                        <FlashingText>
                            <label htmlFor="my-modalvideo" className="btn lg:mx-0 h-[80px] md:h-fit shover:underline bg-white text-center w-[140px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-1 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Watch Video of Cannulation</label>
                        </FlashingText>
                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvideo" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvideo" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Femoral Vein Cannulation: Video</h3>



                                </div>

                                {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}
                                <h3 className="font-bold text-gray-800 text-base">
                                    Blind Femoral Vein Cannulation:</h3>
                                <div class='max-w-fit flex justify-center items-center mb-4'>
                                    <iframe
                                        className='w-fit h-fit md:w-[560px] md:h-[250px] flex place-content-center place-items-center'
                                        src="https://www.youtube.com/embed/xKT7P-SvTug"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen>

                                    </iframe>
                                </div>



                                <div className="modal-action">
                                    <label htmlFor="my-modalvideo" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>




                <div className='w-100'>
                    <div className='grid grid-cols-1 place-items-start'>

                        <div className='text-xs mt-8 md:text-lg text-white font-bold'>Femoral Vein Cannulation Procedure:</div>


                        <ul class="space-y-4 text-white mt-8 list-outside px-4 md:px-8 text-xs  md:text-lg">

                            <ol class=" space-y-1 list-decimal list-outside">
                                <li><span className='font-bold'>Preparation:</span></li>
                                <ul class="pl-5 space-y-1 list-disc list-outside">
                                    <li>Gather all necessary equipment, including sterile gloves, drapes, antiseptic solution, central venous catheter kit, syringe, local anesthesia, and ultrasound machine (if available).</li>
                                    <li>Position the patient in a supine position with the leg externally rotated and abducted to expose the groin area.</li>
                                    <li>Ensure appropriate lighting and adequate exposure of the femoral region.</li>
                                </ul>
                                <li><span className='font-bold'>Sterile field setup:</span></li>
                                <ul class="pl-5 space-y-1 list-disc list-outside">
                                    <li>Put on sterile gloves and prepare the sterile field using sterile drapes.</li>
                                    <li>Cleanse the skin over the intended cannulation site using an antiseptic solution, typically chlorhexidine or povidone-iodine.</li>
                                    <li>Place sterile towels or adhesive drapes around the site to maintain the sterile field.</li>
                                </ul>
                                <li><span className='font-bold'>Anesthesia:</span></li>
                                <ul class="pl-5 space-y-1 list-disc list-outside">
                                    <li>Administer local anesthesia to the skin and underlying tissues at the intended insertion site using a small needle and anesthetic agent, such as lidocaine.</li>
                                </ul>
                                <li><span className='font-bold'>Needle insertion:</span></li>
                                <ul class="pl-5 space-y-1 list-disc list-outside">
                                    <li>Identify the target vein using palpation, landmark-based techniques, or ultrasound guidance.</li>
                                    <li>Hold the needle with a syringe attached at a 45-degree angle to the skin, aiming towards the femoral artery.</li>
                                    <li>Advance the needle slowly while applying gentle negative pressure on the syringe.</li>
                                    <li>Aspirate blood to confirm entry into the vein.</li>
                                </ul>
                                <li><span className='font-bold'>Guidewire insertion:</span></li>
                                <ul class="pl-5 space-y-1 list-disc list-outside">
                                    <li>Once the needle is in the vein, remove the syringe and insert a guidewire through the needle.</li>
                                    <li>Advance the guidewire smoothly into the vein while ensuring it does not kink or retract.</li>
                                </ul>
                                <li><span className='font-bold'>Dilate and catheter insertion:</span></li>
                                <ul class="pl-5 space-y-1 list-disc list-outside">
                                    <li>Make a small incision at the insertion site, if necessary, to allow passage of the dilator and catheter.</li>
                                    <li>Insert the dilator over the guidewire and advance it into the vein.</li>
                                    <li>Remove the dilator, leaving the guidewire in place.</li>
                                    <li>Thread the central venous catheter over the guidewire into the vein until the desired length is reached.</li>
                                    <li>Secure the catheter to the skin using sutures or adhesive dressings.</li>
                                </ul>
                                <li><span className='font-bold'>Confirmation and securing:</span></li>
                                <ul class="pl-5 space-y-1 list-disc list-outside">
                                    <li>Confirm proper catheter placement by visualizing blood return and checking for appropriate waveform or pressure measurement (if available).</li>
                                    <li>Suture or secure the catheter in place to prevent accidental dislodgment.</li>
                                    <li>Attach the catheter to a sterile dressing and secure it to the patient's skin.</li>
                                </ul>
                                <li><span className='font-bold'>Post-procedure care:</span></li>
                                <ul class="pl-5 space-y-1 list-disc list-outside">
                                    <li>Monitor the patient for any signs of complications, such as bleeding, infection, or hematoma formation.</li>
                                    <li>Implement measures to prevent catheter-related bloodstream infections, such as proper catheter care and maintaining aseptic technique.</li>
                                </ul>
                            </ol>
                        </ul>



                    </div>
                </div>








                <DefaultBottomDialogue />
            </div>




        </CalculatorWorking >

    );
};
