import React from 'react';
import SaveAsPDF from '../../../../../features/button/save-as-pdf';
import DefaultBottomDialogue from '../../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../../calculatorworking';

export const IntermittentCatheter = () => {


    return (

        <CalculatorWorking>



            {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
            <h1 class="place-content-center text-2xl text-center md:text-4xl mb-15 font-extrabold dark:text-white">Intermittent Catheterization<div className='text-base'>(Clean Intermittent Self-Catheterization)</div></h1>

            <div className="container mb-20 mx-auto px-4 py-0">
                <div className="flex place-items-center place-content-center gap-x-1 container grid-cols-none mx-auto px-4 py-0">


                    <div className='px-0 md:px-8 mt-2'>
                        <label htmlFor="my-modal" className="btn lg:mx-0 hover:underline h-[80px] md:h-fit bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Necessary Equipment for CISC</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modal" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white ">
                                <label htmlFor="my-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        List of Necessary Equipment for Intermittent Catheterization:</h3>


                                    <div> <SaveAsPDF /></div>
                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                <ul class="space-y-4 text-gray-800 list-decimal list-outside px-4 md:px-8 text-vsm  md:text-base">

                                    <li className='font-bold'>Disposable Gloves:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Wearing disposable gloves helps maintain proper hygiene and minimize the risk of infection. Gloves should be worn during the preparation and insertion of the catheter.</li>
                                    </ol>

                                    <li className='font-bold'>Catheters:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Catheters are flexible tubes used to drain urine from the bladder. They come in various sizes and types, including straight catheters or hydrophilic catheters. The specific type and size of catheter required will depend on individual needs and preferences.</li>
                                    </ol>

                                    <li className='font-bold'>Lubricant:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A water-based lubricant is essential for easy insertion of the catheter. It helps reduce discomfort and potential trauma to the urethra during the catheterization process.</li>
                                    </ol>

                                    <li className='font-bold'>Clean Water or Sterile Saline Solution:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li> Before starting CISC, it is crucial to have access to clean water or a sterile saline solution for cleaning the hands, genital area, and catheter.</li>
                                    </ol>

                                    <li className='font-bold'>Antiseptic Wipes or Solution:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Antiseptic wipes or a solution, such as chlorhexidine or povidone-iodine, are used to clean the urethral meatus (opening) before catheterization. This helps reduce the risk of introducing bacteria into the urinary tract.</li>
                                    </ol>

                                    <li className='font-bold'>Disposable Towels or Paper Towels:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Disposable towels or paper towels are useful for drying the genital area after cleaning and for maintaining cleanliness during the catheterization process.</li>
                                    </ol>

                                    <li className='font-bold'>Disposal Bag:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A disposable bag or container for disposing of used catheters and other medical waste is necessary to ensure proper disposal and prevent the spread of infection.</li>
                                    </ol>

                                    <li className='font-bold'>Personal Hygiene Products:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>It is recommended to have personal hygiene products, such as mild soap or cleansing wipes, for general cleanliness before and after the procedure.</li>
                                    </ol>

                                    <li className='font-bold'>Mirror (optional):</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A small handheld mirror can be helpful for individuals who require visual assistance during catheterization, particularly for locating the urethral opening.</li>
                                    </ol>

                                    <li className='font-bold'>Container for Urine Collection (optional):</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A sterile container or urine collection bag is necessary to collect the urine that is drained during catheterization. It should be clean and preferably sterile to avoid contamination.</li>
                                    </ol>

                                </ul>

                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-vsm md:text-base">It is important to consult with a healthcare professional for specific recommendations on equipment, as individual needs and preferences may vary. They can provide guidance on the appropriate type and size of catheter, as well as any additional equipment that may be necessary based on the individual's condition and circumstances.</p>


                                <div className="modal-action">
                                    <label htmlFor="my-modal" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalvials" className="btn lg:mx-0 h-[80px] md:h-fit shover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">CIS Catheter Color Guide</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvials" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvials" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Intermittent Catheter Size Color Guide:</h3>


                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                <div className="overflow-x-auto">
                                    <table className="rounded-lg bg-gray-800 table-pin-cols">
                                        {/* head */}
                                        <thead>
                                            <tr>
                                                <th className='w-[25px]'>Color</th>
                                                <th className='w-[25px]'>Width</th>
                                                <th className='w-[25px] max-w-[25px]'></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* row 1 */}
                                            <tr>
                                                <th className='bg-[#9bce31] text-vsm md:text-xs font-bold text-white text-center'>Light Green</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>6 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>2.0 mm</td>

                                            </tr>

                                            {/* row 2 */}
                                            <tr>
                                                <th className='bg-[#6495ec] text-vsm md:text-xs font-bold text-white text-center'>Light Blue</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center mb-2'>8 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'> 2.7 mm</td>
                                            </tr>


                                            {/* row 3 */}
                                            <tr>
                                                <th className='bg-[#000000] text-vsm md:text-xs font-bold text-white text-center'>Black</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center mb-2'>10 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'> 3.3 mm</td>
                                            </tr>


                                            {/* row 4 */}
                                            <tr>
                                                <th className='bg-[#ffffff] text-vsm md:text-xs font-bold text-black text-center'>White</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>12 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>4.0 mm</td>

                                            </tr>


                                            {/* row 5 */}
                                            <tr>
                                                <th className='bg-[#008001] text-vsm md:text-xs font-bold text-white text-center'>Dark Green</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>14 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>4.7 mm</td>
                                            </tr>


                                            {/* row 6 */}

                                            <tr>
                                                <th className='bg-[#ff8617] text-vsm md:text-xs font-bold text-white text-center'>Orange</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>16 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>5.3 mm<div className='text-vsm md:text-xs text-white text-start'> Most commonly used in adults</div></td>

                                            </tr>

                                            {/* row 7 */}

                                            <tr>
                                                <th className='bg-[#ff0000] text-vsm md:text-xs font-bold text-white text-center'>Red</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>18 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>6.0 mm</td>
                                            </tr>

                                            {/* row 8 */}

                                            <tr>
                                                <th className='bg-[#ffff00] text-vsm md:text-xs font-bold text-gray-800 text-center'>Yellow</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>20 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>6.7 mm</td>
                                            </tr>


                                            {/* row 8 */}


                                            <tr>
                                                <th className='bg-[#b955d3] text-vsm md:text-xs font-bold text-white text-center'>Purple</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>22 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>7.3 mm</td>
                                            </tr>

                                            {/* row 9 */}

                                            <tr>
                                                <th className='bg-[#0000ff] text-vsm md:text-xs font-bold text-white text-center'>Dark Blue</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>24 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>8.0 mm</td>
                                            </tr>
                                            {/* row 10 */}
                                            <tr>
                                                <th className='bg-[#ff11ad] text-vsm md:text-xs font-bold text-white text-center'>Pink</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>26 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>8.7 mm</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>


                                {/* <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-base md:text-xl">For more details, <a href='https://en.wikipedia.org/wiki/Vacutainer' target="_blank" rel="noreferrer" className='hover:text-white dark:text-blue-600 text-start text-base md:text-xl hover:bg-blue-400'>click here</a></p> */}







                                <div className="modal-action">
                                    <label htmlFor="my-modalvials" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalvideo" className="btn lg:mx-0 h-[80px] md:h-fit shover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Watch Video of CISC</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvideo" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvideo" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Foley's Catheter Insertion: Video</h3>



                                </div>

                                {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}
                                <h3 className="font-bold text-gray-800 text-base">
                                    Male Patient:</h3>
                                <div class='max-w-fit flex justify-center items-center mb-4'>
                                    <iframe
                                        className='w-fit h-fit md:w-[560px] md:h-[250px] flex place-content-center place-items-center'
                                        src="https://www.youtube.com/embed/7-qnhRAa9c4"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen>

                                    </iframe>
                                </div>
                                <h3 className="font-bold text-gray-800 text-base">
                                    Female Patient:</h3>
                                <div class='max-w-fit flex justify-center items-center'>
                                    <iframe
                                        className='w-fit h-fit md:w-[560px] md:h-[250px] flex place-content-center place-items-center'
                                        src="https://www.youtube.com/embed/V6kRalGPZB0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen>

                                    </iframe>
                                </div>


                                <div className="modal-action">
                                    <label htmlFor="my-modalvideo" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='w-100'>
                    <div className='grid grid-cols-1 place-items-start'>



                        <p class=" text-white dark:text-white px-4 indent-6 my-4 text-start text-sm md:text-lg">Intermittent Catheterization (IC) is a medical procedure recommended by the National Institute for Health and Care Excellence (NICE) for individuals with specific bladder conditions.</p>
                        <p class="mb-10 text-white dark:text-white px-4 my-1 text-start text-sm md:text-lg">Here is some information about Intermittent Catheterization based on NICE guidelines:</p>

                        <ul class="space-y-4 text-white list-decimal list-outside pl-8 pr-4 md:px-16 text-sm md:text-lg">

                            <li className='font-bold'> Definition:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Intermittent Catheterization, also known as clean intermittent self-catheterization (CISC), is a technique that involves inserting a catheter into the bladder to empty it, followed by removal of the catheter.</li>
                            </ol>

                            <li className='font-bold'> Indications:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>NICE guidelines recommend Intermittent Catheterization for individuals with various conditions, including neurogenic bladder dysfunction, urinary retention, and incomplete bladder emptying. It may also be suitable for those with certain types of urinary incontinence.</li>
                            </ol>

                            <li className='font-bold'> Assessment:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Before initiating Intermittent Catheterization, a thorough assessment should be conducted, taking into account the individual's medical history, bladder function, and their ability to perform the procedure independently or with assistance.</li>
                            </ol>

                            <li className='font-bold'>Training: </li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Proper training and education should be provided to the individual and, if necessary, their caregivers, to ensure they understand the technique, maintain hygiene, and minimize the risk of complications.</li>

                            </ol>

                            <li className='font-bold'>Catheter Selection:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>The choice of catheter should be based on the individual's needs, preferences, and clinical requirements. Factors to consider include catheter size, material, and appropriate lubrication to minimize discomfort during insertion.</li>
                            </ol>

                            <li className='font-bold'>Frequency:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>NICE guidelines suggest that the frequency of Intermittent Catheterization should be tailored to the individual's needs and bladder function. A healthcare professional should assess and determine the appropriate interval between catheterizations.</li>

                            </ol>

                            <li className='font-bold'>Hygiene:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Maintaining good hygiene during Intermittent Catheterization is crucial to prevent urinary tract infections (UTIs). Individuals should be advised on proper handwashing, catheter sterilization, and cleaning techniques.</li>
                            </ol>

                            <li className='font-bold'>Monitoring and Support:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Regular follow-up and ongoing support are important to monitor the individual's response to Intermittent Catheterization, assess any complications, and provide guidance or modifications to the procedure as needed.</li>
                            </ol>

                            <li className='font-bold'>Complications and Troubleshooting</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>NICE guidelines emphasize the importance of promptly identifying and managing any complications related to Intermittent Catheterization, such as UTIs, urethral trauma, or catheter blockage. Individuals should be educated on recognizing warning signs and seeking medical advice.</li>
                            </ol>

                            <li className='font-bold'>Quality of Life:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>The NICE guidelines recognize that Intermittent Catheterization can significantly improve the quality of life for individuals with bladder conditions, enabling them to maintain bladder function, minimize urinary symptoms, and reduce the risk of complications.</li>
                            </ol>



                        </ul>
                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Note:</div>

                        <p class="mb-3 text-white dark:text-white px-4 my-4 text-start text-sm md:text-lg">It is essential to consult the most up-to-date NICE guidelines or seek medical advice to ensure accurate and appropriate information regarding Intermittent Catheterization and its implementation for specific individuals.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div>


        </CalculatorWorking >

    );
};
