import React from 'react';
import { Link } from 'react-router-dom';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';




export const CardiacArrest = () => {


    return (

        <CalculatorWorking>


            <div className="container mb-20  mx-auto px-2 md:px-4 py-0">
                {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
                <h1 class="place-content-center block md:inline text-3xl text-start md:text-center md:text-4xl font-extrabold dark:text-white">Cardiac Arrest: <div className='text-start text-base md:text-2xl'>Understanding and Responding to a Life-Threatening Emergency</div></h1>
                <div className='flex my-4 mt-4 gap-x-3 place-items-center place-content-center px-0 md:px-8'>
                    <div className='px-0 md:px-8'>
                        <Link to='/interns/lifesavingprocedures/bls'>
                            <label className="btn lg:mx-0 hover:underline bg-white text-center w-fit hover:text-white  text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">

                                Basic Life Support
                            </label>

                        </Link>
                    </div>

                    <div className='px-0 md:px-8'>
                        <Link to='/interns/lifesavingprocedures/acls'>
                            <label className="btn lg:mx-0 hover:underline bg-white text-center w-fit hover:text-white  text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">


                                Advance Cardiac Life Support
                            </label>
                        </Link>
                    </div>


                    <div className='px-0 md:px-8'>
                        <label htmlFor="my-modalchainofsurvival" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Chain of Survival</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalchainofsurvival" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalchainofsurvival" className="btn btn-sm btn-circle absolute right-2 top-2 mb-2">✕</label>

                                <div className='gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Chain of Survival: See on <a href='https://cpr.heart.org/en/resuscitation-science/cpr-and-ecc-guidelines/algorithms' target="_blank" rel="noreferrer" className='text-base hover:text-white dark:text-blue-600 text-start hover:bg-blue-400'>AHA's website</a></h3>

                                    <h1 className="font-bold text-gray-800 text-xs">
                                        IHCA: In-Hospital Cardiac Arrest
                                    </h1>

                                    <h1 className="font-bold text-gray-800 text-xs mb-6">
                                        OHCA: Out-of Hospital Cardiac Arrest
                                    </h1>



                                </div>

                                {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                <div class='max-w-fit flex justify-center items-center mb-4'>
                                    <img class="scale-100 hover:scale-125 cursor-pointer object-cover ease-in duration-500" src="https://cpr.heart.org/-/media/CPR-Images/CPR-Guidelines-Images/Part-3-Adult-Basic-and-Advanced-Life-Support/Adult_IHCA_OHCA_COS_200513.jpg?h=557&w=800&hash=9332A5C5D7DF9B22108350ADB3132C89" alt="circular" />
                                </div>


                                <div className="modal-action">
                                    <label htmlFor="my-modalchainofsurvival" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>



                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>


                        <p class="mb-10 text-white dark:text-white px-2 indent-6 my-4 text-start text-sm md:text-lg">Cardiac arrest is a critical medical emergency that demands immediate attention. When the heart's electrical system malfunctions, the heart's normal rhythm is disrupted, leading to an irregular heartbeat. This condition, known as cardiac arrest, can result in the cessation of breathing, loss of consciousness, and, if not promptly treated, can quickly lead to death. In this blog, we will explore the latest guidelines provided by the American Heart Association (AHA) for recognizing and responding to cardiac arrest. By understanding these guidelines, you can be better prepared to respond effectively in a life-threatening situation.</p>

                        <ul class="space-y-4 text-white list-decimal list-outside px-6 md:px-16 text-sm md:text-lg">

                            <li className='font-bold'> Recognition and Activation of Emergency Response:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Recognize the signs of cardiac arrest: sudden loss of responsiveness and no normal breathing.</li>
                                <li>Immediately activate the emergency response system or call for help.</li>
                            </ol>

                            <li className='font-bold'>Early Cardiopulmonary Resuscitation (CPR):</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Start CPR with high-quality chest compressions.</li>
                                <li>Perform chest compressions at a rate of 100-120 compressions per minute, allowing for complete chest recoil.</li>
                                <li>Perform compressions at a depth of at least 2 inches (5 cm) in adults.</li>
                                <li>Use the proper hand position and technique for chest compressions.</li>
                                <li>Minimize interruptions in chest compressions.</li>
                            </ol>

                            <li className='font-bold'>Early Defibrillation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Use an automated external defibrillator (AED) as soon as it is available.</li>
                                <li>Follow the AED prompts for proper use and safety.</li>
                                <li>Deliver a shock if indicated.</li>
                            </ol>

                            <Link to='/interns/lifesavingprocedures/acls'>
                                <li className='text-sm md:text-lg font-bold mt-4 underline hover:bg-blue-400 hover:text-white text-blue-200'>
                                    <div className='flex'>
                                        <div>Advanced Cardiac Life Support (ACLS):

                                            <div><svg xmlns="http://www.w3.org/2000/svg" width="3%" height="3%" viewBox="0 0 448 512"><path class='fill-blue-300 hover:fill-white' d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z" /></svg></div>
                                        </div>
                                    </div>
                                </li>
                            </Link>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Administer advanced interventions promptly.</li>
                                <li>Establish advanced airway management, such as endotracheal intubation or supraglottic airway placement.</li>
                                <li>Provide ventilation with 100% oxygen during advanced airway management.</li>
                                <li>Administer appropriate medications as per the ACLS algorithms.</li>
                            </ol>

                            <li className='font-bold'>Post-Cardiac Arrest Care:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Optimize ventilation and oxygenation.</li>
                                <li>Maintain adequate blood pressure and perfusion.</li>
                                <li>Targeted temperature management may be considered for eligible patients.</li>
                                <li>Identify and treat the underlying cause of the cardiac arrest.</li>
                                <li>Provide intensive care support and monitoring.</li>
                            </ol>

                            <li className='font-bold'>Post-Resuscitation Care:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Optimize neurological and cardiovascular support.</li>
                                <li>Manage potential complications, such as hypotension, hypoxemia, electrolyte imbalances, and organ dysfunction.</li>
                                <li>Consider coronary angiography and revascularization if indicated.</li>
                                <li>Provide appropriate follow-up care and rehabilitation.</li>
                            </ol>



                        </ul>
                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Conclusion:</div>

                        <p class="mb-3 text-white dark:text-white px-4 indent-3 my-4 text-start text-sm md:text-lg">Cardiac arrest is a life-threatening emergency that requires immediate recognition and prompt intervention. By adhering to the guidelines set forth by the American Heart Association (AHA), healthcare providers and bystanders can improve the chances of survival and minimize long-term complications. Remember, recognizing the signs, initiating CPR, accessing early defibrillation, and providing advanced cardiac life support are critical steps in the chain of survival. Through awareness, education, and proactive response, we can work together to save lives and make a difference in the face of cardiac arrest.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div >


        </CalculatorWorking >

    );
};
