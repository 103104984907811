import React from 'react'

const RouterNavButton = ({
    children
}) => {
    return (

        <button
            class="whitespace-pre-wrap md:whitespace-nowrap mx-auto max-w-min lg:mx-0 hover:underline bg-white text-gray-800 text-base text-center md:text-xl font-bold rounded-full my-6 px-2 py-4 min-w-full min-h-fit shadow-lg focus:outline-nonemd:px-4 focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            {children}
        </button>

    )
}

export default RouterNavButton;