import React, { useRef } from 'react';
import './chevrondown.css'

const SnapScrollButton = () => {
    const scrollContainerRef = useRef(null);
    const scrollAreas = Array.from(document.querySelectorAll('.scroll-area'));
    let currentSectionIndex = 0;

    const scrollToNextSection = () => {
        currentSectionIndex = (currentSectionIndex + 1) % scrollAreas.length;
        if (scrollContainerRef.current) {
            scrollContainerRef.current.style.transform = `translateY(-${currentSectionIndex * 100}%)`;
        }
    };
    return (
        <button className="scroll-button" onClick={scrollToNextSection}>
            <div class="arrow-container">

                <div class="arrow-down"></div>

            </div>

        </button>

    );
};

export default SnapScrollButton;
