import React from 'react';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';




export const BasicAirwayManagement = () => {




    return (

        <CalculatorWorking>


            <div className="container mb-20  mx-auto px-2 md:px-4 py-0">
                {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
                <h1 class="place-content-center block md:inline text-xl text-start md:text-center md:text-4xl font-extrabold dark:text-white">Basic Airway Management:<div className='text-start text-sm font-bold md:text-2xl'>The Path to Lifesaving Breaths</div></h1>

                <div className='grid-cols-1 md:flex grid place-items-center place-content-center md:place-items-start px-0 md:px-8  my-2 md:my-6'>


                    <div className='flex gap-x-2 '>


                    </div>




                </div>


                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>


                        <div>
                            <div>
                                <p class="mb-10 text-white dark:text-white px-2 indent-6 my-4 text-start text-sm md:text-lg">
                                    Bag-mask ventilation is a fundamental skill in basic airway management but can be challenging to master. It is primarily performed in cases of inadequate ventilation, which may result from impaired respiratory effort or airway obstruction. Basic airway interventions are also necessary for managing patients with inadequate oxygenation and during cardiopulmonary resuscitation (CPR).                                </p>


                                <ul class="space-y-4 text-white list-disc list-outside px-6 md:px-16 text-sm md:text-lg">
                                    <ol class="pl-2 md:pl-5 mt-2 space-y-2 list-decimal list-outside">
                                        <li className='font-bold text-sm md:text-lg'> Causes of Inadequate Ventilation:</li>
                                        <div className='pl-0 mt-2 space-y-1'>Inadequate ventilation can occur due to various reasons, including insufficient respiratory effort and airway obstruction.</div>

                                        <ol class="mt-2 pl-2 md:pl-5 list-disc list-outside">

                                            <li><div className='font-bold'>Respiratory effort: </div>Inadequate respiratory effort can result from intrinsic (e.g., intracranial hemorrhage) or extrinsic (e.g., opioid overdose) factors. Assessing respiratory effort can be challenging as it is often silent and requires careful observation of chest wall movement, breathing pattern, use of accessory muscles, abnormal sounds, and signs of injury.</li>
                                            <li><div className='font-bold'>Airway obstruction: </div>Unconscious patients can experience airway obstruction due to soft tissue prolapse into the posterior pharynx, loss of muscular tone in the soft palate, or the presence of foreign bodies, injured tissue, blood, or secretions. Noises such as snoring or gurgling may indicate partial obstruction, while complete airway obstruction can manifest as retractions of accessory muscles or cyanosis. Prompt identification and relief of airway obstruction are crucial.</li>
                                        </ol>


                                        <li className='font-bold'>Airway Maneuvers:</li>
                                        <div className='pl-0 mt-2 space-y-1'>Two common positioning maneuvers can improve airflow during basic airway management: head-tilt chin-lift and jaw-thrust.</div>

                                        <ol class="mt-2 pl-2 md:pl-5 list-disc list-outside">
                                            <div>
                                                <div>
                                                    <li><div className='font-bold'>Head-tilt chin-lift: </div>This maneuver is the primary technique for patients without suspected cervical spine injury. It involves using two hands to extend the patient's neck and open the airway. One hand applies downward pressure on the forehead, while the index and middle fingers of the other hand lift the mandible at the mentum to elevate the tongue from the posterior pharynx.</li>
                                                </div>
                                                <div class='max-w-fit md:max-w-[300px] justify-center items-center mb-4'>
                                                    <img class="scale-100 hover:scale-125 md:hover:scale-100 cursor-pointer object-cover ease-in duration-500" src="https://pocketdentistry.com/wp-content/uploads/285/B9781455705542000058_f005-007-9781455705542.jpg" alt="circular" />
                                                    <div className='md:hidden text-xs text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                </div>
                                            </div>

                                            <div>
                                                <div>
                                                    <li><div className='font-bold'>Jaw-thrust maneuver: </div>The jaw-thrust maneuver is suitable for patients with suspected cervical spine injury. It moves the tongue anteriorly with the mandible, reducing the likelihood of airway obstruction. To perform this maneuver, stand at the head of the bed while the patient is supine, place the heels of both hands on the parieto-occipital areas of the patient's head, and grasp the angles of the mandible with the index and long fingers. Then, displace the jaw anteriorly.</li>
                                                </div>
                                                <div class='max-w-fit md:max-w-[300px] justify-center items-center mb-4'>
                                                    <img class="scale-100 hover:scale-125 md:hover:scale-100 cursor-pointer object-cover ease-in duration-500" src="https://www.acls.net/images/pals-certification.svg" alt="circular" />
                                                    <div className='md:hidden text-xs text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                </div>
                                            </div>
                                        </ol>


                                        <li className='font-bold'>Cervical Spine Immobilization:</li>
                                        <div className='pl-0 mt-2 space-y-1'>When performing airway maneuvers, it is crucial to stabilize the cervical spine to minimize head and neck movement, especially in patients with suspected cervical spine injury. Manual in-line stabilization is preferred over mechanical restraints, and cervical collars can be opened or removed to facilitate airway management while maintaining continuous manual stabilization.</div>

                                        <ul class="space-y-4 text-white list-[lower-roman] list-outside px-6 md:px-10 text-sm md:text-lg mb-4">
                                            <li><div class='font-bold'>Mallampati Classification: </div>The Mallampati classification is a widely used method to assess the visibility and size of the oral cavity and pharynx. It involves asking the patient to open their mouth and protrude their tongue while the examiner is at level the same levels as that of patient and evaluates the structures. The classification ranges from I to IV.</li>
                                            <div className='text-sm md:text-lg font-bold'>Mnemonic: PUSH - Keep removing one alphabet with every class </div>

                                            <ol class="mt-2 pl-2 md:pl-5 list-disc list-outside">

                                                <li>Class I (PUSH): The entire tonsillar pillars, uvula, hard and soft palates are visualized</li>
                                                <li>Class II (USH): Partial uvula and soft palate are visualized</li>
                                                <li>Class III (SH): Only the soft palate is visualized</li>
                                                <li>Class IV (H): Only hard palate is visualized or no visualization of any structures beyond the tongue</li>
                                            </ol>

                                            <div class='max-w-fit items-center mb-4'>
                                                <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://www.uptodate.com/contents/images/EM/75229/Mallampaticlassification.jpg" alt="circular" />
                                                <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                            </div>



                                            <li><div class='font-bold'>Thyromental Distance: </div>The thyromental distance is measured as the distance between the thyroid notch and the tip of the chin with the patient's head fully extended. <span className='font-bold'>Short TMD has been defined as less than 6 cm.</span> A shorter thyromental distance may suggest limited space for airway management and potential difficulty in achieving proper alignment during intubation. <span className='font-bold'>It is the single most important indicator of a difficult intubation.</span> </li>
                                            <div class='max-w-fit md:max-w-[400px] justify-center items-center mb-4'>
                                                <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://www.e-safe-anaesthesia.org/sessions/02_03/jpg/ana_1_030_12_t1_01_med.jpg" alt="circular" />
                                                <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                            </div>

                                            <li><div class='font-bold'>Sternomental distance </div>Sternomental distance is measured between the sternal notch and the mandible, measured in full neck extension. <span className='font-bold'>Short sternomental distance is defined as less than 12 cm.</span> This parameter and TMD may be objective surrogates for adequacy of neck extension. </li>
                                            <li><div class='font-bold'>Mandibular protrusion (Upper lip bite test): </div>Patients are asked to protrude the lower jaw such that the mandibular teeth are in front of the maxillary teeth, as a predictor of the ability to sublux the mandible during laryngoscopy. A more objective, similar measurement is the upper lip bite test (ULBT), which assesses the patient's ability to reach and cover the upper lip with their lower incisors. ULBT grading includes</li>
                                            <ol class="mt-2 pl-2 md:pl-5 list-disc list-outside mb-4">
                                                <li>Grade 1: The patient can fully cover the upper lip with lower incisors</li>
                                                <li>Grade 2: The patient can partially cover the upper lip with lower incisors</li>
                                                <li>Grade 3: The patient cannot reach the upper lip with lower teeth</li>
                                            </ol>

                                            <li><div class='font-bold'>Interincisor Gap: </div>The interincisor gap is assessed by measuring the distance between the upper and lower incisors when the patient opens their mouth. A reduced interincisor gap may indicate limited mouth opening and potential challenges during intubation or mask ventilation. A crude method (three finger test) is to check whether patient can fit 3 fingers in his mouth in vertical orientation.</li>
                                            <div class='min-w-fit md:max-w-[300px] justify-center items-center mb-4'>
                                                <img class="scale-100 bg-white hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://images.ctfassets.net/vrrt8fsfwf0e/52ks0MsAxNIEtzluSskuJ5/42617bbe89f43be457efa247de4a59ec/Trismus_Art_1.svg" alt="circular" />
                                                <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                            </div>

                                            <li><div class='font-bold'>Neck Mobility: </div>Evaluation of neck mobility is crucial as restricted movement can impact airway positioning and intubation. Assessing the ability to flex, extend, and rotate the neck helps identify potential difficulties in achieving optimal airway alignment.</li>
                                            <li><div class='font-bold'>Radiological Imaging: </div>In some cases, e.g. retrosternal extension of thyroid, radiological imaging such as X-rays, computed tomography (CT), or magnetic resonance imaging (MRI) may be utilized to assess the airway anatomy in greater detail. These imaging techniques can help identify structural abnormalities, tumors, or other factors that may impede airway management.</li>
                                            <li><div class='font-bold'>Ultrasound: </div>Ultrasound can be employed to assess the airway in real-time. It allows visualization of the soft tissues, structures, and dynamic movements during respiration. Ultrasound can assist in identifying anatomical variations, determining the optimal site for airway interventions, and guiding the placement of devices such as supraglottic airway devices or percutaneous tracheostomy.</li>
                                            <li><div class='font-bold'>Endoscopic Evaluation: </div>In certain cases, a flexible or rigid endoscope may be used to directly visualize the airway and assess its anatomy. This technique provides a detailed view of the larynx, vocal cords, and any potential abnormalities that may affect airway management.</li>

                                        </ul>


                                        <li className='font-bold'>Airway Adjuncts:</li>
                                        <div className='pl-0 mt-2 space-y-1'>To maintain an open airway, oropharyngeal and nasopharyngeal airway devices are crucial. They prevent tongue obstruction and ensure a clear path for breathing. For bag-mask ventilation, an oropharyngeal airway (OPA) is recommended, unless it's only needed temporarily. Additional nasopharyngeal airways can be used alongside the OPA. However, neither device protects against aspiration. If a patient can't protect their airway, an endotracheal tube should be inserted promptly.</div>
                                        <ol class="mt-2 pl-2 md:pl-5 list-disc list-outside mb-4">
                                            <li><div className='font-bold'>Oropharyngeal airway (OPA):</div>OPAs are only suitable for deeply unresponsive patients who cannot maintain their airway. Using an OPA in responsive patients can lead to vomiting and aspiration. OPAs are curved, firm, hollow tubes with a rectangular opening. They are used to create a clear path between the mouth and glottis, preventing obstruction by the tongue and other soft tissues. When inserting an OPA, ensure the flange rests against the patient's lips to prevent accidental inward movement. OPAs are available in different sizes, and a rough method for selecting the correct size is to align the airway with the patient's mandible, with the tip reaching the angle of the mandible.</li>
                                            <li><div className='font-bold'>Nasopharyngeal airway (NPA): </div>NPAs are soft rubber or plastic tubes inserted through the nose into the posterior pharynx. Patients tolerate NPAs better than OPAs, making them suitable when using an OPA is challenging, such as in patients with a clenched jaw or those who are semiconscious. NPAs, also known as nasal trumpets, are sized based on their internal diameter. The longer the tube, the larger the internal diameter. To choose the correct size, align the airway with the patient's mandible, with the flared end at the tip of the nose and the distal tip reaching the angle of the mandible. Before insertion, coat the NPA with water-soluble lubricant or anesthetic jelly. Insert the tube along the floor of the naris into the posterior pharynx, noting the caudad inclination of the naris floor. Rotate the tube if resistance is encountered.</li>

                                        </ol>

                                        <li className='font-bold'>Bag-mask Venilation:</li>
                                        <div className='pl-0 mt-2 space-y-1'>Bag-mask ventilation is a crucial skill in airway management, but it can be challenging to perform correctly. It is essential to monitor the technique carefully to ensure adequate ventilation and oxygenation for patients requiring airway support. Proper bag-mask ventilation allows clinicians to buy time for a controlled and well-planned approach to definitive airway management, such as endotracheal intubation.</div>


                                        <ul class="space-y-4 text-white list-disc list-outside px-6 md:px-8 text-sm md:text-lg mb-6">
                                            <li className='pl-0 mt-2 space-y-1 font-bold mb-4'>Successful bag-mask ventilation relies on three key factors: </li>
                                            <ol class="mt-2 pl-2 md:pl-6 list-[lower-latin] list-outside mb-4">
                                                <li>Clear airway</li>
                                                <li>Proper mask seal</li>
                                                <li>Correct ventilation (including volume, rate, and cadence)</li>
                                            </ol>
                                        </ul>
                                        <div className='px-4 italic mt-2 space-y-2'>Airway maneuvers and adjuncts are used to ensure airway patency. Whenever possible, a two-handed technique is preferred. Prior to placing the mask on the patient's face, the airway should be opened using appropriate maneuvers and devices.
                                        </div>


                                        <ul class="space-y-4 text-white list-disc list-outside px-6 md:px-8 text-sm md:text-lg mb-6">
                                            <li className='pl-0 mt-2 space-y-1 font-bold mb-4'>Two techniques can be used to hold the mask in place:  </li>
                                            <ol class="mt-2 pl-2 md:pl-6 list-[lower-latin] list-outside mb-4">

                                                <li className='font-bold'>Single-hand technique </li>
                                                <div className='pl-0 mt-2 space-y-1 mb-4'>The single-hand technique involves  </div>

                                                <ol class="mt-2 pl-2 md:pl-5 list-disc list-outside mb-4">
                                                    <li>Placement of one hand on the mask, with the web space between the thumb and index finger resting against the mask connector (Like the C-shape).</li>
                                                    <li>The other three fingers are used to perform a chin-lift maneuver, lifting the mandible into the mask (Like the E-shape).</li>
                                                    <div class='max-w-fit md:max-w-[300px] justify-center items-center mb-4'>
                                                        <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://static.cambridge.org/binary/version/id/urn:cambridge.org:id:binary:20201002023020199-0834:9781108303477:41953fig12_2.png" alt="circular" />
                                                        <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                        <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                                    </div>
                                                </ol>


                                                <li className='font-bold'>Two-hand technique.</li>
                                                <div className='pl-0 mt-2 space-y-1 mb-4'>The two-hand technique requires two providers. </div>

                                                <ol class="mt-2 pl-2 md:pl-5 list-disc list-outside mb-4">
                                                    <li>One maintaining a good mask seal and open airway </li>
                                                    <li>The other squeezes the bag to ventilate the patient</li>
                                                    <div class='max-w-fit md:max-w-[300px] justify-center items-center mb-4'>
                                                        <img class="scale-100 hover:scale-125 md:hover:scale-150 cursor-pointer object-cover ease-in duration-500" src="https://img.medscapestatic.com/pi/meds/ckb/78/23778tn.jpg" alt="circular" />
                                                        <div className='md:hidden text-vsm text-center opacity-75 font-bold'>Tap image to zoom</div>
                                                        <div className='text-vsm hidden md:block text-center opacity-75 font-bold'>Hover image to zoom</div>
                                                    </div>
                                                </ol>


                                            </ol>
                                            <div className='pl-0 mt-2 italic space-y-1 mb-4'>Different hand positions can be used, but the thenar eminences are recommended for better mask stability and reduced provider fatigue. </div>

                                        </ul>


                                        <li className='font-bold'>Ventilation volumes, rates, and cadence:</li>
                                        <div className='pl-0 mt-2 space-y-1'>Once an open airway and a good mask seal are present, the clinician connects the bag to the mask and ventilates the patient. Three critical errors should be avoided:</div>
                                        <ol class="mt-2 pl-4 md:pl-6 list-[lower-latin] list-outside mb-4">
                                            <li>Giving excessive tidal volumes</li>
                                            <li>Forcing air too quickly</li>
                                            <li>Ventilating too rapidly</li>
                                        </ol>
                                        <div className='pl-0 mt-2 space-y-1'>A volume just large enough to cause chest rise <span className='font-bold'>(no more than 8 to 10 cc/kg)</span> should be used, as overinflation of the lungs can lead to barotrauma. During cardiopulmonary resuscitation (CPR), even smaller tidal volumes are adequate (5 to 6 cc/kg) due to the reduced cardiac output of such patients</div>
                                        <div className='pl-0 mt-2 space-y-1'>The bag should not be squeezed explosively. It should be squeezed steadily over approximately one full second. This technique, in addition to producing smaller tidal volumes, reduces the likelihood of creating sufficient pressure to open the gastroesophageal sphincter, which leads to gastric inflation. A potential complication of gastric insufflation is vomiting, which can lead to aspiration of gastric contents.</div>


                                        <li className='font-bold'>Cricoid pressure (Sellicks maneuver):</li>
                                        <div className='pl-0 mt-2 space-y-1'>Sellicks maneuver (ie, firm cricoid pressure) may reduce gastric insufflation during bag-mask ventilation, and it is reasonable to apply it during BMV if adequate personnel are available. The technique and its role in rapid sequence intubation are controversial.</div>


                                    </ol>

                                </ul>
                            </div>


                        </div>




                        <p class="my-8 text-white dark:text-white px-4 indent-3 text-start text-sm md:text-lg">In conclusion, inadequate ventilation can occur due to insufficient respiratory effort or airway obstruction. Proper assessment and management of the airway are crucial to ensure adequate oxygenation and ventilation. Airway maneuvers, adjuncts, and careful monitoring play a vital role in maintaining an open airway during bag-mask ventilation. It is important to avoid excessive tidal volumes, rapid ventilation, and forcing air too quickly to prevent complications such as barotrauma and gastric insufflation. Additionally, the use of appropriate techniques, such as cricoid pressure, remains a topic of debate in airway management. Overall, a comprehensive understanding of airway anatomy, proper technique, and vigilance are essential for successful bag-mask ventilation and optimal patient outcomes.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div >


        </CalculatorWorking >

    );
};
