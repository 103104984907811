import React from 'react';
import SaveAsPDF from '../../../../features/button/save-as-pdf';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';

export const IVSampling = () => {


    return (

        <CalculatorWorking>



            {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
            <h1 class="place-content-center text-2xl text-center md:text-4xl mb-15 font-extrabold dark:text-white">Intravenous Sampling <div>(Venepuncture)</div></h1>

            <div className="container mb-20 mx-auto px-4 py-0">
                <div className="flex place-items-center place-content-center gap-x-1 container grid-cols-none mx-auto px-4 py-0">

                    <div className='px-0 md:px-8 mt-2'>
                        <label htmlFor="my-modal" className="btn lg:mx-0 hover:underline h-[80px] md:h-fit bg-white text-center w-[110px] md:w-fit  hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Necessary Equipment for Sampling</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modal" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white ">
                                <label htmlFor="my-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        List of Necessary Equipment for IV Sampling:</h3>


                                    <div> <SaveAsPDF /></div>
                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                <ul class="space-y-4 text-gray-800 list-decimal list-outside px-4 md:px-8 text-vsm  md:text-base">

                                    <li className='font-bold'> Gloves:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Disposable gloves to ensure hand hygiene and prevent contamination.</li>
                                    </ol>

                                    <li className='font-bold'>IV Cannula:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Appropriate-sized IV cannula for venous access and blood collection.</li>
                                    </ol>

                                    <li className='font-bold'>Antiseptic Solution:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>An antiseptic solution, such as chlorhexidine or povidone-iodine, for skin preparation and disinfection.</li>
                                    </ol>

                                    <li className='font-bold'>Tourniquet:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A tourniquet to apply pressure above the intended insertion site to enhance vein visibility and control blood flow during cannulation.</li>
                                    </ol>

                                    <li className='font-bold'>Non-Touch Technique (NTT) Equipment:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Pre-assembled blood collection tubes or syringes with appropriate needles for aseptic blood collection without direct needle-to-tube contact.</li>
                                    </ol>

                                    <li className='font-bold'>Adhesive Dressings:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Adhesive dressings or transparent film dressings to secure the IV cannula in place after insertion.</li>
                                    </ol>

                                    <li className='font-bold'>Sharps Container:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A designated sharps container for safe disposal of used needles, syringes, and other sharps.</li>
                                    </ol>

                                    <li className='font-bold'>Labels: </li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Pre-printed labels or marker pens for accurate labeling of collected blood samples, including patient identification and test requirements.</li>
                                    </ol>

                                    <li className='font-bold'>Alcohol Swabs: </li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Alcohol swabs for cleaning the tops of blood collection tubes before transferring blood samples.</li>
                                    </ol>

                                    <li className='font-bold'>Gauze or Cotton Balls:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile gauze or cotton balls for applying pressure to the puncture site after blood collection to stop any bleeding.</li>
                                    </ol>

                                    <li className='font-bold'>Waste Disposal Bags:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Plastic bags for the proper disposal of used gloves, swabs, and other non-sharp waste.</li>
                                    </ol>

                                    <li className='font-bold'>Hand Hygiene Supplies:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Soap and water or alcohol-based hand rub for performing hand hygiene before putting on gloves and after the procedure.</li>
                                    </ol>


                                </ul>

                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-vsm md:text-base">It is essential to follow the NICE guidelines on IV sampling to maintain patient safety, prevent infection, and ensure accurate blood sample collection. These steps provide a framework for healthcare professionals to perform IV sampling effectively and consistently, optimizing patient care and diagnostic accuracy.</p>


                                <div className="modal-action">
                                    <label htmlFor="my-modal" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className='px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalvials" className="btn lg:mx-0 h-[80px] md:h-fit shover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Vial Color Guide</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvials" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvials" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Vacutainer Vials for Blood Sampling</h3>


                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                <div className="overflow-x-auto">
                                    <table className="rounded-lg bg-gray-800 table-pin-cols">
                                        {/* head */}
                                        <thead>
                                            <tr>
                                                <th>Color</th>
                                                <th>Additive</th>
                                                <th className='w-52'>Tests</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* row 1 */}
                                            <tr>
                                                <th className='bg-[#ba56d4] text-vsm md:text-xs font-bold text-white text-center'>Purple</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>EDTA <div className='text-vsm md:text-xs text-white text-center'>(anticoagulant)</div></td>
                                                <td className='text-vsm md:text-xs text-white text-start'> <div className='text-vsm md:text-xs text-white font-bold text-start'>Whole blood:</div> CBC, ESR, Coombs test, platelet antibodies, flow cytometry, blood levels of tacrolimus and cyclosporin. <div> (HbA1c & Blood Glucose when Gray is not available)</div></td>

                                            </tr>
                                            {/* row 2 */}
                                            <tr>
                                                <th className='bg-[#daa520] text-vsm md:text-xs font-bold text-white text-center'>Gold / Yellow</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>Clot activator <div className='text-vsm md:text-xs text-white text-center'>(coagulant gel)</div> </td>
                                                <td className='text-vsm md:text-xs text-white text-start'><div className='text-vsm md:text-xs text-white font-bold text-start'>Serum-separating tube:</div>Most chemistry (LFTs, RFTs, S/E or U&E), endocrine and serology tests, including hepatitis and HIV. <div>(Every test with serum in its name)</div></td>

                                            </tr>
                                            {/* row 3 */}
                                            <tr>
                                                <th className='bg-[#88ceec] text-vsm md:text-xs font-bold text-gray-800 text-center'>Light blue</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>Sodium citrate <div className='text-vsm md:text-xs text-white text-center'>(anticoagulant)</div> </td>
                                                <td className='text-vsm md:text-xs text-white text-start'><span className='text-vsm md:text-xs text-white font-bold text-start'>Coagulation tests</span> such as prothrombin time (PT) and partial thromboplastin time (PTT) and thrombin time (TT).</td>
                                            </tr>

                                            {/* row 4 */}
                                            <tr>
                                                <th className='bg-[#808080] text-vsm md:text-xs font-bold text-white text-center'>Gray</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-start mb-2'><div className='text-vsm md:text-xs font-bold text-white text-center mb-2'>Sodium fluoride (glycolysis inhibitor)</div><div className='text-vsm md:text-xs text-white text-center'>Potassium oxalate (anticoagulant)</div> </td>
                                                <td className='text-vsm md:text-xs text-white text-start'><span className='text-vsm md:text-xs text-white font-bold text-start'>Glucose</span>, lactate</td>
                                            </tr>


                                            {/* row 5 */}
                                            <tr>
                                                <th className='bg-[#90ee8f] text-vsm md:text-xs font-bold text-gray-800 text-center'>Light green</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>Lithium Heparin <div className='text-vsm md:text-xs text-white text-center'>(anticoagulant)</div> </td>
                                                <td className='text-vsm md:text-xs text-white text-start'><span className='text-vsm md:text-xs text-white font-bold text-start'>Plasma:</span>Arterial blood gases, Electrolytes</td>
                                            </tr>


                                            {/* row 6 */}
                                            <tr>
                                                <th className='bg-[#3bb371] text-vsm md:text-xs font-bold text-white text-center'>Dark green</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>Sodium Heparin <div className='text-vsm md:text-xs text-white text-center'>(anticoagulant)</div> </td>
                                                <td className='text-vsm md:text-xs text-white text-start'>Chromosome testing, HLA typing, ammonia, lactate</td>
                                            </tr>


                                            {/* row 7 */}
                                            <tr>
                                                <th className='bg-[#ffc1cb] text-vsm md:text-xs font-bold text-gray-800 text-center'>Pink</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>EDTA <div className='text-vsm md:text-xs text-white text-center'>(anticoagulant)</div> </td>
                                                <td className='text-vsm md:text-xs text-white text-start'><span className='text-vsm md:text-xs text-white font-bold text-start'>Blood typing and cross-matching</span>, direct Coombs test, HIV viral load</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-base md:text-xl">For more details, <a href='https://en.wikipedia.org/wiki/Vacutainer' target="_blank" rel="noreferrer" className='hover:text-white dark:text-blue-600 text-start text-base md:text-xl hover:bg-blue-400'>click here</a></p>







                                <div className="modal-action">
                                    <label htmlFor="my-modalvials" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>




                    <div className='px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalvideo" className="btn lg:mx-0 h-[80px] md:h-fit hover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Watch Video of Sampling</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvideo" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvideo" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Venepuncture: Video</h3>



                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div>
                                <div class='max-w-fit flex justify-center items-center'>
                                    <iframe
                                        className='w-fit h-fit md:w-[560px] md:h-[360px] flex place-content-center place-items-center'
                                        src="https://www.youtube.com/embed/Zioqw3pfSCU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen>

                                    </iframe>
                                </div>



                                <div className="modal-action">
                                    <label htmlFor="my-modalvideo" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>



                        <p class=" text-white dark:text-white px-4 indent-6 my-4 text-start text-sm md:text-lg">Intravenous (IV) sampling plays a crucial role in patient care, allowing healthcare professionals to obtain blood samples for diagnostic purposes, monitor medication levels, and assess the patient's overall condition. The National Institute for Health and Care Excellence (NICE) provides guidelines on the recommended steps for performing IV sampling.</p>
                        <p class="mb-10 text-white dark:text-white px-4 my-1 text-start text-sm md:text-lg">Let's take a brief look at these steps:</p>

                        <ul class="space-y-4 text-white list-decimal list-outside px-4 md:px-16 text-sm md:text-lg">

                            <li className='font-bold'> Preparation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Gather all the necessary equipment, including gloves, appropriate-sized IV cannula, antiseptic solution, tourniquet, non-touch technique (NTT) equipment, and appropriate tubes for blood collection.</li>
                                <li>Ensure a clean and well-lit area for the procedure.</li>
                                <li>Verify the patient's identification and explain the procedure to them, addressing any concerns or questions.</li>
                            </ol>

                            <li className='font-bold'> Hand Hygiene:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Perform hand hygiene by using soap and water or an alcohol-based hand rub before putting on gloves.</li>
                            </ol>

                            <li className='font-bold'> Site Selection:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Select an appropriate site for IV cannulation, following NICE guidelines on site selection based on patient factors (e.g., clinical condition, anticipated duration of IV access).</li>
                            </ol>

                            <li className='font-bold'> Skin Preparation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Cleanse the selected site with an appropriate antiseptic solution using a back-and-forth motion for at least 30 seconds, allowing it to dry.</li>
                            </ol>

                            <li className='font-bold'>Cannulation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Apply a tourniquet above the intended insertion site to enhance vein visibility and control blood flow.</li>
                                <li>Use a sterile technique to insert the IV cannula, ensuring proper positioning within the vein.</li>
                                <li>Secure the cannula in place using appropriate adhesive dressings.</li>
                            </ol>

                            <li className='font-bold'>Blood Collection:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Apply the non-touch technique (NTT) for blood collection. This involves using pre-assembled blood collection tubes or transferring blood from a syringe into the required tubes without direct needle-to-tube contact.</li>
                                <li>Collect the necessary blood samples, ensuring adequate volume for each required test.</li>
                            </ol>

                            <li className='font-bold'>Post-Sampling Care:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Apply gentle pressure over the puncture site to stop any bleeding.</li>
                                <li>Dispose of sharps in designated sharps containers.</li>
                                <li>Label the collected blood samples appropriately and transport them to the laboratory promptly, following local protocols.</li>
                            </ol>

                            <li className='font-bold'>Documentation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Record the details of the procedure, including the site of cannulation, type of cannula used, any complications encountered, and patient response.</li>
                            </ol>



                        </ul>
                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Note:</div>

                        <p class="mb-3 text-white dark:text-white px-4 my-4 text-start text-sm md:text-lg">It is essential to follow the NICE guidelines on IV sampling to maintain patient safety, prevent infection, and ensure accurate blood sample collection. These steps provide a framework for healthcare professionals to perform IV sampling effectively and consistently, optimizing patient care and diagnostic accuracy.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div>


        </CalculatorWorking >

    );
};
