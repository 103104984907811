import React from 'react';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';




export const ACLS = () => {




    return (

        <CalculatorWorking>


            <div className="container mb-20  mx-auto px-2 md:px-4 py-0">
                {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
                <h1 class="place-content-center block md:inline text-3xl text-start md:text-center md:text-4xl font-extrabold dark:text-white">ACLS<div className='text-start text-sm md:text-2xl'>Advanced Cardiovascular Life Support for Critical Care Situations</div></h1>
                <div className='grid-cols-1 md:flex grid place-items-center place-content-center md:place-items-start px-0 md:px-8  my-2 md:my-6'>


                    <div className='flex gap-x-2 '>
                        <div className='px-0 md:px-8  mt-1'>
                            <label htmlFor="my-modalarrest" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">ACLS: Arrest Algorithm</label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my-modalarrest" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                    <label htmlFor="my-modalarrest" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                    <div className='flex gap-x-20'>
                                        <h3 className="font-bold text-gray-800 text-base mb-6">
                                            ACLS Arrest Algorithm: See on <a href='https://cpr.heart.org/en/resuscitation-science/cpr-and-ecc-guidelines/algorithms' target="_blank" rel="noreferrer" className='text-base hover:text-white dark:text-blue-600 text-start hover:bg-blue-400'>AHA's website</a></h3>



                                    </div>

                                    {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                    <div class='max-w-fit flex justify-center items-center mb-4'>
                                        <img class="scale-100 hover:scale-125 cursor-pointer object-cover ease-in duration-500" src="https://cpr.heart.org/-/media/CPR-Images/CPR-Guidelines-Images/Part-3-Adult-Basic-and-Advanced-Life-Support/AlgorithmACLS_CA_200731.jpg?h=800&iar=0&mw=960&w=631&hash=4F21843EEC70EA89840E3E8A0A5EDE90" alt="arrest" />
                                    </div>



                                    <div className="modal-action">
                                        <label htmlFor="my-modalarrest" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className='px-0 md:px-8  mt-1'>
                            <label htmlFor="my-modalcircular" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">ACLS: Circular Algorithm</label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my-modalcircular" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                    <label htmlFor="my-modalcircular" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                    <div className='flex gap-x-20'>
                                        <h3 className="font-bold text-gray-800 text-base mb-6">
                                            ACLS Arrest Circular Algorithm: See on <a href='https://cpr.heart.org/en/resuscitation-science/cpr-and-ecc-guidelines/algorithms' target="_blank" rel="noreferrer" className='text-base hover:text-white dark:text-blue-600 text-start hover:bg-blue-400'>AHA's website</a></h3>



                                    </div>

                                    {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                    <div class='max-w-fit flex justify-center items-center mb-4'>
                                        <img class="scale-100 hover:scale-125 cursor-pointer object-cover ease-in duration-500" src="https://cpr.heart.org/-/media/CPR-Images/CPR-Guidelines-Images/Part-3-Adult-Basic-and-Advanced-Life-Support/AlgorithmACLS_CA_Circular_200612.jpg?h=653&iar=0&mw=960&w=700&hash=343BF9BCEE2EF59E4C60E241A32E1782" alt="circular" />
                                    </div>



                                    <div className="modal-action">
                                        <label htmlFor="my-modalcircular" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className='flex gap-x-2 '>

                        <div className='px-0 md:px-8  mt-1'>
                            <label htmlFor="my-modaltermination" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">ACLS: Termination of Resuscitation</label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my-modaltermination" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                    <label htmlFor="my-modaltermination" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                    <div className='flex gap-x-20'>
                                        <h3 className="font-bold text-gray-800 text-base mb-6">
                                            ACLS, Termination of Resuscitation: See on <a href='https://cpr.heart.org/en/resuscitation-science/cpr-and-ecc-guidelines/algorithms' target="_blank" rel="noreferrer" className='text-base hover:text-white dark:text-blue-600 text-start hover:bg-blue-400'>AHA's website</a></h3>



                                    </div>

                                    {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}


                                    <div class='max-w-fit flex justify-center overflow-hidden items-center mb-4'>
                                        <img class="scale-100 hover:scale-125 cursor-pointer object-cover ease-in duration-500" src="https://cpr.heart.org/-/media/CPR-Images/CPR-Guidelines-Images/Part-3-Adult-Basic-and-Advanced-Life-Support/AlgorithmACLS_Termination_of_Resusc_200612.jpg?h=456&iar=0&mw=960&w=800&hash=B1E4DFAF438D3EA653123333F35DF8AF" alt="circular" />

                                    </div>


                                    <div className="modal-action">
                                        <label htmlFor="my-modaltermination" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className='px-0 md:px-8  mt-1'>
                            <label htmlFor="my-modalchainofsurvival" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Chain of Survival</label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my-modalchainofsurvival" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                    <label htmlFor="my-modalchainofsurvival" className="btn btn-sm btn-circle absolute right-2 top-2 mb-2">✕</label>

                                    <div className='gap-x-20'>
                                        <h3 className="font-bold text-gray-800 text-base mb-6">
                                            Chain of Survival: See on <a href='https://cpr.heart.org/en/resuscitation-science/cpr-and-ecc-guidelines/algorithms' target="_blank" rel="noreferrer" className='text-base hover:text-white dark:text-blue-600 text-start hover:bg-blue-400'>AHA's website</a></h3>

                                        <h1 className="font-bold text-gray-800 text-xs">
                                            IHCA: In-Hospital Cardiac Arrest
                                        </h1>

                                        <h1 className="font-bold text-gray-800 text-xs mb-6">
                                            OHCA: Out-of Hospital Cardiac Arrest
                                        </h1>



                                    </div>

                                    {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                    <div class='max-w-fit flex justify-center items-center mb-4'>
                                        <img class="scale-100 hover:scale-125 cursor-pointer object-cover ease-in duration-500" src="https://cpr.heart.org/-/media/CPR-Images/CPR-Guidelines-Images/Part-3-Adult-Basic-and-Advanced-Life-Support/Adult_IHCA_OHCA_COS_200513.jpg?h=557&w=800&hash=9332A5C5D7DF9B22108350ADB3132C89" alt="circular" />
                                    </div>


                                    <div className="modal-action">
                                        <label htmlFor="my-modalchainofsurvival" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>


                </div>


                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>


                        <div>
                            <div>
                                <p class="mb-10 text-white dark:text-white px-2 indent-6 my-4 text-start text-sm md:text-lg">Advanced Cardiovascular Life Support (ACLS) is an essential set of interventions and algorithms designed to provide optimal care for patients experiencing life-threatening cardiac emergencies. Developed by the American Heart Association (AHA), ACLS guidelines provide healthcare professionals with the knowledge and skills necessary to manage cardiac arrest, stroke, and other critical conditions. In this blog, we will delve into the latest ACLS guidelines to understand the key components and interventions that can help improve patient outcomes in critical care situations.</p>

                                <ul class="space-y-4 text-white list-decimal list-outside px-6 md:px-16 text-sm md:text-lg">

                                    <li className='font-bold text-sm md:text-lg'> Management of Cardiac Arrest:</li>
                                    <div className='pl-0 mt-2 space-y-1'>ACLS emphasizes the importance of high-quality CPR, early defibrillation, and timely administration of medications during cardiac arrest. The primary focus is on the following interventions:</div>
                                    <ol class="pl-2 md:pl-5 mt-2 space-y-1 list-[lower-latin] list-outside">
                                        <li><div class='font-bold'>BLS Survey: </div>Assess the patient's responsiveness, activate the emergency response system, and initiate CPR if necessary.</li>
                                        <li><div class='font-bold'>Rapid Defibrillation: </div>Early defibrillation is critical for patients with shockable rhythms (ventricular fibrillation or pulseless ventricular tachycardia). Use an AED or manual defibrillator to deliver appropriate shocks.</li>
                                        <li><div class='font-bold'>Airway Management: </div>Establish and maintain a patent airway using advanced airway devices (endotracheal tube or supraglottic airway). Monitor oxygenation and ventilation closely.</li>
                                        <li><div class='font-bold'>Medications: </div>Administer medications such as epinephrine, vasopressin, amiodarone, or lidocaine as per the specific cardiac arrest algorithm.</li>
                                    </ol>

                                    <li className='font-bold'>Acute Coronary Syndromes:</li>
                                    <div className='pl-0 mt-2 space-y-1'>ACLS provides guidelines for the management of acute coronary syndromes, including ST-segment elevation myocardial infarction (STEMI) and non-ST-segment elevation myocardial infarction (NSTEMI). Key interventions include:</div>
                                    <ol class="pl-2 md:pl-5 mt-2 space-y-1 list-[lower-latin] list-outside">
                                        <li><div class='font-bold'>Early Recognition: </div>Promptly identify and evaluate patients with symptoms suggestive of acute coronary syndromes.</li>
                                        <li><div class='font-bold'>Reperfusion Therapy: </div>Administer fibrinolytic therapy or perform percutaneous coronary intervention (PCI) as appropriate for STEMI patients within the recommended timeframes.</li>
                                        <li><div class='font-bold'>Medications: </div>Initiate antiplatelet therapy, anticoagulation, and other appropriate medications to manage acute coronary syndromes.</li>
                                        <li><div class='font-bold'>Continuous ECG Monitoring: </div>Monitor patients for arrhythmias and ischemic changes.</li>
                                    </ol>

                                    <li className='font-bold'>Stroke Management:</li>
                                    <div className='pl-0 mt-2 space-y-1'>ACLS guidelines provide specific recommendations for managing patients with suspected stroke. Key considerations include:</div>
                                    <ol class="pl-2 md:pl-5 mt-2 space-y-1 list-[lower-latin] list-outside">
                                        <li><div class='font-bold'>Rapid Assessment: </div>Perform a focused neurological assessment to identify stroke symptoms and assess the time of symptom onset.</li>
                                        <li><div class='font-bold'>Imaging and Evaluation: </div>Conduct brain imaging to determine the type and cause of stroke. Assess eligibility for thrombolytic therapy.</li>
                                        <li><div class='font-bold'>Blood Pressure Management: </div>Maintain blood pressure within specific target ranges to ensure adequate cerebral perfusion.</li>
                                        <li><div class='font-bold'>Airway and Breathing Management: </div>Provide supplemental oxygen and ventilatory support as needed.</li>
                                    </ol>

                                    <li className='font-bold'>Post-Cardiac Arrest Care:</li>
                                    <div className='pl-0 mt-2 space-y-1'>ACLS emphasizes the importance of post-cardiac arrest care to optimize neurological recovery and overall patient outcomes. Key components include:</div>
                                    <ol class="pl-2 md:pl-5 mt-2 space-y-1 list-[lower-latin] list-outside">
                                        <li><div class='font-bold'>Targeted Temperature Management: </div>Consider induced hypothermia or targeted temperature management for eligible patients.</li>
                                        <li><div class='font-bold'>Hemodynamic Optimization: Maintain </div>adequate oxygenation, ventilation, and blood pressure. Use vasoactive medications as necessary.</li>
                                        <li><div class='font-bold'>Coronary Reperfusion: </div>Evaluate the need for emergent coronary angiography and PCI in patients with suspected or confirmed acute coronary syndromes.</li>
                                        <li><div class='font-bold'>Neurological Prognostication: </div>Use validated tools to assess neurological function and determine appropriate care goals.</li>
                                    </ol>


                                </ul>
                            </div>


                        </div>


                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Conclusion:</div>

                        <p class="mb-3 text-white dark:text-white px-4 indent-3 my-4 text-start text-sm md:text-lg">ACLS plays a critical role in managing life-threatening cardiac emergencies, acute coronary syndromes, and strokes. The latest guidelines provided by the American Heart Association (AHA) outline key interventions, including high-quality CPR, defibrillation, medication administration, and targeted therapies for optimal patient outcomes. By staying updated with ACLS guidelines and receiving proper training, healthcare professionals can effectively respond to critical care situations, ultimately improving survival rates and enhancing the quality of care provided to patients in need.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div >


        </CalculatorWorking >

    );
};
