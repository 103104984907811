import { Link } from "react-router-dom"
import RouterNavButton from "../../../features/button/routernavbutton"
import { DefaultBottomDialogue } from "../../../features"

export const LifeSavingProceduresPage = () => {
    return (
        <div className="pt-2">
            <div className="container px-3 mx-auto flex flex-wrap flex-col items-center">
                <h1 className="text-3xl md:text-3xl text-center font-bold my-5">Life Saving Procedures</h1>
                {/* <!--Left Col--> */}
                <div className="max-w-fit">

                    <div className="border px-2 w-[350px] md:w-[450px] md:min-h-[330px] rounded-2xl bg-white bg-opacity-20 container flex flex-wrap flex-col items-center">
                        <div className="grid grid-cols-1 justify-center items-center mb-10 gap-x-3 min-w-fit">


                            <Link to='cardiacarrest'>
                                <RouterNavButton>
                                    Cardiac Arrest
                                </RouterNavButton>
                            </Link>

                            <Link to='bls'>
                                <RouterNavButton>
                                    Basic Life Support <div>(BLS)</div>
                                </RouterNavButton>
                            </Link>

                            <Link to='acls'>
                                <RouterNavButton>
                                    <div className="mx-3">Advanced Cardiac Life Support </div><div>(ACLS)</div>
                                </RouterNavButton>
                            </Link>

                            <Link to='emergencyett'>
                                <RouterNavButton>
                                    <div className="mx-3">Emergency Endotracheal Intubation</div><div>(ETT)</div>
                                </RouterNavButton>
                            </Link>

                        </div>

                    </div>
                    <DefaultBottomDialogue />
                </div>
            </div>
        </div>
    )
}