import React from 'react';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';
import SaveAsPDF from '../../../../features/button/save-as-pdf';



export const EssentialSkillsSurgery = () => {




    return (

        <CalculatorWorking>


            <div className="container mb-20  mx-auto px-2 md:px-4 py-0">
                {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
                <h1 class="place-content-center block md:inline text-2xl text-start md:text-center md:text-4xl font-extrabold dark:text-white">Essential Skills for New Doctors:<div className='text-start text-sm md:text-2xl'>Equipping Fresh Graduates for Success in the Surgical Ward</div></h1>
                <div className='grid-cols-1 md:flex grid place-items-center place-content-center md:place-items-start px-0 md:px-8  my-2 md:my-6'>


                    <div className='flex gap-x-2 '>
                        {/* //for modals */}

                        <SaveAsPDF />


                    </div>




                </div>


                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>


                        <div>
                            <div>
                                <p class="mb-10 text-white dark:text-white px-2 indent-6 my-4 text-start text-sm md:text-lg">Navigating a medical ward as a fresh medical graduate can be both exciting and challenging. It marks the beginning of a journey where theoretical knowledge meets real-life patient care. To work efficiently in a medical ward, fresh medical graduates need to acquire a range of essential skills. These skills encompass not only medical knowledge but also interpersonal communication, practical procedures, time management, and professional conduct. In this guide, we will explore the key skills that fresh medical graduates should focus on to thrive in a medical ward and provide optimal care to their patients.</p>

                                <ul class="space-y-4 pl-8 text-white list-disc list-outside px-4 md:px-8 text-sm  md:text-lg">
                                    <li> <div class='font-bold'>Patient Assessment: </div>Learning how to perform a comprehensive preoperative assessment, including relevant history, physical examination, and diagnostic investigations specific to surgical patients.</li>
                                    <li> <div class='font-bold'>Surgical Techniques and Procedures: </div>Gaining proficiency in common surgical techniques and procedures, such as wound closure, suturing, wound dressing, and basic surgical skills.</li>
                                    <li> <div class='font-bold'>Postoperative Care: </div>Understanding the principles of postoperative care, including monitoring vital signs, managing pain, wound care, and recognizing and managing potential complications.</li>
                                    <li> <div class='font-bold'>Intraoperative Assistance: </div>Assisting the surgical team during procedures, including providing appropriate instruments and supplies, maintaining a sterile field, and anticipating the needs of the surgeon.</li>
                                    <li> <div class='font-bold'>Operating Room Etiquette: </div>Familiarizing oneself with the protocols and etiquette of the operating room, including maintaining a sterile environment, following surgical safety measures, and effective communication with the surgical team.</li>
                                    <li> <div class='font-bold'>Instrumentation and Equipment: </div>Learning about surgical instruments and equipment commonly used in the operating room, their names, functions, and proper handling.</li>
                                    <li> <div class='font-bold'>Interdisciplinary Collaboration: </div>Collaborating effectively with other members of the surgical team, including surgeons, anesthesiologists, nurses, and technicians, to ensure coordinated patient care.</li>
                                    <li> <div class='font-bold'>Patient Safety: </div>Prioritizing patient safety by adhering to infection control practices, practicing correct surgical hand hygiene, and maintaining awareness of potential surgical complications.</li>
                                    <li> <div class='font-bold'>Documentation and Record-Keeping: </div>Maintaining accurate and comprehensive surgical documentation, including operative notes, consent forms, and postoperative progress notes.</li>
                                    <li><div class='font-bold'>Professional Development: </div>Engaging in continuous learning, attending surgical conferences, and staying updated with the latest advancements in surgical techniques and evidence-based practices.</li>
                                </ul>



                            </div>


                        </div>



                        <p class="mb-3 text-white dark:text-white px-4 indent-3 my-4 text-start text-sm md:text-lg">Stepping into the surgical ward as a fresh medical graduate can be both exciting and challenging. However, with the right knowledge and skills, you can navigate this dynamic environment with confidence. By focusing on patient care, developing surgical competencies, and embracing a collaborative mindset, you can contribute to the team and provide excellent surgical care. Remember, continuous learning, adaptability, and dedication are key to thriving in the surgical ward. Embrace the opportunities, face the challenges, and embark on a fulfilling journey as a competent and compassionate surgical professional.</p>


                    </div>
                </div>








                <DefaultBottomDialogue />
            </div >


        </CalculatorWorking >

    );
};
