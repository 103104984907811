
import { useScript } from "./useScript";
import './home.styles.css';
import { Link } from "react-router-dom";
import DefaultBottomDialogue from '../../features/text-dialogues/default-bottom/';
import { WhiteLogo, WhiteHeroLogo, BlackLogo } from "../../assets";
import './snapscroll.css'
import { SnapScrollButton } from "../../features";
import './loading.css'
import './transition-styles.css'
import './socials.css'
import ArrowButton from "../../features/button/arrowbutton";


export const Home = ({ children }) => {
	useScript('/script.js')

	return (
		<div className="leading-normal tracking-normal text-white bg-website.bg" style={{ fontFamily: "Source Sans Pro', sans-serif" }}>
			{/* <!--Nav--> */}
			<nav className="fixed w-full z-30 bg-website.bg top-0 text-white">
				<div className="relative w-auto container mx-auto flex place-items-center justify-self-auto mt-0 py-3">
					<div className="pl-4 flex items-center">
						<Link to='/'>

							<div className=" flex items-center text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl gap-x-2">

								<WhiteLogo />	FirstScrub
							</div>
						</Link>
					</div>





					<div className="block lg:hidden pr-2">
						<div id="nav-toggle" type="checkbox"
							className="hidden md:block relative flex items-center pr-2 text-white hover:text-gray-900 bg-none focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out z-30">

							<label htmlFor="my-drawer-3">
								<svg className="fill-current bg-none h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<title>Menu</title>
									<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
								</svg>
							</label>
						</div>




					</div>


					<div
						className="absolute mt-20 md:mt-0 top-0 w-full flex-grow lg:flex gap-x-4 md:gap-x-0	py-6 border md:border-none rounded-2xl lg:items-center pt-12 md:pt-0 lg:w-auto hidden lg:mt-0 bg-website.bg bg-opacity-100 lg:bg-transparent text-black lg:p-0 z-10"

						id="nav-content"
						style={{ right: '0px' }}

					>

						<div className="hidden md:block">
							<ul className="list-reset gap-x-2 lg:flex justify-end flex-1 items-center">
								<Link to='/'>
									<button
										className="mx-2 md:mx-fit lg:mx-0 hover:underline text-white font-bold rounded-full lg:mt-0 py-4 px-8 shadow-white transform transition hover:scale-105 duration-300 ease-in-out">
										Home
									</button></Link>



								<Link to='/interns'>
									<button
										className="mx-2 md:mx-fit lg:mx-0 hover:underline text-white font-bold rounded-full lg:mt-0 py-4 px-8 shadow-white transform transition hover:scale-105 duration-300 ease-in-out">
										For Interns
									</button></Link>



							</ul>
						</div>

						<div className="block md:hidden">
							<ul className="list-reset gap-x-2 lg:flex justify-end flex-1 items-center">
								<Link to='/'>
									<button
										className="mx-2 md:mx-fit lg:mx-0 hover:underline bg-white md:bg-white text-website.bg md:text-website.bg font-bold rounded-full lg:mt-0 py-4 px-8 shadow-white md:shadow-none opacity-100 md:opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
										Home
									</button></Link>



								<Link to='/interns'>
									<button
										className="mx-2 md:mx-fit lg:mx-0 hover:underline bg-white md:bg-white text-website.bg md:text-website.bg font-bold rounded-full lg:mt-0 py-4 px-8 shadow-white md:shadow-none opacity-100 md:opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
										For Interns
									</button></Link>



							</ul>
						</div>


					</div>

				</div>




				<hr className="border-b border-gray-100 opacity-25 my-0 py-0" />

			</nav>
			{/* <!--Hero--> */}
			<div className="mb-8 "></div>
			<div class="scroll-container">
				<div id='section1' class="scroll-area">

					<div className="pt-4 mb-4 md:mb-20 mt-10 md:mt-10" >

						<div className="mb-0 md:mb-40 "></div>


						<div className="container px-3 mx-auto flex flex-wrap flex-col items-center">
							{/* <!--Left Col--> */}
							<div class="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
								{/* <p class="uppercase tracking-loose text-xl w-full">Calculating every score manually?</p>
                        <h1 class="my-4 text-5xl   text-center font-bold leading-tight ">
                            Welcome to SymptoMath
                        </h1>
                        <p class="leading-normal text-xl mb-8">
                            Use our calculators which provide with accurate results with their interpretation
                        </p> */}
								{children}
								{/* <button>
                            <Link class="mx-auto lg:mx-0 hover:underline bg-white text-center text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"

                                to="/calculators">Calculators</Link>

                        </button> */}
							</div>
							{/* // Right Col */}

							{/* <div class="flex flex-col w-full md:w-2/4 justify-center items-center text-center md:text-left">
								<img src="https://i.ibb.co/YWHj5rW/7881778.png" alt="7881778" border="0" />
							</div> */}






							<div class="flex flex-col w-full md:w-70 justify-center items-center text-center md:text-left mb-28 md:mb-0">

								<div className="hidden md:block">
									<div className="pl-40 hero-content flex-col lg:flex-row">
										<div>
											<WhiteHeroLogo></WhiteHeroLogo>
										</div>
										<div>

											<p className="uppercase text-start text-2xl py-6">Are you a fresh medical graduate?</p>
											<h1 className="text-5xl font-bold">Welcome to FirstScrub!</h1>
											<p className="text-xl py-6">We will help you with all what you need when stepping in the wards for the first time</p>

										</div>
									</div>
								</div>

								<div className='display-block md:hidden'>
									<p class="uppercase tracking-loose text-sm md:text-xl w-full">Are you a fresh medical graduate?</p>

									<div className="flex mb-0 md:mb-10">
										<h1 class="my-4 text-3xl md:text-5xl text-center font-bold leading-tight ">
											Welcome to FirstScrub</h1>
									</div>
									<p class="leading-normal text-sm md:text-xl w-[300px] md:w-fit">

										We will help you with all what you need when stepping in the wards for the first time
									</p>

								</div>


							</div>
							<div className="scale-50 mt-4 md:mb-24 md:mt-0">
								{/* <HiChevronDoubleDown /> */}

							</div>
							<div className="flex place-content-center">
								<SnapScrollButton />
							</div>

						</div>

					</div >
				</div>


				<div id='section2' class="scroll-area">


					<section className="container mx-auto text-center py-6 my-8 mt-40 mb-40">
						<div className="hero-content flex-col lg:flex-row">
							<div class="block md:hidden mb-10">


							</div>
							<div class="loading1 hidden md:block">
								<svg width="256px" height="96px">
									{/* 0.157 23.954, 14 23.954, 21.843 48, 43 0, 50 24, 64 24 */}
									<polyline points="0.314 47.908, 28 47.908, 43.686 96, 86 0, 100 48, 128 48" id="back"></polyline>
									<polyline points="0.314 47.908, 28 47.908, 43.686 96, 86 0, 100 48, 128 48" id="front"></polyline>
								</svg>
							</div>
							<div>
								<div>
									<h2 className="text-center md:text-start text-xl md:text-5xl font-bold">For House Officers / Interns / FY1</h2>
									<h3 className="text-center md:text-start w-[300px] md:w-fit py-6 text-sm md:text-3xl">Start your journey of providing better care to the patients here.</h3>
								</div>
								<div className="flex place-content-center place-items-center">
									<Link to='/interns'>
										<ArrowButton>Click here to begin
										</ArrowButton>
									</Link>
								</div>
							</div>

						</div>
						<div className="mt-12 md:mt-24">
							<DefaultBottomDialogue />
							<div class="font-base text-vsm md:text-base mx-6">We are still under development & adding more to the website.</div>
							<div class="font-base text-vsm md:text-base mx-6">Email us at <span class='font-base' href='mailto: contact@firstscrub.com'>contact@firstscrub.com </span>for corrections and additions</div>
						</div>





					</section>

				</div>


				<div class="scroll-area">

					<section className="container mx-auto text-center py-6 mb-12">
						<h2 className="px-4 w-full my-2 text-4xl md:text-5xl font-bold leading-tight text-center text-white">
							Connect with us!
						</h2>
						<div className="w-full mb-4">
							<div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
						</div>
						<h3 className="my-4 px-8 text-2xl md:text-3xl leading-tight">
							Keep getting latest feature updates on Instagram
						</h3>
						<div className="flex place-content-center">
							<a href='http://www.instagram.com/first.scrub' target="_blank" rel="noreferrer">
								<button
									className="mx-auto flex gap-x-4 lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-10 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">

									<svg
										xmlns="http://www.w3.org/2000/svg"
										class="h-8 text-black w-8"
										viewBox="0 0 24 24">
										<path
											d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
									</svg>
									@first.scrub
								</button>
							</a>
						</div>
					</section>
				</div>
			</div>
			{/* <!--Footer--> */}
			<div className="relative -mt-12 lg:-mt-24">
				<svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg"
				>
					<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fillRule="nonzero">
							<path
								d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
								opacity="0.100000001"></path>
							<path
								d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
								opacity="0.100000001"></path>
							<path
								d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
								id="Path-4" opacity="0.200000003"></path>
						</g>
						<g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fillRule="nonzero">
							<path
								d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z">
							</path>
						</g>
					</g>
				</svg>
			</div>



			<div>
				<footer className="footer p-10 md:px-48 md:py-10 bg-white text-neutral-content">

					<div className="flex mb-6 text-black">
						<a className="flex flex-row items-baseline gap-x-2" href="/"> <BlackLogo />
							<a className="text-gray-800 no-underline hover:no-underline w-fit font-bold text-2xl lg:text-4xl" href="/">


								FirstScrub
							</a></a>
					</div>

					<div className="grid">
						<div className="footer-title grid text-gray-800">Social</div>
						<div className="flex">

							<ul class="wrapper">
								<li class="icon facebook">
									<a href='http://www.instagram.com/first.scrub' target="_blank" rel="noreferrer">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="h-8 appicon text-black pb-2 w-8"
											viewBox="0 0 24 24">
											<path
												d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
										</svg>
									</a>
									<span class="tooltip">Facebook</span>
									<span><i class="fab fa-facebook-f"></i></span>
								</li>

								<li class="icon instagram">
									<a href='http://www.instagram.com/first.scrub' target="_blank" rel="noreferrer">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="h-8 appicon text-black flex pb-2 w-8"
											viewBox="0 0 24 24">
											<path
												d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
										</svg>
									</a>
									<span class="tooltip">Instagram</span>
									<span><i class="fab fa-instagram"></i></span>
								</li>

								<li class="icon twitter">
									<a href='http://www.instagram.com/first.scrub' target="_blank" rel="noreferrer">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="h-8 appicon text-black pb-2 w-8"
											viewBox="0 0 24 24">
											<path
												d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
										</svg>
									</a>
									<span class="tooltip">Twitter</span>
									<span><i class="fab fa-twitter"></i></span>
								</li>

							</ul>


							<div className="grid grid-flow-col gap-x-4">




							</div>

						</div>
					</div>

					<div className="grid">
						<div className="footer-title grid text-gray-800">Contact</div>
						<div className="flex">
							<div className="grid grid-flow-col gap-4">
								<p class="text-gray-800 text-xl mb-2">contact@firstscrub.com</p>
							</div>
						</div>
					</div>

				</footer>
			</div >



			<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>

		</div >

	)


}