import React, { Component } from 'react'

class SaveAsPDF extends Component {
    render() {
        return (
            <div>
                <button
                    className="btn lg:mx-0 hover:underline bg-white text-center w-max hover:text-white  text-vsm md:text-xs text-gray-800 font-bold rounded-full lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                    onClick={() => window.print()}>Save as PDF</button>
            </div>
        )
    }
}

export default SaveAsPDF