
import React from 'react'
import './styles.css'

const ArrowButton = ({
    children
}) => {
    return (

        <button class="signupBtn">
            {children}
            <span class="arrow">
                <svg fill="#ffffff" viewBox="0 0 320 512" height="1em" xmlns="http://www.w3.org/2000/svg"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg>
            </span>
        </button>

    )
}

export default ArrowButton;

