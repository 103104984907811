import { Link } from "react-router-dom"
import RouterNavButton from "../../../features/button/routernavbutton"
import { DefaultBottomDialogue } from "../../../features"


export const AnesthesiaPage = () => {
    return (
        <div className="pt-2">
            <h3 className="text-center font-bold my-4 text-3xl mx-auto leading-tight mb-10">
                Anesthesia
            </h3>
            <div className="container w-fit grid grid-cols-1 md:grid-cols-2 gap-x-4 mx-auto flex flex-wrap flex-col items-center">

                {/* <!--Left Col--> */}
                {/* <div className="max-w-[280px] flex grid grid-cols-2"> */}

                <div className="px-4 mb-16">
                    <div className="border px-2 w-[350px] md:w-[450px] md:min-h-[300px] rounded-2xl bg-white bg-opacity-20 container flex flex-wrap flex-col items-center">

                        <h3 className="px-4 text-center font-bold my-4 text-2xl mx-auto leading-tight">
                            Peri-operative Care
                        </h3>
                        <Link to='preoperativeassessment'>
                            <RouterNavButton>
                                Pre-operative Assessment
                            </RouterNavButton>
                        </Link>

                        <Link to='generalanesthetics'>
                            <RouterNavButton>
                                Intra-operative Management
                            </RouterNavButton>
                        </Link>
                        <Link to='postoperativecare'>
                            <RouterNavButton>
                                Post-operative Management
                            </RouterNavButton>
                        </Link>
                    </div>
                </div>

                <div className="px-4 mb-16">
                    <div className="border px-2 w-[350px] md:w-[450px] md:min-h-[330px] rounded-2xl bg-white bg-opacity-20 container flex flex-wrap flex-col items-center">
                        <h3 className="text-white font-outline-4 font-outline-white text-center font-bold my-4 text-2xl mx-auto leading-tight">
                            Airway Management
                        </h3>
                        <Link to='basicairwaymanagement'>
                            <RouterNavButton>
                                Basic Airway Management
                            </RouterNavButton>
                        </Link>
                        <Link to='ett-anesthesia'>
                            <RouterNavButton>
                                Endotracheal Intubation
                            </RouterNavButton>
                        </Link>
                        <Link to='ett-troubleshooting'>
                            <RouterNavButton>
                                ETT Troubleshooting
                            </RouterNavButton>
                        </Link>


                    </div>
                </div>


                <div className="px-4 mb-16">

                    <div className="border px-2 w-[350px] md:w-[450px] md:min-h-[330px] rounded-2xl bg-white bg-opacity-20 container flex flex-wrap flex-col items-center">
                        <h3 className="text-center font-bold my-4 text-2xl mx-auto leading-tight">
                            Calculators
                        </h3>
                        <div className="w-[280px] max-w-[300px] md:max-w-fit md:w-fit md:mx-0 grid grid-cols-1 md:grid-cols-1 gap-x-2 md:gap-x-6 justify-center items-center">



                            <Link to='commonanesthetics'>
                                <RouterNavButton>
                                    Anaesthetic Agents:<div> Dose Calculator</div>
                                </RouterNavButton>
                            </Link>

                            <Link to='ivdriprates'>
                                <RouterNavButton>
                                    IV Drip Rate Calculator
                                </RouterNavButton>
                            </Link>
                            <Link to='maintainancefluid'>
                                <RouterNavButton>
                                    Maintainance Fluid Calculator
                                </RouterNavButton>
                            </Link>




                        </div>
                    </div>
                </div>











            </div>
            <DefaultBottomDialogue />
        </div>
    )
}


