import React from 'react';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';
import { FlashingText } from '../../../../features';
import { Link } from 'react-router-dom';


export const UrinarySampling = () => {


    return (

        <CalculatorWorking>



            {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
            <h1 class="place-content-center text-2xl text-center md:text-4xl mb-15 font-extrabold dark:text-white">Urinary Sampling</h1>

            <div className="container mb-20 mx-auto px-4 py-0">



                <div className="flex place-items-center place-content-center gap-x-3 md:gap-x-1 container grid-cols-none mx-auto py-0">



                    <div className='px-0 md:px-8 mt-2'>
                        <FlashingText>
                            <label htmlFor="my-modalpuncture1" className="btn lg:mx-0 hover:underline w-fit md:min-w-[150px] h-fit md:h-fit bg-white text-center md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Handling The Sampling Jar</label>
                        </FlashingText>
                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalpuncture1" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white ">
                                <label htmlFor="my-modalpuncture1" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold px-4 text-gray-800 text-base mb-2">
                                        HANDLING THE SAMPING JAR & PATIENT INSTRUCTIONS:</h3>



                                </div>


                                <div class='max-w-fit md:max-w-fit justify-center items-center mb-8'>
                                    <img class="scale-100 hover:scale-125 md:hover:scale-110 cursor-pointer object-cover ease-in duration-500" src="https://i.ibb.co/znYvJm2/Screenshot-2023-07-14-at-20-47-51.png" alt="circular" />
                                    <div className='text-sm text-gray-800 text-start italic font-bold'>Image from:<a class='text-sm text-center font-bold text-blue-400 my-0 flex justify-center hover:bg-blue-300 hover:text-white' href='https://www.england.nhs.uk/wp-content/uploads/2020/08/Taking_a_urine_sample.pdf' target="_blank" rel="noreferrer">NHS: Eastern Pathology Alliance</a></div>

                                </div>


                                <div className="modal-action">
                                    <label htmlFor="my-modalpuncture1" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>


                    </div>









                </div>





                <div className='w-100'>
                    <div className='grid grid-cols-1 place-items-start'>



                        <p class=" text-white dark:text-white px-4 indent-6 my-5 text-start text-sm md:text-lg">Obtaining urine samples for diagnostic purposes is a common procedure in healthcare settings. Various urinary sampling techniques are employed at the bedside to collect urine samples for analysis. These techniques range from non-invasive methods that require patient cooperation to invasive procedures performed by healthcare professionals. The choice of sampling technique depends on factors such as the patient's condition, the purpose of the sample, and clinical judgment. Understanding the different types of urinary sampling techniques available is important for healthcare providers to ensure accurate and reliable collection of urine samples. In this article, we will explore several types of urinary sampling techniques commonly used at the bedside, including both invasive and non-invasive methods, highlighting their purpose and considerations.</p>

                        <ul class="space-y-4 text-white list-disc list-outside pl-8 pr-4 md:px-16 text-sm md:text-lg">


                            <li className='font-bold'>Types of Urinary Sampling Techniques at the Bedside:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li><div className='font-bold'>Midstream Urine: </div>
                                    <div>
                                        <FlashingText>
                                            <label htmlFor="my-modalmsu" className="btn lg:mx-0 hover:underline w-fit md:min-w-[150px] h-fit md:h-[20px] bg-white text-center md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-1 px-4 my-1 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Details of procedure</label>
                                        </FlashingText>
                                        {/* Put this part before </body> tag */}
                                        <input type="checkbox" id="my-modalmsu" className="modal-toggle" />
                                        <div className="modal">
                                            <div className="modal-box relative bg-white ">
                                                <label htmlFor="my-modalmsu" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                                <div className='flex gap-x-20'>
                                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                                        Midstream Urine (MSU):</h3>



                                                </div>


                                                <iframe
                                                    className='w-fit h-fit md:w-[450px] md:h-[250px] flex place-content-center place-items-center mb-4'
                                                    src="https://www.youtube.com/embed/aV9JJSTU6Ak"
                                                    title="YouTube video player"
                                                    frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowfullscreen>

                                                </iframe>

                                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                                <ul class="space-y-4 text-gray-800 list-disc list-outside px-4 md:px-8 text-vsm  md:text-base">



                                                    <li className='font-bold'>What is the purpose of a MSU test?</li>
                                                    <ol class="pl-4 space-y-1 list-decimal list-outside">
                                                        <li><div className='font-bold text-blue-600 underline'>To confirm the diagnosis of a urine infection: </div>The usual symptoms of a urine infection are pain when you pass urine and passing urine frequently. However, symptoms are not always typical, particularly in the elderly, so a urine test may be needed.</li>
                                                        <li><div className='font-bold text-blue-600 underline'>To decide the best antibiotic to use: </div>Some germs (bacteria) are resistant to some antibiotics. If the test shows that bacteria are in the urine then the bacteria are tested against various antibiotics. This finds which antibiotics will kill the bacteria in the urine.</li>
                                                    </ol>

                                                    <li className='font-bold'>Patient instructions to collect a midstream specimen of urine (MSU).</li>
                                                    <ol class="pl-5 space-y-1 list-[lower-latin] list-outside">
                                                        <li>Before doing an MSU, wash your hands and ideally your genitals as well.</li>
                                                        <li>Do not open the sterile bottle until you are ready to take the sample.</li>
                                                        <li><span className='font-bold text-blue-600'>Women </span>- hold open the entrance to the vagina (your labia).</li>
                                                        <li><span className='font-bold text-blue-600'>Men </span>- pull back your foreskin (if applicable).</li>
                                                        <li>Pass some urine into the toilet. Then, without stopping the flow of urine, catch some urine in a clean (sterile) bottle. (The bottle should be provided by a doctor or nurse.) </li>
                                                        <li>Once you have enough urine in the bottle, finish off passing the rest of your urine into the toilet.</li>
                                                        <li>Avoid touching any part of your genitals with the bottle, as this will increase the risk of contamination. Put the cap back on the container. You do not need to fill the bottle to the top; a small amount will do. </li>
                                                    </ol>


                                                </ul>



                                                <div className="modal-action">
                                                    <label htmlFor="my-modalmsu" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    This is the most common method of urinary sampling. It involves collecting a sample of urine in the middle of the stream after a small amount of urine has been voided to flush out any contaminants from the urethra. It requires proper cleaning of the genital area before collecting the sample to minimize the risk of contamination.</li>
                                <li><div className='font-bold'>Clean Catch: </div>This method is similar to midstream urine collection but involves additional steps to ensure a cleaner sample. The genital area is thoroughly cleaned, and the initial part of the urine stream is discarded to minimize contamination. The sample is then collected midstream.</li>
                                <li><div className='font-bold'>Catheterized Urine: </div>
                                    <div>
                                        <FlashingText>
                                            <label htmlFor="my-modalcsu" className="btn lg:mx-0 hover:underline w-fit md:min-w-[150px] h-fit md:h-[20px] bg-white text-center md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-1 px-4 my-1 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Details of procedure</label>
                                        </FlashingText>
                                        {/* Put this part before </body> tag */}
                                        <input type="checkbox" id="my-modalcsu" className="modal-toggle" />
                                        <div className="modal">
                                            <div className="modal-box relative bg-white ">
                                                <label htmlFor="my-modalcsu" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                                <div className='flex gap-x-20'>
                                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                                        Collecting a Catheter Specimen of Urine (CSU):</h3>



                                                </div>


                                                <iframe
                                                    className='w-fit h-fit md:w-[450px] md:h-[250px] flex place-content-center place-items-center mb-4'
                                                    src="https://www.youtube.com/embed/x41zyB73ZHI"
                                                    title="YouTube video player"
                                                    frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowfullscreen>

                                                </iframe>

                                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                                <ul class="space-y-4 text-gray-800 list-disc list-outside px-4 md:px-8 text-vsm  md:text-base">


                                                    <ol class="space-y-1 list-outside">
                                                        <li>Taking a CSU must be a non-touch, aseptic technique and must be taken from the sampling port on the drainage bag.</li>
                                                    </ol>


                                                    <li className='font-bold'>Equipment:</li>
                                                    <ol class="pl-4 space-y-1 list-decimal list-outside">
                                                        <li>Single use plastic apron</li>
                                                        <li>Single use non-sterile gloves</li>
                                                        <li>Sterile 10ml syringe</li>
                                                        <li>2 x 70% alcohol swabs suitable for equipment use</li>
                                                        <li>Single patient use disposable clamp</li>
                                                        <li>Specimen containers</li>
                                                        <li>Laboratory request form</li>
                                                        <li>Detergent wipe for equipment</li>
                                                    </ol>

                                                    <li className='font-bold'>Procedure:</li>
                                                    <ol class="pl-5 space-y-1 list-[lower-latin] list-outside">
                                                        <li>Put on a disposable apron</li>
                                                        <li>Decontaminate hands</li>
                                                        <li>If there is no urine in the catheter bag tubing, clamp tubing 2-3 inches below the samplingport using a single patient use, scissor style clamp</li>
                                                        <li>Decontaminate hands</li>
                                                        <li>Apply gloves</li>
                                                        <li>Clean sampling port prior to attaching the syringe with a 70% alcohol wipe suitable forequipment use for 30 seconds contact time then allow to dry.</li>
                                                        <li>Attach a sterile 10ml syringe to the sampling port to aspirate urine</li>
                                                        <li>Clean the sampling port with 70% alcohol wipe after removal of the syringe</li>
                                                        <li>Place urine in yellow top specimen container in view of using the green topped vacutainerbefore sending to laboratory</li>
                                                        <li>Remove clamp from catheter bag tubing and decontaminate with a detergent wipe</li>
                                                        <li>Remove gloves and apron</li>
                                                        <li>Wash hands with soap and water</li>
                                                        <li>Label specimen</li>
                                                        <li>Complete laboratory request form stating clinical symptoms</li>
                                                    </ol>


                                                </ul>



                                                <div className="modal-action">
                                                    <label htmlFor="my-modalcsu" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    Catheterization is an invasive technique where a <Link to='../urinarycatheterization/foleyscatheter' class='text-sm md:text-lg text-blue-300 underline'>urinary catheter is inserted into the bladder</Link> to collect a urine sample. It is commonly used when a clean catch sample is difficult or not feasible, such as in patients with urinary retention or those unable to provide a urine sample voluntarily.</li>
                                <li><div className='font-bold'>Urine Collection Bag: </div>A urine collection bag is a non-invasive method used in certain situations, such as in infants, bedridden patients, or those with limited mobility. The bag is attached to the perineal area to collect urine over a specific period.</li>
                                <li><div className='font-bold'>Suprapubic Aspiration: </div>
                                    <div>
                                        <FlashingText>
                                            <label htmlFor="my-modalpuncture2" className="btn lg:mx-0 hover:underline w-fit md:min-w-[150px] h-fit md:h-[20px] bg-white text-center md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-1 px-4 my-1 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Details of procedure</label>
                                        </FlashingText>
                                        {/* Put this part before </body> tag */}
                                        <input type="checkbox" id="my-modalpuncture2" className="modal-toggle" />
                                        <div className="modal">
                                            <div className="modal-box relative bg-white ">
                                                <label htmlFor="my-modalpuncture2" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                                <div className='flex gap-x-20'>
                                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                                        Suprapubic Aspiration of Urine</h3>



                                                </div>


                                                <iframe
                                                    className='w-fit h-fit md:w-[450px] md:h-[250px] flex place-content-center place-items-center mb-4'
                                                    src="https://www.youtube.com/embed/Q0B5UAWIdYU"
                                                    title="YouTube video player"
                                                    frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowfullscreen>

                                                </iframe>

                                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                                <ul class="space-y-4 text-gray-800 list-decimal list-outside px-4 md:px-8 text-vsm  md:text-base">



                                                    <li className='font-bold'>Equipment:</li>
                                                    <ol class=" space-y-1  list-outside">
                                                        <li>The equipment required for bladder puncture includes sterile gloves, a sterile drape, an appropriate-sized needle (usually 16-18G), a syringe for aspirating urine, antiseptic solution for skin preparation, local anesthetic if necessary, a urine collection container, and adhesive dressings for wound closure after the procedure.</li>
                                                    </ol>

                                                    <li className='font-bold'>Procedure Steps:</li>
                                                    <ol class="pl-5 space-y-1 list-[lower-latin] list-outside">
                                                        <li>Prepare the patient: Explain the procedure to the patient, obtain informed consent, and position the patient in a supine position.</li>
                                                        <li>Preparation and draping: Wear sterile gloves and prepare the puncture site using an antiseptic solution. Place a sterile drape to create a sterile field around the puncture site.</li>
                                                        <li>Anesthesia (if necessary): Administer a local anesthetic</li>
                                                        <li>Needle insertion: Insert a sterile needle into the bladder through the previously prepared puncture site. Advance the needle slowly while monitoring the patient's response and urine flow.</li>
                                                        <li>Aspiration and urine collection: Attach a syringe to the needle and gently aspirate urine from the bladder. Collect the urine sample in a sterile container if needed for diagnostic purposes.</li>
                                                        <li>Needle removal and wound closure: Once the bladder is adequately drained, carefully remove the needle. Apply pressure and use adhesive dressings to close the puncture site and prevent leakage.</li>
                                                        <li>Post-procedure care: Provide appropriate post-procedure care, including monitoring for any complications, ensuring patient comfort, and arranging for follow-up care as necessary.</li>
                                                    </ol>


                                                </ul>



                                                <div className="modal-action">
                                                    <label htmlFor="my-modalpuncture2" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    This is an invasive method where a needle is inserted directly into the bladder through the abdominal wall to collect a urine sample. It is typically performed under sterile conditions and may be used when other sampling methods are not feasible or when a sterile sample is required.</li>
                                <li><div className='font-bold'>Urinary Catheter Collection: </div>
                                    <div>
                                        <FlashingText>
                                            <label htmlFor="my-modalcsu" className="btn lg:mx-0 hover:underline w-fit md:min-w-[150px] h-fit md:h-[20px] bg-white text-center md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-1 px-4 my-1 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Details of procedure</label>
                                        </FlashingText>
                                        {/* Put this part before </body> tag */}
                                        <input type="checkbox" id="my-modalcsu" className="modal-toggle" />
                                        <div className="modal">
                                            <div className="modal-box relative bg-white ">
                                                <label htmlFor="my-modalcsu" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                                <div className='flex gap-x-20'>
                                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                                        Collecting a Catheter Specimen of Urine (CSU):</h3>



                                                </div>


                                                <iframe
                                                    className='w-fit h-fit md:w-[450px] md:h-[250px] flex place-content-center place-items-center mb-4'
                                                    src="https://www.youtube.com/embed/x41zyB73ZHI"
                                                    title="YouTube video player"
                                                    frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowfullscreen>

                                                </iframe>

                                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                                <ul class="space-y-4 text-gray-800 list-disc list-outside px-4 md:px-8 text-vsm  md:text-base">


                                                    <ol class="space-y-1 list-outside">
                                                        <li>Taking a CSU must be a non-touch, aseptic technique and must be taken from the sampling port on the drainage bag.</li>
                                                    </ol>


                                                    <li className='font-bold'>Equipment:</li>
                                                    <ol class="pl-4 space-y-1 list-decimal list-outside">
                                                        <li>Single use plastic apron</li>
                                                        <li>Single use non-sterile gloves</li>
                                                        <li>Sterile 10ml syringe</li>
                                                        <li>2 x 70% alcohol swabs suitable for equipment use</li>
                                                        <li>Single patient use disposable clamp</li>
                                                        <li>Specimen containers</li>
                                                        <li>Laboratory request form</li>
                                                        <li>Detergent wipe for equipment</li>
                                                    </ol>

                                                    <li className='font-bold'>Procedure:</li>
                                                    <ol class="pl-5 space-y-1 list-[lower-latin] list-outside">
                                                        <li>Put on a disposable apron</li>
                                                        <li>Decontaminate hands</li>
                                                        <li>If there is no urine in the catheter bag tubing, clamp tubing 2-3 inches below the samplingport using a single patient use, scissor style clamp</li>
                                                        <li>Decontaminate hands</li>
                                                        <li>Apply gloves</li>
                                                        <li>Clean sampling port prior to attaching the syringe with a 70% alcohol wipe suitable forequipment use for 30 seconds contact time then allow to dry.</li>
                                                        <li>Attach a sterile 10ml syringe to the sampling port to aspirate urine</li>
                                                        <li>Clean the sampling port with 70% alcohol wipe after removal of the syringe</li>
                                                        <li>Place urine in yellow top specimen container in view of using the green topped vacutainerbefore sending to laboratory</li>
                                                        <li>Remove clamp from catheter bag tubing and decontaminate with a detergent wipe</li>
                                                        <li>Remove gloves and apron</li>
                                                        <li>Wash hands with soap and water</li>
                                                        <li>Label specimen</li>
                                                        <li>Complete laboratory request form stating clinical symptoms</li>
                                                    </ol>


                                                </ul>



                                                <div className="modal-action">
                                                    <label htmlFor="my-modalcsu" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    In patients with indwelling urinary catheters, a urine sample can be collected directly from the catheter tubing. This method is convenient and requires minimal patient cooperation.</li>
                                <li><div className='font-bold'>Pediatric Bag Collection: </div>Specifically designed urine collection bags are used in infants and young children. The bag is attached to the perineal area and collects urine during voiding.</li>
                                <li><div className='font-bold'>Urethral Swab: </div>In certain cases, a swab may be used to collect a urine sample by gently swabbing the urethral opening. This technique is employed when other methods are not suitable or available.</li>
                            </ol>
                        </ul>



                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Note:</div>

                        <p class="mb-3 text-white dark:text-white px-4 my-4 text-start text-sm md:text-lg">It is important to note that the choice of urinary sampling technique depends on factors such as the patient's condition, the purpose of the sample, and clinical judgment. Each technique has its own considerations in terms of sterility, invasiveness, and patient comfort. Proper technique selection and adherence to sterile practices are crucial to ensure accurate and reliable urine sample collection.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div>




        </CalculatorWorking >

    );
};
