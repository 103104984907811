import React, { useState } from 'react';
import { CalculatorWorking } from '../../calculatorworking';
import MyModal from '../../../../features/modal';
import { HiOutlineChevronDoubleRight, HiOutlineChevronDoubleLeft } from "react-icons/hi";


export const CommonAnesthetics = () => {
    const [weight, setWeight] = useState('');
    const dosePerKgs = {
        drug1: { max: 2, unit: 'mg' },
        propofol: { min: 2, max: 2.5, unit: 'mg' },
        ketamineanesthesia: { max: 2, unit: 'mg' },
        ketamineanalgesia: { max: 0.2, unit: 'mg' },
        suxamethonium: { min: 1, max: 1.5, unit: 'mg' },
        atracuriuminitial: { max: 0.5, unit: 'mg' },
        atracuriummaintain: { min: 0.08, max: 0.1, unit: 'mg' },
        midazolam6m: { min: 0.05, max: 0.1, unit: 'mg' },
        midazolam6y: { min: 0.025, max: 0.05, unit: 'mg' },
        diazepam: { min: 0.2, max: 0.5, unit: 'mg' },
        atropine: { min: 0.01, max: 0.02, unit: 'mg' },
        neostigmine: { min: 0.04, max: 0.08, unit: 'mg' },
        phenylephrine: { min: 1, max: 2, unit: 'mcg' },
        adrenaline: { min: 1, max: 2, unit: 'mcg' },
        bupivacaine: { max: 0.25, unit: 'mg' },
        nalbuphine: { min: 0.1, max: 0.3, unit: 'mg' },
        tramadolanalgesia: { max: 2, unit: 'mg' },
        tramadolshivering: { min: 0.2, max: 0.5, unit: 'mg' },
        ketorolac: { min: 0.5, max: 0.75, unit: 'mg' },
        paracetamol: { min: 10, max: 15, unit: 'mg' },
        metoprolol: { min: 2, max: 2.5, unit: 'mg' },

    };

    const calculateDoses = () => {
        const doses = {};

        // Calculate the dose for each drug based on the weight.
        Object.keys(dosePerKgs).forEach((drug) => {
            const dosePerKg = dosePerKgs[drug];
            let calculatedMinDose = 0;
            let calculatedMaxDose = 0;
            if (typeof dosePerKg.dose === 'number') {
                // If the drug has a single dose, calculate the single dose.
                calculatedMinDose = weight * dosePerKg.dose;
                calculatedMaxDose = calculatedMinDose;
            } else {
                // If the drug has a dose range, calculate the minimum and maximum doses.
                calculatedMinDose = weight * dosePerKg.min;
                calculatedMaxDose = weight * dosePerKg.max;
            }

            if (drug === 'paracetamol') {
                doses[drug] = { min: `${calculatedMinDose.toFixed(0)}`, max: `${calculatedMaxDose.toFixed(0)} ${dosePerKg.unit}` };
            } else { doses[drug] = { min: `${calculatedMinDose.toPrecision(3)}`, max: `${calculatedMaxDose.toPrecision(3)} ${dosePerKg.unit}` }; }
        });

        // Update the state with the calculated doses.
        setDoses(doses);
    };

    const [doses, setDoses] = useState({
        drug1: { min: '0', max: '0 mg' },
        propofol: { min: '' },
        ketamineanesthesia: { max: '' },
        ketamineanalgesia: { max: '' },
        suxamethonium: { min: '' },
        atracuriuminitial: { max: '' },
        atracuriummaintain: { min: '' },
        midazolam6m: { min: '' },
        midazolam6y: { min: '' },
        diazepam: { min: '' },
        atropine: { min: '' },
        neostigmine: { min: '' },
        phenylephrine: { min: '' },
        adrenaline: { min: '' },
        bupivacaine: { max: '' },
        nalbuphine: { min: '' },
        tramadolanalgesia: { min: '' },
        tramadolshivering: { min: '' },
        ketorolac: { max: '' },
        paracetamol: { min: '' },
        metoprolol: { min: '' },

    });


    let [isOpen, setIsOpen] = useState(false)
    function openModal() {
        console.log('here')
        setIsOpen(true)
    }





    return (

        <CalculatorWorking>

            <MyModal isOpen={isOpen} setIsOpen={setIsOpen}>
                <div
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                >
                    References
                </div>


                <div className="mt-2">
                    <p className="text-sm text-start text-gray-500">
                        1. Commonly used intravenous agents for pediatric procedural sedation. UpToDate. https://www.uptodate.com/contents/image?imageKey=EM%2F83512. Accessed April 20, 2023.
                    </p>
                </div>

            </MyModal>

            <div className="container  mx-auto px-4 py-6">

                <h1 className="text-3xl text-center font-bold mb-2">IV Anesthetics Dose Calculator</h1>
                <div className='w-90'>
                    <div className='grid grid-cols-1 mb-4 place-items-center'>
                        <p class="px-4 text-sm font-bold  text-white text-start mb-2 dark:text-white">Enter weight below:</p>

                        <input
                            class="text-center hover:bg-table-hover hover:text-white dark:text-white bg-white mb-4 border-gray-300 rounded"
                            type="number"
                            placeholder="in Kgs"
                            value={weight}
                            onChange={(event) => setWeight(event.target.value)}
                            required
                        />

                        <button id="navAction"
                            onClick={calculateDoses}
                            className="w-fit grid grid-cols-1  mb-2 lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                            Calculate Doses
                        </button>
                    </div>
                </div>


                <div className='border rounded-2xl animate-bounce flex gap-x-4 place-content-center md:hidden place-items-center'>
                    <HiOutlineChevronDoubleLeft />
                    <div classname='text-white text-flash text-center text-xl'>

                        Scroll the table to see more

                    </div>
                    <HiOutlineChevronDoubleRight />
                </div>


                <form>
                    <section class="container px-4 mx-auto">

                        <div class="flex flex-col mt-6">
                            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                                    <table class="min-w-fit divide-y  dark:divide-none">
                                        <thead class=" dark:bg-gray-800">

                                        </thead>
                                        <tbody class="divide-y  dark:divide-gray-900 dark:bg-gray-900">




                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Drug/Dose</th>
                                                        <th>Calculated Dose</th>
                                                        <th>Dose</th>
                                                        <th>Formulations Available</th>
                                                        <th>Dilution in D/W</th>
                                                        <th>Onset</th>
                                                        <th>Duration</th>

                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Propofol </div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.propofol.min}-{doses.propofol.max}</div>

                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">2 - 2.5 mg/kg/day</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">200mg/20ml</p>
                                                                    <p class="px-4 text-xs text-center  text-white dark:text-white">(10mg/ml)</p>
                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">

                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">30 - 40 sec</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">8 - 10 min</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Ketamine</div>


                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white"> Anesthesia:</p>
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.ketamineanesthesia.max}</div>
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white"> Analgesia:</p>
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.ketamineanalgesia.max}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white"> Anesthesia: 2mg/kg</p>
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white"> Analgesia: 0.2mg/kg</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">500mg/10ml</p>
                                                                    <p class="px-4 text-xs text-center  text-white dark:text-white">(50mg/ml)</p>
                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">10ml</p>
                                                                    <p class="px-4 text-xs text-center  text-white dark:text-white">(5mg/ml)</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">30 sec</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">5 - 10 min</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Suxamethonium</div>


                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.suxamethonium.min}-{doses.suxamethonium.max}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white"> 1 - 1.5 mg/kg</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">100mg/2ml</p>
                                                                </div>

                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">5ml</p>
                                                                    <p class="px-4 text-xs text-center  text-white dark:text-white">(20mg/ml)</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">30 - 60 sec</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">4 - 6 min</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Atracurium</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">Initial Dose:</p>
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.atracuriuminitial.max}</div>
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">Maintainance:</p>
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.atracuriummaintain.min}-{doses.atracuriummaintain.max}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">Initial Dose: 0.5 mg/kg</p>
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">Maintainance:</p>
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">0.08 - 0.1 mg/kg</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">30mg/3ml</p>
                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white"></p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">2 - 3 min</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">20 - 35 min</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Midazolam</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">6 months - 5 years</p>
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.midazolam6m.min}-{doses.midazolam6m.max}</div>
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">6 years - 12 years</p>
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.midazolam6y.min}-{doses.midazolam6y.max}</div>
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">&gt;12 years</p>
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">1 - 2 mg</div>
                                                                    <p class="px-4 text-xs  text-white text-center mb-0 font-bold dark:text-white">Max: 2mg for</p>
                                                                    <p class="px-4 text-xs  text-white text-center font-bold dark:text-white">all age groups</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white font-bold text-start dark:text-white">6 months to 5 years:</p>
                                                                    <div><p class="px-4 text-xs  text-white inline-block text-start mb-4 dark:text-white">(0.05 - 0.1mg/kg)</p><span classname='text-blue-500 text-xs float-right hover:text-blue-200' onClick={() => openModal()}>[1]</span></div>
                                                                    <p class="px-4 text-xs  text-white font-bold text-start dark:text-white">6 to 12 years:</p>
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">(0.025 - 0.05mg/kg) [1]</p>
                                                                    <p class="px-4 text-xs  text-white font-bold text-start dark:text-white">Over 12 years:</p>
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">(1 to 2 mg IV) [1]</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">5mg/5ml</p>
                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white"></p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">5 - 20 min</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">1 - 6 hours</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Diazepam</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.diazepam.min}-{doses.diazepam.max}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">0.2 - 0.5 mg/kg</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">5mg/ml</p>

                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">5cc</p>
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">(1mg/ml)</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">3 - 5 min</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">30 - 90 minutes</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Atropine</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.atropine.min}-{doses.atropine.max}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">0.01 - 0.02 mg/kg</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">1mg/ml</p>

                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">5cc</p>
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">(0.2mg/ml)</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">1 - 2 min</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">4 hours</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Neostigmine</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.neostigmine.min}-{doses.neostigmine.max}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">0.04 - 0.08 mg/kg</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">2.5mg/ml</p>

                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">5cc</p>
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">(0.5mg/ml)</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">1 - 20 min</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">1 - 2 hours</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Phenylephrine</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.phenylephrine.min}-{doses.phenylephrine.max}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">1 - 2 mcg/kg</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">10mg/ml</p>

                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">20cc</p>
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">(0.5mg/ml) OR</p>
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">(500mcg/ml)</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">10 - 15 min</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">15  mins</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Adrenaline</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.adrenaline.min}-{doses.adrenaline.max}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">1 - 2 mcg/kg</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">1mg/ml</p>

                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">20cc</p>
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">(50mcg/ml)</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">5 - 10 min</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">4 hours</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Bupivacaine</div>
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">(Spinal)</p>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.bupivacaine.max}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">0.25 mg/kg</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">15mg/2ml</p>

                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">already 0.75%</p>
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">in D/W</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">1 - 17 min</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">2 - 9 hours</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Nalbuphine</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.nalbuphine.min}-{doses.nalbuphine.max}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">0.1 - 0.3 mg/kg</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">10mg/ml</p>

                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">5cc</p>
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">(0.2mg/ml)</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">2 - 3 min</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">3 - 6 hours</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Tramadol</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">Analgesia:</p>
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.tramadolanalgesia.max}</div>
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">Shivering:</p>
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.tramadolshivering.min}-{doses.tramadolshivering.max}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">Analgesia: 2mg/kg</p>
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">Shivering: 0.2 - 0.5mg/kg</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">100mg/2ml</p>

                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">5cc</p>
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">(20mg/ml)</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">2 - 10 min</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">6 - 8 hours</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Ketorolac</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.ketorolac.min}-{doses.ketorolac.max}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">0.5mg/kg</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">30mg/ml</p>

                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">5cc</p>
                                                                    <p class="px-4 text-xs text-center font-bold  text-white dark:text-white">(6mg/ml)</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">1 - 5 min</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">4 - 6 hours</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Paracetamol</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.paracetamol.min}-{doses.paracetamol.max}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">10 - 15mg/kg</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">1g/100ml</p>
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">10g/ml</p>

                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">

                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">5 - 10min</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">4 - 6 hours</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>


                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Metoprolol</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.metoprolol.min}-{doses.metoprolol.max}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">2 - 2.5mg/kg in 2 mins,</p>
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">can be repeated after 5 mins.</p>
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">Not more than 15mg.</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">5mg/5ml</p>
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">(1mg/ml)</p>

                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">Used for</p>
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">HTN during </p>
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">anesthesia</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">IV: 4 - 5min</p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white">5 - 8 hours</p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-white hover:bg-table-hover border-gray-300 rounded">
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Isosorbide</div>
                                                                    <div class="px-4 text-xl md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Trinitrate</div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="dose">
                                                                    <div class="px-4 text-xl md:text-2xl text-center hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">{doses.metoprolol.min}-{doses.metoprolol.max}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="drug">
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">2 - 10ml/hr, OR</p>
                                                                    <p class="px-4 text-xs  text-white text-start dark:text-white">20 - 30 micro-drops/min</p>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Formulations">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">10mg/10ml</p>
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">(1mg/ml)</p>

                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Dilutions in D/W">
                                                                    <p class="px-4 text-xs  text-white font-bold text-center dark:text-white">100ml N/S</p>

                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Onset">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white"></p>



                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-sm font-medium whitespace-nowrap">
                                                            <div class="bg-none aria-selected:bg-table-hover">
                                                                <div class="text-white hover:bg-table-hover border-gray-300 rounded" name="Duration">
                                                                    <p class="px-4 text-xs  text-white text-center dark:text-white"></p>



                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>



                                                </tbody>

                                            </table>






                                        </tbody>




                                    </table>


                                </div>
                            </div>
                        </div>

                    </section>






                </form>
                <div class='flex place-items-center place-content-center my-10'>
                    <button
                        onClick={() => openModal()}
                        className="w-fit grid grid-cols-1  mb-2 lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                        Calculate Doses
                    </button>
                </div>
                <div className='text-center text-xl'>developed by alostmedic.com</div>

            </div>
        </CalculatorWorking>
    );
};




