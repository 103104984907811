import React from 'react';
import './styles.css'

const WhiteHeroLogo = () => {



    return (
        <svg xmlns="http://www.w3.org/2000/svg" href='/' class="animated-svg" x="250px" y="250px" width="56%" height="140%" viewBox="0 0 5000 3819 " enable-background="new 0 0 0 0" >
            <path fill="#ffffff" opacity="1.000000" stroke="none"
                d="
M3852.000000,3824.000000 
	C2945.225830,3824.000000 2038.451782,3824.000000 1129.993164,3822.282227 
	C1129.137085,3811.993896 1130.467041,3803.438232 1130.711670,3794.851074 
	C1132.209473,3742.265381 1133.443237,3689.672119 1134.800537,3637.082275 
	C1136.106445,3586.493896 1137.480469,3535.907715 1138.778931,3485.319092 
	C1140.145874,3432.063232 1141.440674,3378.805420 1142.799927,3325.549561 
	C1144.108276,3274.292969 1145.478149,3223.038086 1146.785645,3171.781494 
	C1148.143921,3118.526855 1149.242798,3065.264648 1150.856567,3012.017822 
	C1152.408447,2960.816162 1151.815552,2909.591553 1155.260742,2858.389648 
	C1158.208496,2814.582031 1157.747803,2770.545410 1158.797485,2726.610107 
	C1159.577026,2693.983154 1160.319458,2661.355225 1161.155396,2628.729492 
	C1161.939941,2598.114014 1162.880249,2567.502441 1163.654053,2536.886719 
	C1164.797974,2491.620605 1165.800781,2446.350830 1166.964478,2401.085449 
	C1168.179565,2353.825684 1169.622925,2306.571777 1170.756714,2259.310059 
	C1171.491699,2228.670166 1171.683838,2198.017090 1172.416260,2167.376953 
	C1173.593872,2118.117432 1175.044312,2068.864502 1176.321289,2019.607300 
	C1176.735718,2003.623291 1176.745972,1987.626343 1177.330566,1971.649658 
	C1178.473755,1940.407349 1179.926025,1909.176270 1181.140381,1877.936401 
	C1181.259033,1874.881958 1180.611938,1871.797852 1180.144409,1866.875244 
	C1157.565796,1879.153931 1139.533081,1894.796753 1119.965332,1908.088745 
	C1100.702515,1921.173218 1082.113525,1935.247925 1063.183594,1948.824951 
	C1043.706665,1962.794067 1024.191040,1976.709473 1004.663330,1990.607422 
	C985.246277,2004.426514 965.792786,2018.194458 945.821167,2032.363892 
	C657.153870,1755.374512 369.435791,1479.296143 82.070107,1203.555786 
	C83.007484,1201.162964 83.156776,1199.646606 83.989883,1198.815674 
	C454.926605,828.837769 825.909729,458.906189 1196.912109,88.994057 
	C1197.341309,88.566093 1198.052002,88.299065 1198.667236,88.212494 
	C1199.303711,88.122910 1199.983887,88.345543 1200.956421,88.472160 
	C1225.284912,112.675438 1249.949341,136.900070 1274.252563,161.481674 
	C1297.192505,184.684296 1319.648193,208.364853 1342.511475,231.644150 
	C1362.114868,251.604248 1382.096680,271.192444 1401.721313,291.132050 
	C1421.339233,311.064911 1440.567017,331.382721 1460.242798,351.257446 
	C1489.750366,381.063263 1519.627197,410.503662 1549.105957,440.337677 
	C1572.507690,464.021454 1595.451538,488.156860 1618.779175,511.914581 
	C1637.437256,530.916626 1656.473755,549.547180 1675.147827,568.533752 
	C1695.695679,589.425354 1715.872559,610.682556 1736.479248,631.515198 
	C1765.974487,661.334045 1795.894409,690.734131 1825.296875,720.643250 
	C1854.230225,750.075073 1882.561523,780.098694 1911.493408,809.531921 
	C1940.898193,839.446106 1970.823853,868.847473 2000.322754,898.670044 
	C2041.526611,940.326172 2082.586426,982.125000 2123.633789,1023.935547 
	C2169.355957,1070.507935 2214.901367,1117.254639 2260.697021,1163.754517 
	C2306.969482,1210.739014 2353.531494,1257.438843 2399.825439,1304.402344 
	C2445.666016,1350.905762 2491.308105,1397.605225 2537.079346,1444.177246 
	C2580.056152,1487.905762 2623.059814,1531.608032 2666.103516,1575.271118 
	C2715.227783,1625.102417 2764.416504,1674.869873 2813.561768,1724.680420 
	C2836.473877,1747.902710 2859.187012,1771.324585 2882.325928,1794.318237 
	C2890.778809,1802.718018 2900.203125,1810.140015 2911.082520,1819.670898 
	C3031.122070,1810.700073 3154.426758,1801.485229 3280.188232,1792.086792 
	C3275.840576,1786.996948 3273.160889,1783.406738 3270.030518,1780.265503 
	C3240.860352,1750.992676 3211.665283,1721.743652 3182.374512,1692.591064 
	C3134.686279,1645.127075 3086.880371,1597.780884 3039.201172,1550.307617 
	C3005.693359,1516.944580 2972.379639,1483.386353 2938.856445,1450.039185 
	C2912.882812,1424.202026 2886.650146,1398.625000 2860.657227,1372.807007 
	C2834.202637,1346.530518 2807.969482,1320.031616 2781.511719,1293.758301 
	C2757.886230,1270.297607 2734.055664,1247.043945 2710.402832,1223.610596 
	C2707.304199,1220.540649 2704.725586,1216.945312 2701.385742,1212.974609 
	C2708.220947,1205.811768 2714.035156,1199.453613 2720.131348,1193.378296 
	C2740.880615,1172.700684 2761.822998,1152.215942 2782.514893,1131.481079 
	C2804.147461,1109.802979 2825.511475,1087.856567 2847.112061,1066.146362 
	C2868.238281,1044.912842 2889.546387,1023.860291 2910.719238,1002.672913 
	C2956.876221,956.484131 3003.034912,910.296936 3049.118408,864.034851 
	C3071.198730,841.868774 3093.042725,819.467407 3115.117188,797.295532 
	C3134.841797,777.483765 3154.774902,757.880127 3174.544922,738.113525 
	C3196.666016,715.996460 3218.670410,693.762939 3240.793213,671.647888 
	C3261.505859,650.942505 3282.329590,630.348633 3303.062012,609.663086 
	C3324.264648,588.508118 3345.420410,567.305603 3366.588379,546.115845 
	C3387.284668,525.398682 3407.966797,504.667603 3428.662842,483.950470 
	C3450.300293,462.290924 3471.923096,440.616669 3493.596680,418.993225 
	C3514.798340,397.840912 3536.083008,376.771637 3557.281494,355.616211 
	C3578.015381,334.924530 3598.703125,314.186340 3619.381104,293.438843 
	C3640.524658,272.223999 3661.594482,250.935562 3682.765381,229.748077 
	C3703.932861,208.564026 3725.230469,187.510117 3746.387207,166.315445 
	C3765.194336,147.474548 3783.770508,128.401779 3802.696045,109.681320 
	C3808.767822,103.675446 3815.975586,99.014854 3824.334961,94.566750 
	C4190.997070,444.385986 4557.072266,793.644836 4923.125000,1142.927490 
	C4923.563477,1143.346069 4923.736328,1144.102783 4923.896484,1144.736450 
	C4924.046387,1145.330444 4924.013672,1145.970459 4924.131836,1147.505493 
	C4912.787109,1159.666626 4901.192871,1172.455566 4889.208496,1184.868408 
	C4863.767578,1211.219849 4838.097168,1237.349365 4812.568848,1263.616699 
	C4766.634766,1310.880737 4720.618164,1358.065430 4674.860352,1405.500366 
	C4640.645996,1440.969116 4606.932129,1476.920410 4572.736328,1512.407227 
	C4505.705078,1581.970215 4438.526855,1651.391602 4371.344727,1720.808716 
	C4327.375488,1766.240845 4283.166016,1811.441772 4239.293457,1856.966797 
	C4194.928223,1903.002808 4150.891113,1949.354858 4106.587402,1995.450317 
	C4100.213379,2002.081299 4093.149170,2008.048462 4085.970703,2014.726318 
	C4037.175293,1983.853760 3989.402344,1953.594482 3941.594482,1923.390137 
	C3894.000732,1893.321045 3846.863037,1862.514038 3796.340820,1831.772095 
	C3796.516602,1846.846558 3796.219482,1859.208130 3796.707275,1871.649780 
	C3797.880615,1901.578369 3798.821777,1931.517700 3799.634033,1961.458618 
	C3800.843262,2006.055298 3801.810547,2050.658447 3802.973877,2095.256592 
	C3804.189209,2141.850098 3805.546875,2188.439941 3806.780762,2235.033203 
	C3807.609375,2266.329102 3808.309326,2297.627930 3809.105225,2328.924561 
	C3809.934326,2361.536621 3810.803711,2394.147705 3811.666260,2426.758789 
	C3812.722412,2466.697998 3813.638428,2506.641357 3814.899170,2546.573975 
	C3815.990723,2581.151855 3817.648682,2615.711914 3818.741943,2650.289551 
	C3819.731201,2681.577148 3820.236084,2712.879639 3821.064697,2744.172607 
	C3821.857666,2774.119873 3822.858643,2804.061768 3823.648438,2834.009033 
	C3824.806885,2877.942383 3825.807617,2921.880371 3826.970459,2965.813477 
	C3828.186035,3011.738770 3829.554443,3057.660156 3830.779053,3103.584961 
	C3831.631348,3135.544678 3832.303955,3167.508545 3833.148682,3199.468262 
	C3833.922852,3228.750977 3834.848877,3258.029297 3835.656738,3287.311035 
	C3836.905518,3332.571777 3838.021484,3377.836670 3839.363525,3423.094482 
	C3840.369385,3457.010498 3841.801025,3490.914307 3842.747070,3524.831787 
	C3843.527100,3552.801270 3843.734131,3580.786133 3844.441650,3608.758301 
	C3845.636475,3656.018066 3847.023193,3703.272461 3848.337646,3750.529297 
	C3848.763428,3765.835205 3847.870117,3781.344971 3850.149658,3796.371582 
	C3851.561768,3805.679932 3852.187988,3814.732910 3852.000000,3824.000000 
z"/>

            <path fill="#ffffff" opacity="1.000000" stroke="none"
                d="
M3200.353271,112.444656 
	C3189.668457,124.262917 3179.147705,136.234741 3168.268799,147.871735 
	C3129.177734,189.687042 3090.018799,231.439636 3050.783691,273.119873 
	C3010.155029,316.280182 2969.330322,359.255737 2928.735596,402.447540 
	C2890.435547,443.197815 2852.367432,484.166168 2814.112061,524.958435 
	C2787.700928,553.121338 2761.140137,581.143677 2734.653809,609.236145 
	C2699.039551,647.010498 2663.389893,684.751587 2627.835205,722.582214 
	C2594.096680,758.480652 2560.478516,794.492065 2526.751709,830.401306 
	C2523.652344,833.701355 2520.147217,836.620178 2515.824951,840.654724 
	C2503.107178,827.695740 2491.100342,815.433472 2479.062744,803.201477 
	C2457.123047,780.907410 2435.140137,758.655701 2413.221436,736.340515 
	C2392.691162,715.438904 2372.271240,694.428650 2351.710693,673.556519 
	C2329.755371,651.268372 2307.657959,629.119995 2285.693604,606.840637 
	C2276.809570,597.829041 2268.152588,588.594055 2259.324463,579.526978 
	C2221.705322,540.889343 2184.174561,502.164337 2146.391602,463.687103 
	C2110.957275,427.601501 2075.126953,391.904510 2039.689453,355.822174 
	C2000.967285,316.395447 1962.602905,276.617401 1923.880493,237.190994 
	C1894.031128,206.798752 1863.816650,176.765381 1833.873291,146.465012 
	C1822.659790,135.117950 1811.689575,123.527267 1800.779907,111.887032 
	C1799.200562,110.201965 1796.202148,108.831139 1797.288574,105.705605 
	C1797.732300,104.969566 1798.049194,103.975143 1798.373413,103.972733 
	C1827.022827,103.760139 1855.672852,103.623909 1886.484619,104.363533 
	C1889.788940,104.937546 1890.931396,104.630478 1892.073730,104.323410 
	C1896.065918,104.246773 1900.058228,104.103020 1904.050415,104.103027 
	C2333.428223,104.103966 2762.805908,104.115692 3194.710938,105.231094 
	C3198.276611,108.371819 3199.314941,110.408234 3200.353271,112.444656 
z"/>

        </svg>


    );
};

export default WhiteHeroLogo;


