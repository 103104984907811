import React from 'react';
import './styles.css'; // Import the CSS file containing the animation styles

const FlashingText = ({ children }) => {
    return (
        <div className="text-line">{children}</div>
    );
};

export default FlashingText;
