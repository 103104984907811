import { Link } from "react-router-dom"


export const PediatricsPage = () => {
    return (
        <div className="pt-2">

            <div className="container px-3 mx-auto flex flex-wrap flex-col items-center">
                {/* <!--Left Col--> */}


                <div className="max-w-[280px]">
                    <div className="grid grid-cols-1 justify-center items-center mb-10 gap-x-3 min-w-fit">


                        <h1 className="text- md:text-4xl text-center font-bold my-5">Pediatrics</h1>
                        <h3 className="text-center font-bold my-4 text-sm md:text-base mx-auto leading-tight">
                            For our separate Pediatrics website, <span className="text-center font-bold my-4 text-base mx-auto hover:bg-blue-400 leading-tight">click here</span>
                        </h3>
                        <Link to='pedantibiotic'>
                            <button class="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                Pediatric Antibiotic Dose
                            </button>
                        </Link>
                        <Link to='apgar'>
                            <button class="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                APGAR Score
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}