import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TiChevronLeftOutline } from "react-icons/ti";
import './backbutton.styles.css'

const BackButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    };

    return (
        <div
            // className="rounded-lg bg-table-hover border bg-opacity-20 px-4 py-2 text-xs md:text-sm w-fit font-bold text-white hover:bg-opacity-30 my-3 mt-4 lg:mt-0 shadow focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
            className="w-fit flex gap-x-1 items-center md:w-fit text-xs md:text-base bg-white text-black font-bold rounded-full py-1 px-4 md:px-6 md:py-4 shadow focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"

            onClick={handleClick}>
            <div class="cursor-pointer duration-200 hover:scale-150 active:scale-100" title="Go Back">
                <div className='hover:-translate-x-10 duration-1000 ease-linear'>
                    <TiChevronLeftOutline className='scale-125'></TiChevronLeftOutline>
                </div>
            </div>
            Go Back
        </div>

        // <button onClick={handleClick} class="signupBtn">
        //     <span class="arrow">
        //         <svg fill="#ffffff" viewBox="0 0 320 512" height="1em" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)">
        //             <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path>
        //         </svg>

        //     </span>
        //     <span class="btnText">Go Back</span>
        // </button>



        //   <div className="w-fit flex flex-row gap-x-1 items-center md:w-fit text-xs md:text-base bg-white text-black font-bold rounded-full py-1 px-4 md:px-6 md:py-4 shadow focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
        //         <backbutton>
        //             <svg>
        //                 <TiChevronLeftOutline> </TiChevronLeftOutline>
        //             </svg>

        //             <span>Go Back</span>
        //         </backbutton>
        //     </div>


    );
};

export default BackButton;
