import React from 'react';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';
import SaveAsPDF from '../../../../features/button/save-as-pdf';


export const ETTTroubleshooting = () => {




    return (

        <CalculatorWorking>


            <div className="container mb-20  mx-auto px-2 md:px-4 py-0">
                {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
                <h1 class="place-content-center block md:inline text-2xl text-start md:text-center md:text-4xl font-extrabold dark:text-white">Troubleshooting Endotracheal Intubation:<div className='text-start text-sm md:text-2xl'>Navigating Challenges for Optimal Airway Management.</div></h1>
                <div className='grid-cols-1 md:flex grid place-items-center place-content-center md:place-items-start px-0 md:px-8  my-2 md:my-6'>


                    <div className='flex gap-x-2 '>
                        <div className='px-0 md:px-8  mt-1'>
                            <label htmlFor="my-modalarrest" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">ETT Size Guide</label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my-modalarrest" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                    <label htmlFor="my-modalarrest" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                    <div className='flex gap-x-20'>
                                        <h3 className="font-bold text-gray-800 text-base mb-6">
                                            ETT Size Guide: </h3>



                                    </div>

                                    {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                    <ul class="space-y-4 text-gray-800  list-outside px-4 md:px-8 text-vsm  md:text-base">
                                        <div className='rounded-lg border-4 px-4'>
                                            <li className='font-bold'>Adults:</li>
                                            <ol class="pl-5 space-y-1 list-disc list-outside">
                                                <li>Most commonly used sizes in adults are between 7 & 8mm</li>
                                                <li>Sizes may vary according to the anatomical variations or abnormailities.</li>
                                            </ol>
                                        </div>

                                        <div className='rounded-lg border-4 p-4'>
                                            <li className='font-bold'>Paediatrics:</li>
                                            <ol class="pl-5 space-y-1 list-disc list-outside">
                                                <li>There is a formula to calculate the size of ETT in paediatric patients.</li>
                                                <li className='font-bold text-base md:text-lg'>ETT Size = [Age / 4 ] + 4</li>
                                            </ol>
                                        </div>


                                    </ul>



                                    <div className="modal-action">
                                        <label htmlFor="my-modalarrest" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className='px-0 md:px-8  mt-1'>

                            <label htmlFor="my-modalchecklist" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Intubation Checklist</label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my-modalchecklist" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                    <label htmlFor="my-modalchecklist" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                    <div className='flex gap-x-20 mb-2'>
                                        <h3 className="font-bold text-gray-800 text-base mb-6">
                                            Intubation Checklist</h3>
                                        <div className='mb-2'> <SaveAsPDF /></div>


                                    </div>

                                    {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                    <div className="overflow-x-auto">
                                        <table className="rounded-lg bg-gray-800 table-pin-cols">
                                            {/* head */}

                                            <thead>
                                                <tr>

                                                    <th>Step</th>
                                                    <th>1st attempt</th>
                                                    <th>2nd attempt</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <th className='bg-[#3bb371]'>Pre-procedure</th>
                                                    <th className='bg-[#3bb371]'></th>
                                                    <th className='bg-[#3bb371]'></th>


                                                </tr>
                                                <tr>

                                                    <td>Assessment of patient and airway</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Ensures trained assistant & senior supervision</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Confirms airway plan with anaesthetic assistant</td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td>Performs WHO sign in</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>

                                                    <td>Full monitoring including capnography</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Ensures patent IV cannula</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>

                                                    <td>Optimises patient position</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Pre-oxygenation until ETO2 &gt; 0.8</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr>
                                                    <th className='bg-[#3bb371]'>Procedure</th>
                                                    <th className='bg-[#3bb371]'></th>
                                                    <th className='bg-[#3bb371]'></th>
                                                </tr>

                                                <tr>
                                                    <td>Supports airway using simple manoeuvres</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr>
                                                    <td>Supports ventilation using bag mask ventilation</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr>

                                                    <td>Assesses adequacy of anaesthesia/relaxation</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Inserts laryngoscope correctly into mouth and obtains view – vocalises view</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>

                                                    <td>Inserts endotracheal tube to correct distance</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Uses additional manoeuvres or adjuncts, if required (e.g. external neck pressure; bougie)</td>
                                                    <td></td>
                                                    <td></td>


                                                </tr>
                                                <tr>
                                                    <td>Anchors ETT to ensure it does not move</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Removes laryngoscope carefully from mouth</td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>

                                                    <td>Attaches ventilator tubing to ETT</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Confirms ventilation – chest rising, ETCO2, auscultates chest</td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>

                                                    <td>Secures ETT</td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <th className='bg-[#3bb371]'>Post-procedure</th>
                                                    <th className='bg-[#3bb371]'></th>
                                                    <th className='bg-[#3bb371]'></th>
                                                </tr>
                                                <tr>

                                                    <td>Documentation of airway management</td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>

                                                    <th className='bg-[#3bb371]'>Throughout</th>
                                                    <th className='bg-[#3bb371]'></th>
                                                    <th className='bg-[#3bb371]'></th>
                                                </tr>
                                                <tr>
                                                    <td>Appropriate communication with assistant & patient</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>

                                                    <td>Aware of patient condition/vital signs</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>

                                                    <td>Shows understanding of adequate anaesthetic/paralysis for intubation/maintenance</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className='text-xs md:text-base text-black font-bold my-8'>From: <a class='text-xs md:text-base text-start font-bold text-blue-400  hover:text-white hover:bg-blue-400' href='https://das.uk.com/files/2020/page/Tayside%20Mastery%20Learning%20Programme%20combined%20final-Aug26.pdf' target="_blank" rel="noreferrer">Tayside Mastery Learning Programme of DAS and Royal College of Anesthetists    </a></div>
                                    </div>


                                    <div className="modal-action">
                                        <label htmlFor="my-modalchecklist" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>




                </div>


                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>


                        <div>
                            <div>
                                <p class="mb-10 text-white dark:text-white px-2 indent-6 my-4 text-start text-sm md:text-lg">Endotracheal tube intubation plays a vital role in securing a patient's airway during anesthesia and critical care procedures. However, even with a clear view of the larynx, anaesthetists can encounter obstacles that hinder the smooth passage of the tube. In this guide, we explore the common challenges faced during endotracheal tube intubation and provide practical troubleshooting strategies to overcome them. From optimizing patient positioning to addressing complications like oesophageal or endobronchial intubation, understanding and resolving these issues are crucial for ensuring successful airway management and patient safety. Join us as we delve into the world of troubleshooting endotracheal tube intubation, equipping anaesthetists with the knowledge and techniques to navigate challenges with confidence and expertise.</p>

                                <ul class="space-y-5 text-white list-disc list-outside px-6 md:px-16 text-sm md:text-lg">

                                    <div className='text-base md:text-2xl font-bold mb-8'>Problems encountered during endotracheal intubation:</div>

                                    <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-decimal list-outside">
                                        <li class='font-bold'>Inadequate view of larynx and vocal cords:</li>
                                        <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-disc list-outside">
                                            <div className='italic'>It is not uncommon for the anaesthetist to achieve a good view of the larynx, yet have difficulty in passing the tube between the vocal cords.</div>
                                            <li>Always check that the patient is in the optimal position.</li>
                                            <li>Passage of the tube can be facilitated if an assistant retracts the right side of the mouth laterally and the tube has an optimal shape (consider a stylet).</li>
                                            <li>You can perform external laryngeal manipulation (ELM) with your right hand and then ask your assistant to replicate this to improve the laryngeal view. Some refer to this manoeuvre as BURP (backwards, upwards, rightwards pressure). ELM should also be applied when a suboptimal view of the larynx is obtained at laryngoscopy.</li>
                                            <div class='max-w-fit md:max-w-[400px] justify-center items-center mb-8'>
                                                <iframe
                                                    className='w-fit h-fit'
                                                    src="https://www.youtube.com/embed/odTYa1t7JfY"
                                                    title="YouTube video player"
                                                    frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowfullscreen>

                                                </iframe>
                                            </div>
                                            <li>Good views on a Macintosh videolaryngoscope screen and difficulty passing a tube can be a sign of poor laryngoscopy technique. Focusing on optimising the direct view first, prior to looking at the screen, should ensure optimal technique.</li>

                                        </ol>

                                        <li class='font-bold'>Oesophageal intubation:</li>
                                        <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-disc list-outside">
                                            <li className='italic'>Signs of oesophageal intubation include:</li>
                                            <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-[lower-latin] list-outside">
                                                <li>Capnography: absent or decaying trace – <span class='font-bold'>THIS IS CRUCIAL</span></li>
                                                <li>Progressive hypoxia (may take 3 minutes or more for onset)</li>
                                                <li>Abnormal sounds heard in axillae or epigastrium.</li>
                                            </ol>

                                            <li className='italic'>Action plan:</li>
                                            <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-[lower-latin] list-outside">
                                                <li className='font-bold'>Remove ETT (If in doubt, pull it out)</li>
                                                <li>Default to bag and mask ventilation (+/– adjunct)</li>
                                                <li>Administer 100 % oxygen</li>
                                                <li>Call for senior help</li>

                                            </ol>
                                        </ol>

                                        <li class='font-bold'>Endobronchial intubation.    </li>
                                        <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-disc list-outside">
                                            <div>Occurs when the ETT is advanced too far, entering one of the main bronchi (most commonly the right). The best way to avoid endobronchial intubation is to insert the ETT to the correct distance in the first place, and then ensure it does not move while it is being secured. </div>
                                            <li className='font-bold'>Signs of bronchial intubation:</li>
                                            <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-[lower-latin] list-outside">
                                                <li>Uneven chest expansion</li>
                                                <li>Reduced breath sounds unilaterally (can be subtle)</li>
                                                <li>High airway pressures</li>
                                                <li>Desaturation to around 85–88% (may take several minutes).</li>
                                            </ol>

                                            <li className='font-bold'>Action Plan:</li>
                                            <ol class="pl-2 md:pl-5 mt-2 space-y-4 list-[lower-latin] list-outside">
                                                <li>Administer 100 % oxygen</li>
                                                <li>Observe the chest and auscultate both axillae</li>
                                                <li>Deflate the cuff and withdraw the ETT carefully until breath sounds are heard bilaterally</li>
                                                <li>ensure adequate re-expansion of non-ventilated lung – patient may require hand ventilation, continuous positive airway pressure (CPAP) or positive-end expiratory pressure (PEEP).</li>
                                            </ol>

                                        </ol>





                                    </ol>

                                </ul>

                            </div>


                        </div>


                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Conclusion:</div>

                        <p class="mb-3 text-white dark:text-white px-4 indent-3 my-4 text-start text-sm md:text-lg">Endotracheal intubation is a crucial procedure performed in the operation theater for anesthesia to secure the patient's airway during surgical procedures. Following a systematic approach, proper preparation, and adherence to sterile techniques, healthcare professionals can successfully perform endotracheal intubation, ensuring optimal oxygenation, ventilation, and patient safety throughout the surgical procedure. Continuous monitoring and vigilance are essential to promptly address any complications or challenges that may arise during the intubation process.</p>

                        <div className='text-xs md:text-base text-white font-bold my-8'>Literature From: <a class='text-xs md:text-base text-start font-bold text-blue-300  hover:text-white hover:bg-blue-400' href='https://das.uk.com/files/2020/page/Tayside%20Mastery%20Learning%20Programme%20combined%20final-Aug26.pdf' target="_blank" rel="noreferrer">Tayside Mastery Learning Programme of DAS and Royal College of Anesthetists    </a></div>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div >


        </CalculatorWorking >

    );
};
