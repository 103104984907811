import React from 'react';
import SaveAsPDF from '../../../../features/button/save-as-pdf';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';
import { Link } from "react-router-dom"
import { FlashingText } from '../../../../features';

export const SurgeryProgress = () => {


    return (

        <CalculatorWorking>


            <div className="container mb-20  mx-auto px-4 py-0">
                {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
                <h1 class="flex place-content-center text-2xl text-center md:text-4xl font-extrabold dark:text-white">Comprehensive Guide to Daily Patient Progress Monitoring for Post-Op and Pre-Op Surgical Patients</h1>
                <div className='flex place-content-center'>
                    <FlashingText>
                        <div className='grid grid-cols-1 border p-2 rounded-2xl w-fit flex place-items-center mb-8'>
                            <div className='pl-5'> Please read about general progress of patients regardless of being pre or post-operative below</div>
                            <Link to='/interns/internalmedicine/medicineprogress'>
                                <div class="btn lg:mx-0 hover:underline bg-white text-center w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                    General Progress
                                </div>
                            </Link>
                        </div>
                    </FlashingText>
                </div>
                <div className='flex place-content-center gap-x-2' >


                    <div className='flex place-items-center place-content-center md:place-items-start px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalpreop" className="btn lg:mx-0 hover:underline bg-white text-center w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Pre-Op Progress Template</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalpreop" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white ">
                                <label htmlFor="my-modalpreop" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-xl mb-6">Pre-Op Progress Template:</h3>


                                    <div> <SaveAsPDF /></div>
                                </div>

                                <div class='font-bold text-center text-gray-400 text-xl mb-6' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                <div className='mb-2 grid grid-cols-3'>
                                    <div style={{ userSelect: "none" }} className="text-xs text-gray-800 md:text-base text-start pl-2">Date:</div>
                                    <div style={{ userSelect: "none" }} className="text-xs text-gray-800 md:text-base text-start pl-2">Shift:<span class='font-bold'>M/E/N</span></div>
                                    <div style={{ userSelect: "none" }} className="text-xs text-gray-800 md:text-base text-start pl-2">Time:</div>
                                </div>
                                <div style={{ userSelect: "none" }} className="text-xs mb-2 md:text-base text-gray-800 font-bold text-start pl-2">Patient Name:</div>
                                <div style={{ userSelect: "none" }} className='mb-2 grid grid-cols-2'>
                                    <div style={{ userSelect: "none" }} className="text-xs md:text-base text-gray-800 font-bold text-start pl-2">Diagnosis: &#9651;</div>
                                    <div style={{ userSelect: "none" }} className="text-xs md:text-base text-gray-800 font-bold text-start pl-2"> Comorbids/Allergies:</div>
                                </div>
                                <div>
                                    <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Subjective:</div>

                                    <div className='px-2 mb-4'>
                                        <ul style={{ userSelect: "none" }} class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                            <li>
                                                Chief Complaint:

                                            </li>
                                            <li>
                                                Patient concerns or questions related to upcoming surgery:

                                            </li>

                                        </ul>
                                    </div>
                                </div>

                                <div>
                                    <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Objective:</div>

                                    <div className='px-2 mb-4'>
                                        <ul style={{ userSelect: "none" }} class="space-y-4 grid grid-cols-2 gap-x-2 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                            <li>
                                                Vital Signs:
                                                <ol class="pl-5 mt-2 px-2 md:px-4 space-y-1 list-decimal list-outside">
                                                    <li>Blood Pressure: _____ mmHg</li>
                                                    <li>Heart Rate: _____ bpm</li>
                                                    <li>Oxygen Saturation: ____ %</li>
                                                    <li>Respiratory Rate: ____ /min</li>
                                                    <li>Temperature: ____ &deg;C/F</li>
                                                </ol>
                                            </li>
                                            <li>Physical Examination Findings:
                                                <ul class="pl-5 mt-2 px-2 md:px-4 space-y-1 list-decimal list-outside">
                                                    <li>General Appearance:</li>
                                                    <li>Cardiovascular System:</li>
                                                    <li>Respiratory System:</li>
                                                    <li>Gastrointestinal System:</li>
                                                    <li>Neurological System:</li>
                                                    <li>Other Relevant Systems:</li>
                                                </ul>
                                            </li>
                                        </ul>

                                        <div style={{ userSelect: "none" }} className="text-xs mb-2 md:text-base text-gray-800 font-bold text-start pl-2">Pre-Op Specific:</div>
                                        <ul style={{ userSelect: "none" }} class="space-y-4 grid grid-cols-2 gap-x-2 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                            <li>
                                                Medical History Review:
                                                <ul class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                                    <li>Allergies: </li>
                                                    <li>Current Medications: </li>
                                                    <li>Previous Surgeries:</li>
                                                    <li>Pre-existing Medical Conditions:</li>
                                                </ul>
                                            </li>
                                            <li>
                                                Pre-Op Tests and Assessments:
                                                <ul class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                                    <li>Blood Work: </li>
                                                    <li>Imaging studies:</li>
                                                    <li>Electrocardiogram:</li>
                                                    <li>Anesthesia Evaluation:</li>
                                                </ul>
                                            </li>
                                            <li>
                                                Patient Education and Preparation:
                                                <ul class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                                    <li>Fasting Guidelines:</li>
                                                    <li>Medication Restrictions:</li>
                                                    <li>Surgical Procedure Explanation:</li>
                                                    <li>Expected Outcomes and Risks:</li>
                                                    <li>Post-Op Care Instructions: </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Course of Illness:</div>

                                    <div className='px-2 mb-4'>
                                        <ul style={{ userSelect: "none" }} class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                            <li>
                                                Timeline of treatment and interventions:

                                            </li>
                                            <li>
                                                Response to treatment and changes observed:

                                            </li>

                                        </ul>
                                    </div>
                                </div>

                                <div>
                                    <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Assessment:</div>

                                    <div className='px-2 mb-4'>
                                        <ul style={{ userSelect: "none" }} class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                            <li>
                                                Analysis of subjective and objective data:

                                            </li>
                                            <li>
                                                Diagnosis evaluation:

                                            </li>

                                        </ul>
                                    </div>
                                </div>

                                <div>
                                    <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Plan:</div>

                                    <div className='px-2 mb-4'>
                                        <ul style={{ userSelect: "none" }} class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                            <li>
                                                Treatment adjustments or modifications:

                                            </li>
                                            <li>
                                                Patient education:

                                            </li>

                                            <li>
                                                Follow-up monitoring and next steps:

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Additional Notes:</div>

                                    <div className='px-2 mb-4'>
                                        <ul style={{ userSelect: "none" }} class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                            <li>
                                                ________________________________
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                                <div className="modal-action">
                                    <label htmlFor="my-modalpreop" className="btn hover:bg-table-hover hover:text-gray-800">Close</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='flex place-items-center place-content-center md:place-items-start px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalpostop" className="btn lg:mx-0 hover:underline bg-white text-center w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Post-Op Progress Template</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalpostop" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white ">
                                <label htmlFor="my-modalpostop" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-xl mb-6">Post-Op Daily Progress Template:</h3>


                                    <div> <SaveAsPDF /></div>
                                </div>

                                <div class='font-bold text-center text-gray-400 text-xl mb-6' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                <div className='mb-2 grid grid-cols-3'>
                                    <div style={{ userSelect: "none" }} className="text-xs text-gray-800 md:text-base text-start pl-2">Date:</div>
                                    <div style={{ userSelect: "none" }} className="text-xs text-gray-800 md:text-base text-start pl-2">Shift:<span class='font-bold'>M/E/N</span></div>
                                    <div style={{ userSelect: "none" }} className="text-xs text-gray-800 md:text-base text-start pl-2">Time:</div>
                                </div>
                                <div style={{ userSelect: "none" }} className="text-xs mb-2 md:text-base text-gray-800 font-bold text-start pl-2">Patient Name:</div>
                                <div style={{ userSelect: "none" }} className='mb-2 grid grid-cols-2'>
                                    <div style={{ userSelect: "none" }} className="text-xs md:text-base text-gray-800 font-bold text-start pl-2">Diagnosis: &#9651;</div>
                                    <div style={{ userSelect: "none" }} className="text-xs md:text-base text-gray-800 font-bold text-start pl-2"> Comorbids/Allergies:</div>
                                </div>
                                <div>
                                    <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Subjective:</div>

                                    <div className='px-2 mb-4'>
                                        <ul style={{ userSelect: "none" }} class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                            <li>
                                                Chief Complaint:

                                            </li>
                                            <li>
                                                Patient-reported pain levels and location:

                                            </li>
                                            <li>
                                                Other symptoms or concerns reported by the patient:

                                            </li>

                                        </ul>
                                    </div>
                                </div>

                                <div>
                                    <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Objective:</div>

                                    <div className='px-2 mb-4'>
                                        <ul style={{ userSelect: "none" }} class="space-y-4 grid grid-cols-2 gap-x-2 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                            <li>
                                                Vital Signs:
                                                <ol class="pl-5 mt-2 px-2 md:px-4 space-y-1 list-decimal list-outside">
                                                    <li>Blood Pressure: _____ mmHg</li>
                                                    <li>Heart Rate: _____ bpm</li>
                                                    <li>Oxygen Saturation: ____ %</li>
                                                    <li>Respiratory Rate: ____ /min</li>
                                                    <li>Temperature: ____ &deg;C/F</li>
                                                </ol>
                                            </li>
                                            <li>Physical Examination Findings:
                                                <ul class="pl-5 mt-2 px-2 md:px-4 space-y-1 list-decimal list-outside">
                                                    <li>General Appearance:</li>
                                                    <li>Cardiovascular System:</li>
                                                    <li>Respiratory System:</li>
                                                    <li>Gastrointestinal System:</li>
                                                    <li>Neurological System:</li>
                                                    <li>Other Relevant Systems:</li>
                                                </ul>
                                            </li>
                                        </ul>

                                        <div style={{ userSelect: "none" }} className="text-xs mb-2 md:text-base text-gray-800 font-bold text-start pl-2">Post-Op Specific:</div>
                                        <ul style={{ userSelect: "none" }} class="space-y-4 grid grid-cols-2 gap-x-2 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                            <li>
                                                Wound Assessment:
                                                <ul class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                                    <li>Incision site appearance and healing progress: </li>

                                                </ul>
                                            </li>
                                            <li>
                                                Pain Management:
                                                <ul class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                                    <li>Pain assessment on scale of 0-10: </li>
                                                    <li>Pain medication administration and effectiveness: </li>
                                                    <li>Side effects or adverse reactions to pain medication: </li>
                                                </ul>
                                            </li>
                                            <li>
                                                Mobility and Activity:
                                                <ul class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                                    <li>Patient's ability to perform activities of daily living:</li>
                                                    <li>Progress in mobilization and ambulation:</li>
                                                </ul>
                                            </li>
                                            <li>
                                                Respiratory Function:
                                                <ul class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                                    <li>Assessment of breathing patterns and lung sounds:</li>
                                                    <li>Deep breathing exercises and respiratory care:</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Course of Illness:</div>

                                    <div className='px-2 mb-4'>
                                        <ul style={{ userSelect: "none" }} class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                            <li>
                                                Timeline of treatment and interventions:

                                            </li>
                                            <li>
                                                Response to treatment and changes observed:

                                            </li>

                                        </ul>
                                    </div>
                                </div>

                                <div>
                                    <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Assessment:</div>

                                    <div className='px-2 mb-4'>
                                        <ul style={{ userSelect: "none" }} class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                            <li>
                                                Analysis of subjective and objective data:

                                            </li>
                                            <li>
                                                Diagnosis evaluation:

                                            </li>

                                        </ul>
                                    </div>
                                </div>

                                <div>
                                    <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Plan:</div>

                                    <div className='px-2 mb-4'>
                                        <ul style={{ userSelect: "none" }} class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                            <li>
                                                Treatment adjustments or modifications:

                                            </li>
                                            <li>
                                                Patient education:

                                            </li>

                                            <li>
                                                Follow-up monitoring and next steps:

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ userSelect: "none" }} className="text-xs my-4 md:text-base text-gray-800 font-bold text-start pl-2">Additional Notes:</div>

                                    <div className='px-2 mb-4'>
                                        <ul style={{ userSelect: "none" }} class="space-y-4 text-xs pl-2 text-gray-800 list-disc list-outside dark:text-gray-800">
                                            <li>
                                                ________________________________
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                                <div className="modal-action">
                                    <label htmlFor="my-modalpostop" className="btn hover:bg-table-hover hover:text-gray-800">Close</label>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>



                        <p class="mb-10 text-white dark:text-white px-4 indent-6 my-4 text-start text-sm md:text-lg">Monitoring the daily progress of post-operative and pre-operative surgical patients is crucial for healthcare professionals to ensure optimal recovery and prepare them for surgery. By closely monitoring their condition, healthcare providers can detect any complications, track healing progress, and make informed decisions regarding their care. In this blog, we will explore the essential components of daily patient progress monitoring specifically for post-op and pre-op surgical patients, providing you with valuable insights to enhance patient outcomes.</p>

                        <ul class="space-y-4 text-white list-disc list-outside px-4 md:px-16 text-sm md:text-lg">

                            <li className='font-bold'>Pre-Op Patient Progress Monitoring:</li>
                            <div>Pre-operative patient progress monitoring aims to assess the patient's readiness and optimize their health status before surgery. Consider the following aspects:</div>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Medical History Review: Thoroughly review the patient's medical history, including allergies, current medications, previous surgeries, and any pre-existing medical conditions. Identify potential risks and take appropriate measures to manage them before the surgery.</li>
                                <li>Pre-Op Tests and Assessments: Coordinate and document the results of pre-operative tests, such as blood work, imaging studies, electrocardiograms, and anesthesia evaluations. Ensure that all necessary tests are completed, and review the findings for any abnormalities or concerns.</li>
                                <li>Patient Education and Preparation: Provide pre-operative instructions, including fasting guidelines, medication restrictions, and preparation for anesthesia. Educate the patient about the surgical procedure, potential risks, expected outcomes, and post-operative care instructions.</li>
                                <li>Psychological Support: Recognize and address any anxiety or concerns the patient may have about the upcoming surgery. Provide emotional support, answer questions, and involve the patient in decision-making to alleviate fears and promote a sense of empowerment.</li>
                            </ol>


                            <li className='font-bold'> Post-Op Patient Progress Monitoring:</li>
                            <div> Post-operative patient progress monitoring focuses on the recovery and healing process after surgery. Here are some key considerations:</div>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Wound Assessment: Regularly inspect the surgical incision site for signs of healing, such as redness, swelling, drainage, or any signs of infection. Document any changes in wound appearance or drainage characteristics.</li>
                                <li>Pain Management: Evaluate and manage post-operative pain levels using appropriate pain assessment scales. Administer pain medications as prescribed and monitor the patient's response and side effects.</li>
                                <li>Mobility and Activity: Encourage early mobilization and assess the patient's ability to perform activities of daily living. Gradually increase mobility while ensuring patient safety and preventing complications like deep vein thrombosis.</li>
                                <li>Respiratory Function: Monitor respiratory status, particularly in patients who underwent chest or abdominal surgeries. Assess breathing patterns, auscultate lung sounds, and encourage deep breathing exercises to prevent post-operative respiratory complications.</li>
                            </ol>
                        </ul>
                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Conclusion:</div>

                        <p class="mb-3 text-white dark:text-white px-4 indent-3 my-4 text-start text-sm md:text-lg">Daily patient progress monitoring for post-op and pre-op surgical patients plays a vital role in optimizing their outcomes. By regularly assessing wound healing, managing pain, monitoring mobility, evaluating respiratory function, reviewing medical history, conducting pre-operative tests, providing patient education, and offering psychological support, healthcare professionals can ensure a smooth recovery for post-op patients and prepare pre-op patients for surgery with confidence. Remember, patient-centered care and thorough monitoring are key to successful surgical outcomes.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />

            </div>

        </CalculatorWorking >

    );
};
