import React from 'react';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';




export const BLS = () => {




    return (

        <CalculatorWorking>


            <div className="container mb-20  mx-auto px-2 md:px-4 py-0">
                {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
                <h1 class="place-content-center block md:inline text-3xl text-start md:text-center md:text-4xl font-extrabold dark:text-white">BLS<div className='text-start text-sm md:text-2xl'>Basic Life Support: Saving Lives through Immediate Action</div></h1>
                <div className='grid-cols-1 md:flex grid place-items-center place-content-center md:place-items-start px-0 md:px-8  my-2 md:my-6'>


                    <div className='flex gap-x-2 '>
                        <div className='px-0 md:px-8  mt-1'>
                            <label htmlFor="my-modalarrest" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Adult BLS Algorithm</label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my-modalarrest" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                    <label htmlFor="my-modalarrest" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                    <div className='flex gap-x-20'>
                                        <h3 className="font-bold text-gray-800 text-base mb-6">
                                            Adult BLS Algorithm: See on <a href='https://cpr.heart.org/en/resuscitation-science/cpr-and-ecc-guidelines/algorithms' target="_blank" rel="noreferrer" className='text-base hover:text-white dark:text-blue-600 text-start hover:bg-blue-400'>AHA's website</a></h3>



                                    </div>

                                    {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                    <div class='max-w-fit flex justify-center items-center mb-4'>
                                        <img class="scale-100 hover:scale-125 cursor-pointer object-cover ease-in duration-500" src="https://cpr.heart.org/-/media/CPR-Images/CPR-Guidelines-Images/Part-3-Adult-Basic-and-Advanced-Life-Support/AlgorithmBLS_Adult_200624.jpg?h=800&iar=0&mw=960&w=627&hash=712458A60101DF4DE7E2A0BD110360CB" alt="arrest" />
                                    </div>



                                    <div className="modal-action">
                                        <label htmlFor="my-modalarrest" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='px-0 md:px-8  mt-1'>
                            <label htmlFor="my-modalchainofsurvival" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Chain of Survival</label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my-modalchainofsurvival" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                    <label htmlFor="my-modalchainofsurvival" className="btn btn-sm btn-circle absolute right-2 top-2 mb-2">✕</label>

                                    <div className='gap-x-20'>
                                        <h3 className="font-bold text-gray-800 text-base mb-6">
                                            Chain of Survival: See on <a href='https://cpr.heart.org/en/resuscitation-science/cpr-and-ecc-guidelines/algorithms' target="_blank" rel="noreferrer" className='text-base hover:text-white dark:text-blue-600 text-start hover:bg-blue-400'>AHA's website</a></h3>

                                        <h1 className="font-bold text-gray-800 text-xs">
                                            IHCA: In-Hospital Cardiac Arrest
                                        </h1>

                                        <h1 className="font-bold text-gray-800 text-xs mb-6">
                                            OHCA: Out-of Hospital Cardiac Arrest
                                        </h1>



                                    </div>

                                    {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                    <div class='max-w-fit flex justify-center items-center mb-4'>
                                        <img class="scale-100 hover:scale-125 cursor-pointer object-cover ease-in duration-500" src="https://cpr.heart.org/-/media/CPR-Images/CPR-Guidelines-Images/Part-3-Adult-Basic-and-Advanced-Life-Support/Adult_IHCA_OHCA_COS_200513.jpg?h=557&w=800&hash=9332A5C5D7DF9B22108350ADB3132C89" alt="circular" />
                                    </div>


                                    <div className="modal-action">
                                        <label htmlFor="my-modalchainofsurvival" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>



                    <div className='px-0 md:px-8  mt-1'>
                        <label htmlFor="my-modaltermination" className="btn lg:mx-0 h-fit md:h-fit shover:underline bg-white text-center w-fit md:w-fit hover:text-white text-vsm md:text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">BLS: Termination of Resuscitation</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modaltermination" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modaltermination" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        BLS, Termination of Resuscitation: See on <a href='https://cpr.heart.org/en/resuscitation-science/cpr-and-ecc-guidelines/algorithms' target="_blank" rel="noreferrer" className='text-base hover:text-white dark:text-blue-600 text-start hover:bg-blue-400'>AHA's website</a></h3>



                                </div>

                                {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                <div class='max-w-fit flex justify-center items-center mb-4'>
                                    <img class="scale-100 hover:scale-125 cursor-pointer object-cover ease-in duration-500" src="https://cpr.heart.org/-/media/CPR-Images/CPR-Guidelines-Images/Part-3-Adult-Basic-and-Advanced-Life-Support/AlgorithmBLS_Termination_of_Resusc_200612.jpg?h=451&iar=0&mw=960&w=800&hash=D86E1F4562272B61BA50953C5BDA750E" alt="circular" />
                                </div>


                                <div className="modal-action">
                                    <label htmlFor="my-modaltermination" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>


                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>



                        <p class="mb-10 text-white dark:text-white px-2 indent-6 my-4 text-start text-sm md:text-lg">Basic Life Support (BLS) is a crucial set of skills that can mean the difference between life and death in an emergency situation. When someone experiences cardiac arrest or stops breathing, immediate intervention can help maintain blood circulation and provide vital oxygen to the brain and other organs. In this blog, we will explore the latest guidelines provided by the American Heart Association (AHA) for Basic Life Support. Understanding and implementing these guidelines can empower you to take swift action and potentially save a life.</p>

                        <ul class="space-y-4 text-white list-decimal list-outside px-6 md:px-16 text-sm md:text-lg">

                            <li className='font-bold text-sm md:text-lg'> Recognizing Cardiac Arrest:</li>
                            <div className='pl-0 mt-2 space-y-1'>Recognizing cardiac arrest is the first step in initiating BLS. Look for signs such as unresponsiveness, absence of normal breathing, and the absence of a pulse. If you witness someone experiencing these symptoms, immediately activate the emergency response system or call for help.</div>


                            <li className='font-bold'>Providing High-Quality Chest Compressions:</li>
                            <div className='pl-0 mt-2 space-y-1'>High-quality chest compressions are the cornerstone of BLS. To perform effective compressions:</div>
                            <ol class="pl-2 md:pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Position the heel of one hand on the center of the chest, with the other hand on top, interlocking fingers.</li>
                                <li>Compress the chest at a rate of 100-120 compressions per minute.</li>
                                <li>Ensure a compression depth of at least 2 inches (5 cm) in adults and adolescents.</li>
                                <li>Allow for complete chest recoil after each compression.</li>
                                <li>Minimize interruptions in compressions, except when defibrillation or advanced airway management is required.</li>

                            </ol>

                            <li className='font-bold'>Delivering Rescue Breaths:</li>
                            <div className='pl-0 mt-2 space-y-1'>Rescue breaths are an integral part of BLS for patients who are not breathing or not breathing normally. The steps to deliver rescue breaths are as follows:</div>
                            <ol class="pl-2 md:pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Open the airway using the head tilt-chin lift or jaw thrust maneuver, depending on the suspected cervical spine injury.</li>
                                <li>Give two breaths, each lasting about 1 second, while watching for chest rise.</li>
                                <li>Maintain an effective ratio of 30 compressions to 2 breaths in adult and child BLS, and 15 compressions to 2 breaths in infant BLS.</li>
                            </ol>

                            <li className='font-bold'>Utilizing Automated External Defibrillators (AEDs):</li>
                            <div className='pl-0 mt-2 space-y-1'>AEDs are user-friendly devices that analyze a person's heart rhythm and deliver an electric shock if necessary. When using an AED:</div>
                            <ol class="pl-2 md:pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Power on the device and follow the voice or visual prompts.</li>
                                <li>Apply the electrode pads to the person's bare chest as instructed.</li>
                                <li>Minimize interruptions in chest compressions while the AED is analyzing or delivering a shock.</li>
                                <li>Resume CPR immediately after the shock is delivered, following the AED's prompts.</li>
                            </ol>

                            <li className='font-bold'>Assisting with Choking:</li>
                            <div className='pl-0 mt-2 space-y-1'>BLS guidelines also cover techniques to assist a conscious choking victim. Depending on the age of the person, the Heimlich maneuver or abdominal thrusts may be appropriate. Prompt action is crucial in relieving the obstruction and restoring normal breathing.</div>


                        </ul>
                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Conclusion:</div>

                        <p class="mb-3 text-white dark:text-white px-4 indent-3 my-4 text-start text-sm md:text-lg">ACLS plays a critical role in managing life-threatening cardiac emergencies, acute coronary syndromes, and strokes. The latest guidelines provided by the American Heart Association (AHA) outline key interventions, including high-quality CPR, defibrillation, medication administration, and targeted therapies for optimal patient outcomes. By staying updated with ACLS guidelines and receiving proper training, healthcare professionals can effectively respond to critical care situations, ultimately improving survival rates and enhancing the quality of care provided to patients in need.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div >


        </CalculatorWorking >

    );
};
