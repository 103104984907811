import { Link } from "react-router-dom"
import RouterNavButton from "../../../features/button/routernavbutton"
import { DefaultBottomDialogue } from "../../../features"

export const SurgeryPage = () => {
    return (
        <div className="pt-2">
            <div className="container px-3 mx-auto flex flex-wrap flex-col items-center">
                {/* <!--Left Col--> */}
                <div className="max-w-fit">

                    <div className="px-4 border grid grid-cols-1 flex place-items-center place-content-center md:w-[450px] bg-white bg-opacity-20 rounded-2xl">
                        <h1 className="text-3xl md:text-3xl text-center font-bold my-5">Surgery</h1>
                        <h3 className="text-center my-4 text-sm md:text-xl mx-auto leading-tight">
                            Select desired topic
                        </h3>
                        <div className="w-[300px] max-w-[300px] md:max-w-fit md:w-fit md:mx-0 grid grid-cols-2 md:grid-cols-2 gap-x-2 md:gap-x-6 justify-center items-center">




                            <Link to='essentialskills-surgery'>
                                <RouterNavButton>  Where to begin?
                                </RouterNavButton>
                            </Link>
                            <Link to='surgeryprogress'>
                                <RouterNavButton>   Daily Progress
                                </RouterNavButton>
                            </Link>


                        </div>


                    </div>

                    <DefaultBottomDialogue />
                </div>
            </div>
        </div>
    )
}