import React, { useState } from 'react';
import { CalculatorWorking } from '../../calculatorworking';


export const IVDripRates = () => {
  const [dropFactor, setDropFactor] = useState(16);
  const [volume, setVolume] = useState('');
  const [time, setTime] = useState('');
  const [dripRate, setDripRate] = useState('');
  const [dripTime, setDripTime] = useState('');


  const calculateDripRate = () => {

    const gttsPerMinute = dropFactor * (volume / time);
    setDripRate(gttsPerMinute.toFixed(0));

    const dropsinsec = 60 / gttsPerMinute;
    setDripTime(dropsinsec.toPrecision(2));
  };


  const submitHandler = (e) => {
    e.preventDefault();
    calculateDripRate();


  }


  return (
    <CalculatorWorking>

      <div className="container  mx-auto px-4 py-6">

        <h1 className="text-3xl font-bold mb-10">IV Fluid Drip Rate Calculator</h1>
        <div className='w-90'>
          <p className="text-white text-xs md:text-xl md-10 flex justify-items-center">
            Calculate IV Fluid Drip Rate for fluid infusions in drops/min by adding desired volume, time and drip set factor.
          </p>

        </div>
        <div className="text-white text-center font-bold text-xs md:text-xl mt-8">
          Formula:
        </div>
        <div className="text-white text-center text-xs md:text-xl mb-6">
          gtts/min = (volume in mL x drop factor) / time (in minutes)
        </div>
        <div className='flex flex-col md:flex-row justify-center gap-x-32'>
          <form onSubmit={submitHandler} netlify>
            <section class="container px-4 mx-auto">

              <div class="flex flex-col mt-6">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <table class="w-min divide-y  dark:divide-none">
                      <thead>

                      </thead>
                      <tbody class="divide-y  dark:divide-gray-900 dark:bg-gray-900">




                        <tr>


                          <table className='table-auto'>



                            <td>

                              <div className='flex content-center'>
                                <label htmlFor="my-modal" className="btn w-fit mt-4 min-h-[70px] mb-2 lg:mx-0 hover:underline bg-white text-center text-xs text-gray-800 hover:text-white hover:border-white font-bold rounded-full py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Drop factor guide</label>

                                {/* Put this part before </body> tag */}
                                <input type="checkbox" id="my-modal" className="modal-toggle" />
                                <div className="modal">
                                  <div className="modal-box">
                                    <h3 className="font-bold text-2xl">Drop factor guide:</h3>
                                    <div className="text-xs font-normal md:text-sm text-start py-4"><div className='font-bold'>Determine the drop factor:</div> The drop factor represents the number of drops in one milliliter (gtts/mL) as specified by the equipment being used. It is usually provided by the manufacturer and can vary. For example, if the drop factor is 20 gtts/mL, it means that 20 drops equal 1 milliliter.</div>
                                    <p className="text-xs md:text-sm text-start py-4">Pediatric sets and sets with buret are usually 60gtt/min (microdrip)</p>
                                    <p className="text-base md:text-xl font-bold py-4">Always see the leaflet or packing for drop factor of the set</p>
                                    <div className="modal-action">
                                      <label htmlFor="my-modal" className="btn hover:bg-table-hover hover:text-white">Got it!</label>
                                    </div>
                                  </div>
                                </div>








                              </div>
                            </td>
                            <td >
                              <div className='grid-cols-1 place-items-center place-content-center'>
                                <div class="px-4 py-1 text-base md:text-xl text-center font-medium whitespace-nowrap">Drop factor:</div>

                                <div class="items-center bg-white aria-selected:bg-table-hover rounded" >


                                  <select class="text-black bg-white rounded" value={dropFactor} onChange={(e) => setDropFactor(e.target.value)}>
                                    <option value="10">10 (macrodrip)</option>
                                    <option value="16">16 (macrodrip)</option>
                                    <option value="20">20 (macrodrip)</option>
                                    <option value="60">60 (microdrip)</option>
                                  </select>

                                </div>

                                <div className='text-vsm pt-2 text-center max-w-[120px]'> Commonly used IV infusion sets are 16 gtts/min</div>

                              </div>


                            </td>

                          </table>

                        </tr>

                        <tr>

                          <table>
                            <td >
                              <div class="grid grid-cols-1 place-items-center px-4 py-4 c text-start text-base md:text-2xl  hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Volume:
                                <input
                                  value={volume}
                                  placeholder="in mL"
                                  onChange={(e) => setVolume(e.target.value)}
                                  className="bg-white text-black"
                                  type='number'
                                  required />
                              </div>



                            </td>




                          </table>
                        </tr>

                        <tr>

                          <table>
                            <td class="text-sm font-medium whitespace-nowrap">
                              <div class=" grid grid-cols-1 place-items-center px-4 py-4 text-base md:text-2xl text-start hover:bg-table-hover hover:text-black font-medium whitespace-nowrap">Time:
                                <div class="bg-none aria-selected:bg-table-hover aria-selected:border-gray-300 rounded" >

                                  <input

                                    value={time}
                                    placeholder="in mins"
                                    onChange={(e) => setTime(e.target.value)}
                                    class="bg-gray-50 text-center border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:text-base dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    type="number"

                                    required />


                                </div>

                              </div>
                            </td>




                          </table>
                        </tr>








                      </tbody>
                    </table>






                  </div>

                </div>
              </div>


            </section>


            <div className="mt-4 flex place-items-center place-content-center mb-5">
              <button
                type="submit" className="w-fit mb-2 lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                Calculate
              </button>
            </div>


          </form>




          <div className='flex place-items-center place-content-center mb-24'>

            <div class="max-w-sm rounded overflow-hidden shadow-inner bg-opacity-20 bg-white">
              {dripRate && (

                <div>
                  <div class="px-6 py-4">

                    <div class="font-bold text-xl mb-2">Drip rate:</div>
                    <div className='flex justify-center items-center gap-x-1'>
                      <div class="text-white text-2xl">
                        {dripRate}</div><span className='text-gray-100 text-xl'>gtts/min</span>
                    </div>
                  </div>

                  <div>
                    <div class="px-6 pt-4 pb-2 grid grid-cols-1 place-items-center">
                      <div class="inline-block bg-gray-200 w-fit rounded-full px-3 py-1 text-sm text-center font-semibold text-gray-700 mr-2 mb-2">How to adjust manually:</div>
                      <div class="inline-block  px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">A drop every {dripTime} seconds</div>


                    </div>

                  </div>

                </div>
              )}
            </div>

          </div>
        </div>




        <div className='my-10 text-center text-xl'>developed by alostmedic.com</div>
      </div>

    </CalculatorWorking >

  );
}
