import React from 'react';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';
import SaveAsPDF from '../../../../features/button/save-as-pdf';



export const EssentialSkillsMedicine = () => {




    return (

        <CalculatorWorking>


            <div className="container mb-20  mx-auto px-2 md:px-4 py-0">
                {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
                <h1 class="place-content-center block md:inline text-2xl text-start md:text-center md:text-4xl font-extrabold dark:text-white">Essential Skills for New Doctors in a Medical Ward:<div className='text-start text-sm md:text-2xl'>A Comprehensive Guide</div></h1>
                <div className='grid-cols-1 md:flex grid place-items-center place-content-center md:place-items-start px-0 md:px-8  my-2 md:my-6'>


                    <div className='flex gap-x-2 '>
                        {/* //for modals */}

                        <SaveAsPDF />


                    </div>




                </div>


                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>


                        <div>
                            <div>
                                <p class="mb-10 text-white dark:text-white px-2 indent-6 my-4 text-start text-sm md:text-lg">Navigating a medical ward as a fresh medical graduate can be both exciting and challenging. It marks the beginning of a journey where theoretical knowledge meets real-life patient care. To work efficiently in a medical ward, fresh medical graduates need to acquire a range of essential skills. These skills encompass not only medical knowledge but also interpersonal communication, practical procedures, time management, and professional conduct. In this guide, we will explore the key skills that fresh medical graduates should focus on to thrive in a medical ward and provide optimal care to their patients.</p>

                                <ul class="space-y-4 pl-8 text-white list-disc list-outside px-4 md:px-8 text-sm  md:text-lg">
                                    <li><div class='font-bold'>Patient Assessment: </div>Learning how to conduct a thorough and systematic patient assessment is crucial. This includes history-taking, physical examination, and interpreting relevant investigations.</li>
                                    <li><div class='font-bold'>Clinical Reasoning: </div>Developing strong clinical reasoning skills is essential for diagnosing and managing patients. Fresh graduates should learn to analyze clinical information, generate differential diagnoses, and formulate appropriate treatment plans.</li>
                                    <li><div class='font-bold'>Ward Procedures: </div>Familiarity with common ward procedures such as venipuncture, IV cannulation, blood sampling, wound dressing, and catheterization is important. Learning proper technique, infection control, and patient safety measures is vital.</li>
                                    <li><div class='font-bold'>Medication Management: </div>Understanding medication prescribing, dosage calculations, drug interactions, and monitoring for adverse effects are critical skills. Fresh graduates should also be aware of medication reconciliation and safe medication administration practices.</li>
                                    <li><div class='font-bold'>Teamwork and Communication: </div>Effective communication and teamwork are essential in a medical ward. Graduates should learn how to collaborate with other healthcare professionals, communicate effectively with patients and their families, and participate in interprofessional team discussions.</li>
                                    <li><div class='font-bold'>Time Management: </div>Learning to prioritize tasks, manage time efficiently, and handle multiple patients is crucial. Graduates should develop strategies to optimize their workflow and ensure timely and effective patient care.</li>
                                    <li><div class='font-bold'>Documentation and Record-Keeping: </div>Accurate and thorough documentation is vital for continuity of care. Fresh graduates should learn how to maintain clear and concise medical records, including progress notes, medication charts, and discharge summaries.</li>
                                    <li><div class='font-bold'>Ethical and Legal Considerations: </div>Understanding ethical principles, patient confidentiality, consent, and legal obligations in healthcare is important. Graduates should be familiar with ethical dilemmas and the process of obtaining informed consent.</li>
                                    <li><div class='font-bold'>Self-Care and Well-being: </div>Taking care of their own physical and mental well-being is essential for fresh graduates. Learning stress management techniques, maintaining a healthy work-life balance, and seeking support when needed are vital aspects of their professional growth.</li>
                                </ul>



                            </div>


                        </div>



                        <p class="mb-3 text-white dark:text-white px-4 indent-3 my-4 text-start text-sm md:text-lg">By focusing on these areas, fresh medical graduates can develop the necessary skills and knowledge to work efficiently in a medical ward, provide optimal patient care, and continue to grow as healthcare professionals.</p>


                    </div>
                </div>








                <DefaultBottomDialogue />
            </div >


        </CalculatorWorking >

    );
};
