import React from 'react';
import SaveAsPDF from '../../../../../features/button/save-as-pdf';
import DefaultBottomDialogue from '../../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../../calculatorworking';

export const ExternalUrinaryCatheter = () => {


    return (

        <CalculatorWorking>



            {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
            <h1 class="place-content-center text-2xl text-center md:text-4xl mb-15 font-extrabold dark:text-white">External Urinary Catheterization <div>(Condom Catheter)</div></h1>

            <div className="container mb-20 mx-auto px-4 py-0">
                <div className="flex place-items-center place-content-center gap-x-1 container grid-cols-none mx-auto px-4 py-0">


                    <div className='px-0 md:px-8 mt-2'>
                        <label htmlFor="my-modal" className="btn lg:mx-0 hover:underline h-[80px] md:h-fit bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Equipment for External Catheter</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modal" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white ">
                                <label htmlFor="my-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        List of Necessary Equipment for Foley's Catheterization:</h3>


                                    <div> <SaveAsPDF /></div>
                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                <ul class="space-y-4 text-gray-800 list-decimal list-outside px-4 md:px-8 text-vsm  md:text-base">

                                    <li className='font-bold'>Sterile Gloves:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Disposable sterile gloves to ensure hand hygiene and prevent contamination.</li>
                                    </ol>

                                    <li className='font-bold'>Foley Catheter:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A sterile Foley catheter of appropriate size and type. Foley catheters have an inflatable balloon near the tip that is used to secure the catheter inside the bladder.</li>
                                    </ol>

                                    <li className='font-bold'>Sterile Lubricating Gel:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A sterile lubricating gel to ease the insertion of the catheter and minimize patient discomfort. Water-soluble lubricants are recommended.</li>
                                    </ol>

                                    <li className='font-bold'>Antiseptic Solution:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>An antiseptic solution, such as chlorhexidine or povidone-iodine, for cleansing the genital area and maintaining aseptic conditions during the procedure.</li>
                                    </ol>

                                    <li className='font-bold'>Sterile Drapes:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile drapes to create a clean field and maintain aseptic conditions during the catheterization process.</li>
                                    </ol>

                                    <li className='font-bold'>Sterile Syringe:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A sterile syringe of appropriate size for inflating the balloon of the Foley catheter. The syringe should be of a volume recommended by the manufacturer.</li>
                                    </ol>

                                    <li className='font-bold'>Sterile Distilled Water:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile water for inflating the balloon of the Foley catheter. The amount of water required for balloon inflation should be as per the manufacturer's guidelines.</li>
                                    </ol>

                                    <li className='font-bold'>Adhesive or Tape:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Adhesive strips or tape to secure the catheter in place after insertion. It should be firm enough to prevent accidental dislodgement but not excessively tight.</li>
                                    </ol>

                                    <li className='font-bold'>Urine Drainage Bag:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A sterile urine drainage bag with appropriate capacity to collect and measure urine output. The bag may be connected to a leg bag or a larger collection bag, depending on the patient's needs.</li>
                                    </ol>

                                    <li className='font-bold'>Sterile Gauze or Cotton Balls:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile gauze or cotton balls for cleaning and drying the genital area, as well as for applying pressure to the puncture site after catheter insertion to stop any bleeding.</li>
                                    </ol>

                                    <li className='font-bold'>Waste Disposal Bags:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Plastic bags for the proper disposal of used gloves, swabs, and other non-sharp waste generated during the catheterization procedure.</li>
                                    </ol>

                                </ul>

                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-vsm md:text-base">It is essential to follow local guidelines, protocols, and infection control practices when performing Foley catheterization. Proper selection and preparation of equipment contribute to maintaining patient safety, preventing infections, and ensuring effective urinary drainage.</p>


                                <div className="modal-action">
                                    <label htmlFor="my-modal" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalvials" className="btn lg:mx-0 h-[80px] md:h-fit shover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Ext-Catheter Size Color Guide</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvials" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvials" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Foley's Catheter Size Color Guide:</h3>


                                </div>
                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                <div className='text-vsm md:text-xs font-base text-gray-800 text-start mb-2'>External catheter sizes vary in different manufacturers by 1-2 mm but the commonly sold sizes are listed below:</div>


                                <div className="overflow-x-auto">
                                    <table className="rounded-lg bg-gray-800 table-pin-cols">
                                        {/* head */}
                                        <thead>
                                            <tr>

                                                <th className='w-[25px]'>Width</th>
                                                <th className='w-[25px] max-w-[25px]'>Size</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* row 1 */}
                                            <tr>

                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>25 mm </td>
                                                <td className='text-vsm md:text-xs text-white text-center'>Small</td>

                                            </tr>

                                            {/* row 2 */}
                                            <tr>

                                                <td className='text-vsm md:text-xs font-bold text-white text-center mb-2'>29 mm</td>
                                                <td className='text-vsm md:text-xs text-white text-center'>Medium</td>
                                            </tr>


                                            {/* row 3 */}
                                            <tr>

                                                <td className='text-vsm md:text-xs font-bold text-white text-center mb-2'>32 mm</td>
                                                <td className='text-vsm md:text-xs text-white text-center'>Intermediate</td>
                                            </tr>


                                            {/* row 4 */}
                                            <tr>

                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>36 mm</td>
                                                <td className='text-vsm md:text-xs text-white text-center'>Large</td>

                                            </tr>


                                            {/* row 5 */}
                                            <tr>

                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>41 mm</td>
                                                <td className='text-vsm md:text-xs text-white text-center'>Extra Large</td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>


                                {/* <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-base md:text-xl">For more details, <a href='https://en.wikipedia.org/wiki/Vacutainer' target="_blank" rel="noreferrer" className='hover:text-white dark:text-blue-600 text-start text-base md:text-xl hover:bg-blue-400'>click here</a></p> */}







                                <div className="modal-action">
                                    <label htmlFor="my-modalvials" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalvideo" className="btn lg:mx-0 h-[80px] md:h-fit shover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Watch Video of Catheter Insertion</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvideo" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvideo" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Foley's Catheter Insertion: Video</h3>



                                </div>

                                {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}

                                <div class='max-w-fit flex justify-center items-center mb-4'>
                                    <iframe
                                        className='w-fit h-fit md:w-[560px] md:h-[360px] flex place-content-center place-items-center'
                                        src="https://www.youtube.com/embed/0cOv3k2JRZU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen>

                                    </iframe>
                                </div>



                                <div className="modal-action">
                                    <label htmlFor="my-modalvideo" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='w-90'>
                    <div className='grid grid-cols-1 place-items-start'>



                        <p class=" text-white dark:text-white px-4 indent-6 my-4 text-start text-sm md:text-lg">External urinary catheterization, also known as condom catheterization, is a procedure used to manage urinary incontinence in male patients. The National Institute for Health and Care Excellence (NICE) provides guidelines on the recommended steps for using external urinary catheters.</p>
                        <p class="mb-10 text-white dark:text-white px-4 my-1 text-start text-sm md:text-lg">Here's a brief note on external urinary catheterization as per NICE guidelines:</p>

                        <ul class="space-y-4 text-white list-decimal list-outside px-8 md:px-16 text-sm md:text-lg">

                            <li className='font-bold'> Preparation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Gather all the necessary equipment, including gloves, a condom catheter (of appropriate size), adhesive strips or securement device, cleansing wipes or solution, and a urine drainage bag.</li>
                                <li>Verify the patient's identification and explain the procedure to them, addressing any concerns or questions.</li>
                            </ol>

                            <li className='font-bold'> Hand Hygiene:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Perform hand hygiene by using soap and water or an alcohol-based hand rub before putting on gloves.</li>
                            </ol>

                            <li className='font-bold'> Patient Positioning:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Ensure the patient is lying comfortably in a supine position or sitting upright.</li>
                                <li>Provide appropriate privacy and maintain a comfortable environment for the patient.</li>
                            </ol>

                            <li className='font-bold'>Size Selection:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Select a condom catheter size that fits the patient's penis properly, ensuring a snug fit without causing constriction or discomfort.</li>
                                <li>It is essential to assess and measure the patient's penis size accurately to choose the appropriate catheter size.</li>

                            </ol>

                            <li className='font-bold'>Cleansing and Preparation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Cleanse the penis and surrounding area with mild soap and water or a suitable cleansing wipe or solution, following NICE guidelines.</li>
                                <li>Ensure proper drying of the area to promote adhesion and prevent skin irritation.</li>

                            </ol>

                            <li className='font-bold'>Catheter Application:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Unroll the condom catheter onto the penis, starting from the base and rolling it down towards the tip, ensuring proper alignment.</li>
                                <li>Leave a small space (approximately 1-2 cm) at the end of the catheter to allow for any expansion.</li>
                                <li>Ensure that the catheter is applied securely but not excessively tight to avoid discomfort or impairment of blood flow.</li>
                            </ol>

                            <li className='font-bold'>Securement:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Use adhesive strips or a securement device to hold the condom catheter in place, ensuring it is securely fastened but not too tight.</li>
                                <li>Ensure that the securement is comfortable and does not cause skin irritation or damage.</li>

                            </ol>

                            <li className='font-bold'>Connect the Drainage System:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                <li>Connect the condom catheter to a urine drainage bag that is appropriate for the patient's needs.</li>
                                <li>The drainage bag should be positioned below the level of the bladder to facilitate gravity drainage and prevent backflow.</li>
                            </ol>

                            <li className='font-bold'>Monitoring and Care:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Regularly monitor the urine drainage, ensuring proper flow and patency of the catheter.</li>
                                <li>Assess the patient for any signs of skin irritation, discomfort, or catheter-related complications.</li>
                                <li>Maintain hygiene and cleanliness of the genital area, ensuring regular catheter changes as per NICE guidelines.</li>
                            </ol>

                            <li className='font-bold'>Documentation:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Document the details of the procedure, including the catheter size, patient's response, urine output, and any complications encountered.</li>
                            </ol>



                        </ul>
                        <div className='mt-10 text-white dark:text-white px-4 text-start text-sm md:text-lg font-bold'>Note:</div>

                        <p class="mb-3 text-white dark:text-white px-4 my-4 text-start text-sm md:text-lg">Following the NICE guidelines for external urinary catheterization helps healthcare professionals perform the procedure safely and effectively. It promotes proper sizing, cleansing, application, and securement of the condom catheter, ensuring patient comfort, urinary management, and prevention of complications. Regular monitoring, appropriate catheter changes, and patient education contribute to maintaining hygiene and optimizing the use of external urinary catheters.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div>


        </CalculatorWorking >

    );
};
