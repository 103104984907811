import React from 'react';
import SaveAsPDF from '../../../../features/button/save-as-pdf';
import DefaultBottomDialogue from '../../../../features/text-dialogues/default-bottom';
import { CalculatorWorking } from '../../calculatorworking';

export const LumbarPuncture = () => {


    return (

        <CalculatorWorking>



            {/* <h1 className="text-2xl/6 md:text-3xl text-center my-2 font-bold">Skeletal Muscle Relaxant Dose Calculator</h1> */}
            <h1 class="place-content-center text-2xl text-center md:text-4xl mb-15 font-extrabold dark:text-white">Lumbar Puncture</h1>

            <div className="container mb-20 mx-auto px-4 py-0">



                <div className="flex place-items-center place-content-center gap-x-1 container grid-cols-none mx-auto py-0">


                    <div className='px-0 md:px-8 mt-2'>
                        <label htmlFor="my-modal" className="btn lg:mx-0 hover:underline md:min-w-[150px] h-[80px] md:h-fit bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Necessary Equipment Required</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modal" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white ">
                                <label htmlFor="my-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        List of Necessary Equipment for NG Tube Insertion:</h3>


                                    <div> <SaveAsPDF /></div>
                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>


                                <ul class="space-y-4 text-gray-800 list-decimal list-outside px-4 md:px-8 text-vsm  md:text-base">

                                    <li className='font-bold'>Nasogastric Tube:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A flexible tube made of various materials, such as polyvinyl chloride (PVC) or silicone, available in different sizes and lengths. Choose the appropriate size based on the patient's age and the purpose of insertion.</li>
                                    </ol>

                                    <li className='font-bold'>Water-soluble Lubricant:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A water-based lubricant used to ease the insertion of the nasogastric tube. Ensure it is suitable for medical use and compatible with the chosen tube material.</li>
                                    </ol>
                                    <li className='font-bold'>Adhesive Tape or Tube Holder:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Used to secure the nasogastric tube to the patient's nose or cheek after insertion. Adhesive tape should be hypoallergenic and gentle on the skin, while tube holders provide a more secure and comfortable attachment.</li>
                                    </ol>
                                    <li className='font-bold'>Syringe:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A syringe, typically 20 or 50 mL in size, is used for various purposes during nasogastric tube insertion, such as aspirating stomach contents or flushing the tube to ensure patency.</li>
                                    </ol>

                                    <li className='font-bold'>Litmus pH Paper Kit:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>For testing pH of gastric aspirate on the bedside to confirm the position of nasogastric tube</li>
                                    </ol>

                                    <li className='font-bold'>Stethoscope (if Litmus kit is not available):</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A stethoscope is necessary to auscultate for the presence of air in the stomach during the verification of tube placement.</li>
                                    </ol>

                                    <li className='font-bold'>Water for Flushing:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Sterile or clean water is used to flush the nasogastric tube before and after insertion to ensure patency and proper functioning.</li>
                                    </ol>

                                    <li className='font-bold'>Marking Pen:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A marking pen is used to mark the external length of the nasogastric tube at the nostril or lip level. This helps in monitoring the tube's position and detecting any displacement.</li>
                                    </ol>


                                    <li className='font-bold'>Personal Protective Equipment (PPE):</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>Healthcare professionals should wear appropriate PPE, including gloves and an apron, to maintain infection control during the procedure.</li>
                                    </ol>


                                    <li className='font-bold'>Cup of Water:</li>
                                    <ol class="pl-5 space-y-1 list-disc list-outside">
                                        <li>A cup of water or a small drink is often provided to the patient to aid in swallowing and facilitate the passage of the nasogastric tube into the stomach.</li>
                                    </ol>
                                </ul>

                                <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-vsm md:text-base">It is important to note that the specific equipment required for nasogastric tube insertion may vary depending on institutional protocols, patient needs, and healthcare provider preferences. Additionally, additional equipment may be needed for specific purposes, such as pH paper to test gastric aspirate pH or a suction device for gastric decompression. Healthcare professionals should consult institutional guidelines and refer to manufacturer instructions for specific equipment to ensure safe and effective nasogastric tube insertion.</p>


                                <div className="modal-action">
                                    <label htmlFor="my-modal" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalvials" className="btn lg:mx-0 h-[80px] md:h-fit shover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">NG Tube Color Guide</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvials" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvials" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        NG Tube Size Color Guide:</h3>


                                </div>

                                <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-2' style={{ userSelect: "none" }}>FirstScrub.com</div>

                                <div className="overflow-x-auto">
                                    <table className="rounded-lg bg-gray-800 table-pin-cols">
                                        {/* head */}
                                        <thead>
                                            <tr>
                                                <th className='w-[25px]'>Color</th>
                                                <th className='w-[25px]'>Width</th>
                                                <th className='w-[25px] max-w-[25px]'></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* row 1 */}
                                            <tr>
                                                <th className='bg-[#9bce31] text-vsm md:text-xs font-bold text-white text-center'>Light Green</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>6 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>2.0 mm</td>

                                            </tr>

                                            {/* row 2 */}
                                            <tr>
                                                <th className='bg-[#6495ec] text-vsm md:text-xs font-bold text-white text-center'>Light Blue</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center mb-2'>8 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'> 2.7 mm</td>
                                            </tr>


                                            {/* row 3 */}
                                            <tr>
                                                <th className='bg-[#000000] text-vsm md:text-xs font-bold text-white text-center'>Black</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center mb-2'>10 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'> 3.3 mm</td>
                                            </tr>


                                            {/* row 4 */}
                                            <tr>
                                                <th className='bg-[#ffffff] text-vsm md:text-xs font-bold text-black text-center'>White</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>12 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>4.0 mm</td>

                                            </tr>


                                            {/* row 5 */}
                                            <tr>
                                                <th className='bg-[#008001] text-vsm md:text-xs font-bold text-white text-center'>Dark Green</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>14 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>4.7 mm</td>
                                            </tr>


                                            {/* row 6 */}

                                            <tr>
                                                <th className='bg-[#ff8617] text-vsm md:text-xs font-bold text-white text-center'>Orange</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>16 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>5.3 mm<div className='text-vsm md:text-xs text-white text-start'> Most commonly used in adults</div></td>

                                            </tr>

                                            {/* row 7 */}

                                            <tr>
                                                <th className='bg-[#ff0000] text-vsm md:text-xs font-bold text-white text-center'>Red</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>18 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>6.0 mm</td>
                                            </tr>

                                            {/* row 8 */}

                                            <tr>
                                                <th className='bg-[#ffff00] text-vsm md:text-xs font-bold text-gray-800 text-center'>Yellow</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>20 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>6.7 mm</td>
                                            </tr>


                                            {/* row 8 */}


                                            <tr>
                                                <th className='bg-[#b955d3] text-vsm md:text-xs font-bold text-white text-center'>Purple</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>22 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>7.3 mm</td>
                                            </tr>

                                            {/* row 9 */}

                                            <tr>
                                                <th className='bg-[#0000ff] text-vsm md:text-xs font-bold text-white text-center'>Dark Blue</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>24 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>8.0 mm</td>
                                            </tr>
                                            {/* row 10 */}
                                            <tr>
                                                <th className='bg-[#ff11ad] text-vsm md:text-xs font-bold text-white text-center'>Pink</th>
                                                <td className='text-vsm md:text-xs font-bold text-white text-center'>26 Fr</td>
                                                <td className='text-vsm md:text-xs text-white text-start'>8.7 mm</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>


                                {/* <p class="text-gray-800 dark:text-gray-800 px-2 my-6 text-start text-base md:text-xl">For more details, <a href='https://en.wikipedia.org/wiki/Vacutainer' target="_blank" rel="noreferrer" className='hover:text-white dark:text-blue-600 text-start text-base md:text-xl hover:bg-blue-400'>click here</a></p> */}







                                <div className="modal-action">
                                    <label htmlFor="my-modalvials" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className='px-0 md:px-8  mt-2'>
                        <label htmlFor="my-modalvideo" className="btn lg:mx-0 md:min-w-[150px] h-[80px] md:h-fit hover:underline bg-white text-center w-[110px] md:w-fit hover:text-white  text-xs text-gray-800 font-bold rounded-full mt-1 lg:mt-0 py-2 px-2 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Watch Video</label>

                        {/* Put this part before </body> tag */}
                        <input type="checkbox" id="my-modalvideo" className="modal-toggle" />
                        <div className="modal">
                            <div className="modal-box relative bg-white w-fit md:w-[560px]">
                                <label htmlFor="my-modalvideo" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

                                <div className='flex gap-x-20'>
                                    <h3 className="font-bold text-gray-800 text-base mb-6">
                                        Nasogastric Tube Insertion: Video</h3>



                                </div>

                                {/* <div class='font-bold text-center text-gray-400 text-base md:text-xl mb-4' style={{ userSelect: "none" }}>FirstScrub.com</div> */}
                                <h3 className="font-bold text-gray-800 text-base">
                                </h3>
                                <div class='max-w-fit flex justify-center items-center mb-4'>
                                    <iframe
                                        className='w-fit h-fit md:w-[560px] md:h-[360px] flex place-content-center place-items-center'
                                        src="https://www.youtube.com/embed/QZRnpwxNJfw"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen>

                                    </iframe>
                                </div>

                                <div className="modal-action">
                                    <label htmlFor="my-modalvideo" className="btn hover:bg-table-hover hover:text-gray-800">Got it!</label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>




                <div className='w-100'>
                    <div className='grid grid-cols-1 place-items-start'>



                        <p class=" text-white dark:text-white px-4 indent-6 my-4 text-start text-sm md:text-lg">Lumbar puncture, commonly known as a spinal tap, is a medical procedure used to collect cerebrospinal fluid (CSF) from the subarachnoid space in the lower back. It is a valuable diagnostic tool for assessing various neurological conditions and can also be used for therapeutic purposes. Proper technique and knowledge of anatomical landmarks are essential to perform a successful lumbar puncture.</p>
                        <p class="mb-10 text-white dark:text-white px-4 my-1 text-start text-sm md:text-lg">The following is a general note outlining the procedure for lumbar puncture:</p>





                        <ul class="space-y-4 text-white list-disc list-outside pl-8 pr-4 md:px-16 text-sm md:text-lg">
                            <li className='font-bold'> Preparation:</li>
                            <ol class=" text-white dark:text-white my-4 text-start text-sm md:text-lg">Suprapubic catheterization may be indicated in various situations, including but not limited to:</ol>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Obtain informed consent from the patient or their legal representative, explaining the purpose, risks, and potential benefits of the procedure.</li>
                                <li>Position the patient in a lateral decubitus (side-lying) or seated position with the back arched and knees drawn up to the abdomen.</li>
                                <li>Ensure the patient is comfortable and well-informed about the process.</li>
                                <li>Make sure all neccessary equipments are available and prepare them for the procedure</li>

                            </ol>

                            <li className='font-bold'> Personal Hygiene:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Scrub with povidine pyodine or chlorexidine surgical scrub & wear sterile gown and gloves (Personal Protective Equipment, PPE) to maintain aseptic environment. Click here to read about scrubbing and donning of gown and gloves.</li>

                            </ol>

                            <li className='font-bold'>Prepare the medications:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Make your assitant open sterile syringes and LP needle in front of you on a trolley with a sterile drape on it aseptically.</li>
                                <li>Aseptically fill syringes with your desired local anesthetic agent for skin (as well as a spinal anesthetic agent separately if giving spinal anesthesia).</li>
                                <li>Place syringes and LP needle in the order of their use (local anesthesia for skin > LP needle > syringe for aspiration of CSF & sterile infusion set to measure opening pressure or syringe with spinal anesthetic agent).</li>
                            </ol>

                            <li className='font-bold'>Establishing an aspetic field:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Ensure adequate exposure, remove any clothing or object obscuring your view or contaminating sterile field, tell your assistant to do it for you.</li>
                                <li>Cleanse the puncture site using an antiseptic solution, povidine iodine or chlorexidine,outwards (from inside to out) in circular motion. Repeat this without touching anything other than skin and allow it to dry.</li>
                                <li>Ensure all accesible areas to be cleansed properly before proceeding including sides of the body.</li>
                                <li>Cover rest of the septic area with sterile drapes to establish an aseptic field of work.</li>
                            </ol>

                            <li className='font-bold'>Landmark Identification:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Spinal cord ends in spinal column at the vertebral level of L1 or L2 in adults and L3 in children.</li>
                                <li>The iliac crest, which is a bony prominence at the top of the hip bone, is a reliable landmark for locating the L4-5 interspace.</li>
                                <li>Palpate the iliac crest and locate the imaginary line connecting the highest points of both iliac crests. The L4-5 interspace is typically located just below this line.</li>
                                <li>Identify the L4 and L5 vertebral spaces, and then track your desired vertebral space higher or lower than L4-5.</li>
                                <li>Make sure to keep at eye on your desired level or make at indentation in the skin with your nail at 90&deg; angle (perpendicularly).</li>

                            </ol>

                            <li className='font-bold'> Local Anesthesia:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Administer a local anesthetic to numb the skin and deeper tissues at the puncture site (or in the middle of your created indentation), usually with a small needle (first with a 1cc syringe then with 3cc or 5 cc syringe depending on desired dose of local anesthetic agent to be given).</li>
                                <li>Let the anesthetic take effect for a few minutes to minimize discomfort during the procedure.</li>
                            </ol>

                            <li className='font-bold'> LP Needle Insertion:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Position the bevel of a spinal needle parallel to the patient's spine.</li>
                                <li>Insert the needle at the midpoint of the desired space or your, aiming towards the umbilicus (navel) or slightly caudad (towards the feet).</li>
                                <li>Advance the needle slowly and steadily, being mindful to avoid the spinal cord and the cauda equina.</li>
                            </ol>

                            <li className='font-bold'>CSF Collection:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Observe the flow of CSF through the needle. Once CSF is present, measure the opening pressure of the CSF if necessary.</li>
                                <li>Otherwise collect the required amount of fluid for diagnostic testing or therapeutic purposes.</li>
                                <li>Inject any intra-thecal medication after first aspirating some CSF in the medication-filled syringe and observing and ensuring it closely</li>

                            </ol>

                            <li className='font-bold'> Needle Removal and Dressing:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Carefully remove the needle, ensuring minimal leakage of CSF.</li>
                                <li>Apply a sterile dressing at the puncture site to prevent infection.</li>
                                <li>Immediately place patient in supine position if full spinal anesthesia is desired, let patient lie in respective lateral decubitus postition for 10 mins if anesthesia of only one side is desired, or let the patient sit for 10 mins afterwards if saddle block is desired. This helps fixate the hyperbaric spinal anesthetic agent in the desired spinal level.</li>

                            </ol>



                            <li className='font-bold'> Post-Procedure Care:</li>
                            <ol class="pl-5 mt-2 space-y-1 list-decimal list-outside">
                                <li>Monitor the patient for any adverse effects or complications, such as headache, infection, or bleeding.</li>
                                <li>Encourage the patient to rest and stay well-hydrated after the procedure or surgery.</li>
                            </ol>




                        </ul>

                        <p class="mb-3 text-white dark:text-white px-4 my-4 text-start text-sm md:text-lg">Lumbar puncture at the L4-5 interspace is a valuable and safe procedure when performed with precision and adherence to guidelines. Proper identification of anatomical landmarks and aseptic technique are essential for successful CSF collection. By following the steps outlined above, healthcare professionals can confidently perform lumbar punctures and contribute to accurate diagnoses and optimal patient care.</p>

                    </div>
                </div>








                <DefaultBottomDialogue />
            </div>




        </CalculatorWorking >

    );
};
